import React, { useContext } from "react";
import styled from "styled-components";
import GiphySelector from "./GiphySelector";
import { CreateCardContext } from "../context/CreateCardContext";
import SolidSelector from "./SolidSelector";
import CreateCardPictureToolbox from "./CreateCardPictureToolbox";
import { CardBrowserContext } from "../context/CardBrowserContext";
import { fadeInBottom } from "../utils/animations";
import { TitleContext } from "../context/TitleContext";

const StyledCreateCard = styled("div")`
  width: 100%;
`;

const Toolbox = styled.div`
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  max-width: 100vw;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  animation: ${fadeInBottom} 0.10s cubic-bezier(0.13, 0.54, 0.68, 0.93) 1;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  z-index: 10;
  height: 100vh;
  background-color: rgba(0,0,0,0.75);
`;

const GIF_VIEW = "GIF";
const SOLID_VIEW = "SOLID";

export default function CreateCard({ previousDocumentTitle }) {

  const { currentView, themeColor } = useContext(CreateCardContext);
  const { refetchCards, setIsAddingCard } = useContext(CardBrowserContext);
  const { setTabTitle } = useContext(TitleContext);

  React.useEffect(() => {
    setTabTitle("Tyrill - New Tyrill Card");
    return () => {
      setTabTitle(previousDocumentTitle);
    }
  }, []);

  return (
    <StyledCreateCard>
      {currentView === GIF_VIEW && <GiphySelector />}
      {currentView === SOLID_VIEW && <SolidSelector />}
      <Toolbox isOpen={true}>
        <CreateCardPictureToolbox
          title={"New Tyrill Card"}
          backgroundColor={themeColor.background}
          color={themeColor.fontColor}
          toolboxIcon={["far", "edit"]}
          zIndex={60}
          onPublishSuccess={() => {
            refetchCards();
            setIsAddingCard(false);
          }}
        />
      </Toolbox>
    </StyledCreateCard>
  );
}
