import React, { createContext, useState } from 'react';

const CreateCardToolboxContext = createContext({
    setOpenToolboxes: () => {},
    toggleToolboxOpen: () => {},
    openToolboxes: ["New Tyrill Card"],
});

function CreateCardToolboxProvider({ children }) {
  const [openToolboxes, setOpenToolboxes] = useState(["New Tyrill Card"]);

  const toggleToolboxOpen = toolboxName => {
      if(openToolboxes.includes(toolboxName)) {
        setOpenToolboxes([...openToolboxes.filter(t => t !== toolboxName)]);
      } else {
        setOpenToolboxes([...openToolboxes, toolboxName]);
      }
  }

  return (
    <CreateCardToolboxContext.Provider value={{
        openToolboxes,
        setOpenToolboxes,
        toggleToolboxOpen
    }}>
      {children}
    </CreateCardToolboxContext.Provider>
  )
}

export { 
    CreateCardToolboxProvider,
    CreateCardToolboxContext
}