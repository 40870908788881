import React, { useState, useEffect } from "react";
import styled from "styled-components";
import UserListItem from "./UserListItem";
import { fadeInTop } from "../utils/animations";
import LoadingIndicator from "./LoadingIndicator";
import StyledButton from "../styleguides/StyledButton";
import StyledParagraph from "../styleguides/StyledParagraph";
import app from "../utils/firebase";

const Wrapper = styled.div`
  animation: ${fadeInTop} 0.15s cubic-bezier(0.17, 0.67, 0.83, 0.67) 1;
  z-index: 7;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  height: calc(100% + 1px);
  transition: all 0.25s cubic-bezier(0.4, 0.35, 0.63, 0.47);
  width: 100%;
`;

const CardLikeList = styled("ul")`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  width: 100%;
`;

const CloseWrapper = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  top: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ContentPadder = styled.div`
    padding: ${props => props.theme.spacing.medium};
`;

export default function CardLikes({ cardId, onClose }) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState([]);
  //eslint-disable-next-line
  const [error, setError] = useState(null);
  const [lastDocument, setLastDocument] = useState(null);


  function getQuery() {
    return app
      .firestore()
      .collection("cards")
      .doc(cardId)
      .collection("hearts")
      .orderBy("createdAt", "asc")
      .limit(25);
  }

  // TODO: ADD SCROLL INFINITE!
  //eslint-disable-next-line
  function fetchMoreData() {
    setBusy(true);
    try {
      getQuery()
        .startAfter(lastDocument)
        .get()
        .then(dataz => {
          if (dataz && dataz.docs && dataz.docs.length > 0) {
            const localData = dataz.docs
              .reverse()
              .map(function(documentSnapshot) {
                const aDocument = documentSnapshot.data();
                aDocument.id = documentSnapshot.id;
                return aDocument;
              });
            setLastDocument(dataz.docs[dataz.docs.length - 1]);
            setData([...localData, ...data]);
          }
          setBusy(false);
        })
        .catch(e => {
          throw e;
        });
    } catch (e) {
      setError(e);
      setBusy(false);
    }
  }

  function fetchData() {
    try {
        getQuery()
        .get()
        .then(dataz => {
          const data = dataz.docs.reverse().map(function(documentSnapshot) {
            const aDocument = documentSnapshot.data();
            aDocument.id = documentSnapshot.id;

            return aDocument;
          });
          setLastDocument(dataz.docs[dataz.docs.length - 1]);
          setData(data);
          setBusy(false);
          setHasLoaded(true);
        });
    } catch (e) {
      setError(e);
      setBusy(false);
      setHasLoaded(true);
    }
  }

  useEffect(() => {
    fetchData();
    return () => setData([])
  }, [cardId]);

  return (
    <Wrapper>
      {(!hasLoaded || busy) &&  <ContentPadder><LoadingIndicator message={"Loading.."}/></ContentPadder>}
      {data && data.length > 0 && (
        <CardLikeList>
          {data.map(userLiked => (
            <UserListItem userProfile={userLiked} key={userLiked.id} />
          ))}
        </CardLikeList>
      )}
      {((hasLoaded && !busy) && (!data || !data.length > 0)) && (
        <ContentPadder>
            <StyledParagraph white>This card has not received any hearts yet. <span role="img" aria-label="Meh">
            😿
          </span></StyledParagraph>
        </ContentPadder>
      )}
      <CloseWrapper>
        <StyledButton onClick={() => onClose()} bottomBorderRadius>
          <StyledParagraph white>Close</StyledParagraph>
        </StyledButton>
      </CloseWrapper>
    </Wrapper>
  );
}
