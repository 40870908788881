import { useState, useEffect, useRef } from 'react';

export default function useSafeState(defaultState) {
  const [state, setState] = useState(defaultState);
  const mountedRef = useRef(false);

  function setSafeState(nextState) {
    return mountedRef.current && setState(nextState);
  }

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return [state, setSafeState];
}