export default [
    {
        id: 1,
        username: "gullskatten",
        firstName: "Espen",
        lastName: "Gudmundsen"
    },
    {
        id: 2,
        username: "dragonfire",
        firstName: "Kim",
        lastName: "Casey"
    },
    {
        id: 3,
        username: "troll",
        firstName: "Tilda",
        lastName: "Johnson"
    },
    {
        id: 4,
        username: "vildu",
        firstName: "Vilde",
        lastName: "Emilson"
    },
    {
        id: 5,
        username: "krizz",
        firstName: "Kristin",
        lastName: "Tollefsen"
    }
]