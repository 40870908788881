import React, { useEffect, useState } from "react";
import app from "../utils/firebase";
import { UserContext } from "./UserContext";
import uuidv4 from "uuid/v4";
import moment from "moment";
import {
  setNotificationSeen as updateSingleNotification,
  markAllAsSeen as updateAllNotifications,
  removeNotification
} from "../utils/notificationHelper";

const NotificationContext = React.createContext({
  notifications: null,
  error: null,
  hasUnseenNotifications: false,
  queryId: 123,
  handleNotificationRemove: () => {},
  setNotificationSeen: () => {}
});

const oneWeekAgo = moment()
  .subtract(7, "d")
  .toDate();

const NotificationProvider = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [hasUnseenNotifications, setHasUnseenNotifications] = useState(false);
  // The query ID will always change on new / modified data events (used for enforcing notification popup update).
  const [queryId, setQueryId] = useState(false);

  function handleSnapshotChanges(snapshot) {
    const dataSnapshot = notifications;
    let hasChanges = false;

    snapshot.docChanges().forEach(function(change) {
      if (change.type === "added") {
        const newdoc = change.doc.data();
        newdoc.id = change.doc.id;

        const isOldNotification = notifications.some(no => no.id === newdoc.id);

        if (!isOldNotification) {
          if (change.newIndex === 0) {
            dataSnapshot.unshift(newdoc);
          } else {
            dataSnapshot.push(newdoc);
          }

          hasChanges = true;
        }
      }
      if (change.type === "modified") {
        const modifiedDoc = change.doc.data();
        modifiedDoc.id = change.doc.id;

        if (change.newIndex !== change.oldIndex) {
          if (change.newIndex === 0) {
            dataSnapshot.splice(change.oldIndex, 1);
            dataSnapshot.unshift(modifiedDoc);
          } else {
            dataSnapshot.splice(change.oldIndex, 1, modifiedDoc);
          }
          hasChanges = true;
        } else {
          dataSnapshot.splice(change.oldIndex, 1, modifiedDoc);
          hasChanges = true;
        }
      }
      if (change.type === "removed") {
        dataSnapshot.splice(change.oldIndex, 1);
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setNotifications(dataSnapshot);
      setQueryId(uuidv4());
      const hasUnseenNotifications = dataSnapshot.some(
        n => !Object.keys(n).includes("seen")
      );
      setHasUnseenNotifications(hasUnseenNotifications);
    }
  }

  useEffect(() => {
    if (user && user.uid) {
      const q = app
        .firestore()
        .collection(`users/${user.uid}/notifications`)
        .where("updatedAt", ">=", oneWeekAgo)
        .orderBy("updatedAt", "desc")
        .limit(10);

      const unsub = q.onSnapshot(handleSnapshotChanges);
      return () => {
        unsub();
      };
    }
  }, [user]);

  function markAllAsSeen() {
    updateAllNotifications(notifications, user.uid);
  }

  function setNotificationSeen(notificationId) {
    updateSingleNotification(notificationId, user.uid);
  }

  function handleNotificationRemove(notificationId) {
    removeNotification(notificationId, user.uid);
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        hasUnseenNotifications,
        queryId,
        markAllAsSeen,
        setNotificationSeen,
        handleNotificationRemove
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
