import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import Text from "../styleguides/Text";
import { TitleContext } from "../context/TitleContext";
import { animated, useTransition } from "react-spring";
import { CircularActionDiv } from "../styleguides/StyledLink";
import { UserContext } from "../context/UserContext";
import { Link } from "react-router-dom";
import noTouchHighlight from "../styleguides/noTouchHighlight";
import { CardBrowserContext } from "../context/CardBrowserContext";

const StyledNav = styled.nav``;

export const AppTitle = styled(({ ...props }) => <Link {...props} />)`
  margin: 0.1rem;
  text-decoration: none;
  padding: 0.1rem;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 50%;
  ${noTouchHighlight}
`;

const RightContentLink = styled(({ ...props }) => <Link {...props} />)`
  padding: ${props => props.theme.spacing.small};
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 9;
  text-decoration: none;
`;


const AppTitleWrapper = styled.div`
  left: 0;
  position: absolute;
  padding: ${props => props.theme.spacing.small};
  z-index: 6;
`;

function Navbar({ theme }) {
  const { path, title, titleColor } = useContext(TitleContext);
  const { user } = useContext(UserContext);
  const { hasHeartedCard } = useContext(CardBrowserContext);
  const transitions = useTransition(title, null, {
    from: { opacity: 0, transform: "translate3d(0,-40px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px, 0)" },
    leave: { opacity: 0, transform: "translate3d(0,-40px,0)" }
  });

  if(!user) {
    return null;
  }

  return (
    <StyledNav>
      <AppTitleWrapper>
        <AppTitle to={path} title="Navigate to Tyrill Home">
          {transitions.map(({ item, key, props }) => (
            <animated.div
              style={{
                ...props,
                position: "absolute",
                top: theme.spacing.tiny,
                padding: "0 0.5rem"
              }}
              key={key}
            >
              <Text element="h1" variant="title" fontType="alternative" color={titleColor} bold size="bigger">{item}</Text>
            </animated.div>
          ))}
        </AppTitle>
      </AppTitleWrapper>
      {user && (
        <RightContentLink to="/user" title="Your Profile">
          <CircularActionDiv includesimage="true" image={user.photoURL}  key={hasHeartedCard || "1"}
          animate={!!hasHeartedCard} />
        </RightContentLink>
      )}
      {!user && (
        <RightContentLink to="/about" title="Read more about Tyrill">
          <Text gutterTop underlined textColor="white">About</Text>
        </RightContentLink>
      )}
    </StyledNav>
  );
}

export default withTheme(Navbar);
