import app from "./firebase";


export function removeNotification(notificationId, userId, callback) {
  if(notificationId) {
    const notificationRef = app
    .firestore()
    .collection(`users`)
    .doc(userId)
    .collection(`notifications`)
    .doc(notificationId);

    notificationRef.delete().then(() => {
      if(callback && callback instanceof Function) {
        callback();
      }
    });
  }
}

export function markAllAsSeen(notifications, userId, callback) {

    if(!notifications || !notifications.length > 0) {
        return;
    }

    const unseenDocRefs = notifications.map(notification => 
      app
      .firestore()
      .collection(`users`)
      .doc(userId)
      .collection(`notifications`)
      .doc(notification.id));

      let batch = app.firestore().batch();

      unseenDocRefs.forEach(docRef => batch.update(docRef, { seen: true }));
      batch.commit().then(() => {
        if(callback && callback instanceof Function) {
          callback();
        }
      });
  }

    export function setNotificationSeen(notificationId, userId, callback) {
    const notificationRef = app
    .firestore()
    .collection(`users`)
    .doc(userId)
    .collection(`notifications`)
    .doc(notificationId);

    notificationRef.update({
      seen: true
    }).then(() => {
      if(callback && callback instanceof Function) {
        callback();
      }
    });
  }