import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Text from "../styleguides/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import moneyPiggly from "../assets/images/piggly/not_allowed_piggly_400.png";
import { TitleContext } from "../context/TitleContext";
import {
  Card,
  CardContentPadder,
  CardImage,
  CardContentWrapper,
} from "../styleguides/CardStyles";
import { useTransition } from "react-spring";
import { fontColorChanger } from "../utils/animations";
import useLocalStorage from "../hooks/useLocalStorage";
import noTouchHighlight from "../styleguides/noTouchHighlight";

const LoginButtonLarge = styled.button`
    padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
    outline: 0;
    border: 0;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    
    ${noTouchHighlight}
`;
const CenteredContent = styled.section`
  padding: 2rem;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
`;

const PageInfo = styled.div`
  padding: ${props => props.theme.spacing.medium}
    ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background-color: ${props => props.isLoading ? 'transparent' : props.theme.colors.brands.facebookBlue};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
`;

const PageHeader = styled.div`
  padding: ${props => props.theme.spacing.medium}
    ${props => props.theme.spacing.small};
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
`;

const LoaderWrapper = styled.div`
  span {
    animation: ${fontColorChanger} 1.5s linear infinite;
    font-size: ${props => props.theme.fontSizes.large};
    margin-right: 0.5rem;
  }

  display: flex;
  align-items: center;
`;

const introPerspective = {
  from: {
    opacity: 0,
    transform: 
      "perspective(500px) rotateX(-180deg) rotateY(3deg) rotateZ(-13deg) scale(1)"
  },
  enter: {
    opacity: 1,
    transform: 
      "perspective(1500px) rotateX(5deg) rotateY(3deg) rotateZ(0deg) scale(1)"
  },
  leave: {
    opacity: 0,
    transform: 
      "perspective(500px) rotateX(7deg) rotateY(15deg) rotateZ(-3deg) scale(1)"
  }
}

const introFadeIn = {
  from: {
    opacity: 0
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0
  }
}

const UserLogin = () => {
  const { user, loginWithFacebook, hasLoadedUser } = useContext(UserContext);
  const { dispatch } = useContext(TitleContext);

  if (user) {
    return <Redirect to="/discover" />;
  }

  const [isAboutSeen] = useLocalStorage(
    "aboutSeen",
    false
  );
  
  useEffect(() => {
    dispatch({
      type: "default-title"
    });
  }, []);

  const transitions = useTransition(null, null, isAboutSeen ? introPerspective : introFadeIn);

  return (
    <React.Fragment>
      <CenteredContent>
      {transitions.map(({ item, key, props }) => (
        <Card
          key={key}
          themecolor="#555555"
          style={{ ...props }}
          defaultperspective={isAboutSeen ? undefined : "true"}
        >
          <PageHeader>
            <Text element="h1" fontType="alternative" textColor="white" variant="title" bold>Login to Tyrill</Text>
          </PageHeader>
          <CardContentWrapper>
            <CardContentPadder>
              <PageInfo isLoading={!hasLoadedUser} onClick={() => hasLoadedUser && loginWithFacebook()}>
                {!hasLoadedUser ? (
                  <LoaderWrapper>
                    <span>
                      <FontAwesomeIcon icon="dharmachakra" spin />
                    </span>
                    <Text textColor="white">Verifying your ID...</Text>
                  </LoaderWrapper>
                ) : (
                  <LoginButtonLarge
                    background={"transparent"}
                  >
                    <Text withIconLeft textColor="white" bold><FontAwesomeIcon icon={["fab", "facebook"]} /> Login with Facebook</Text>
                  </LoginButtonLarge>
                )}
              </PageInfo>
            </CardContentPadder>
          </CardContentWrapper>
          <CardImage backgroundImage={`url(${moneyPiggly})`} />
        </Card>
      ))}
    </CenteredContent>
    </React.Fragment>
  );
};

export default UserLogin;
