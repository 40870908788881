import React from "react";
import Text from "../styleguides/Text";
import StyledTextLink from "../styleguides/StyledTextLink";
import styled from "styled-components";
import { backgroundColorChanger, fontColorChanger } from "../utils/animations";
import {
  MarginLargeTb,
  MarginMediumTb
} from "../styleguides/ContentPadders";
import StyledButton, { ButtonGroup } from "../styleguides/StyledButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../context/UserContext";
import { useSprings, animated, interpolate, useTransition } from "react-spring";
import useInterval from "../hooks/useInterval";
import { TitleContext } from "../context/TitleContext";

const ScrollableView = styled.div`
position: absolute;
top: 0;
-webkit-overflow-scrolling: touch;
min-height: calc(100% + 1px);
overflow-y: auto;
overflow-x: hidden;
width: 100%;
`;

const TitleSection = styled.section`
  animation: ${backgroundColorChanger} 75s linear infinite;
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing.medium};
  position: absolute;
  width: 100%;
  z-index: 3;
  min-height: 100%;
`;

const MaxWidth = styled.div`
  max-width: 900px;
`;

const LoaderWrapper = styled.div`
  span {
    animation: ${fontColorChanger} 1.5s linear infinite;
    font-size: ${props => props.theme.fontSizes.large};
    margin-right: 0.5rem;
  }

  display: flex;
  align-items: center;
`;

const AnimatedPadder = styled(({ ...props }) => <animated.div {...props} />)`
  padding: ${props => props.theme.spacing.smallx} 0;
`;

const AnimatedMotto = styled(({ ...props }) => <animated.div {...props} />)`
  position: absolute;

  `;

const MottoWrapper = styled.div`
  position: relative;
  padding-bottom: ${props => props.theme.spacing.smallx};
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
  `;

const to = i => ({
  x: 0,
  y: i,
  scale: 1,
  rot: 0,
  opacity: 1,
  delay: i * Math.floor(Math.random() * 195 + 1)
});

const from = i => ({
  x: i >= 3 ? -1000 : 0,
  rot: 0,
  scale: 1.5,
  opacity: i >= 3 ? 0.1 : 1,
  y: i >= 3 ? 0 : -1000
});

const mottos = ["Swipe", "Share", "Connect"];

export default function Welcome() {

  const { loginWithFacebook, hasLoadedUser } = React.useContext(UserContext);
  const [currentMotto, setCurrentMotto] = React.useState(mottos[0]);
  const [currentMottoCounter, setCurrentMottoCounter] = React.useState(0);
  const { setTabTitle } = React.useContext(TitleContext);

  useInterval(() => {
      if(currentMottoCounter === mottos.length) {
        setCurrentMottoCounter(0);
      } else {
        setCurrentMottoCounter(currentMottoCounter + 1);
      }
  }, 2000);

  React.useEffect(() => {
    setCurrentMotto(mottos[currentMottoCounter]);
  }, [currentMottoCounter]);

  React.useEffect(() => {
    setTabTitle("Welcome to Tyrill");
  }, []);

  const [props] = useSprings(5, i => ({
    ...to(i),
    from: from(i)
  }));

  const transitions = useTransition(currentMotto, i => i, {
    from: { left: -150, opacity: 0 },
    enter: { left: -2, opacity: 1 },
    leave: { left: 150, opacity: 0 }
  });

  return (
    <ScrollableView>
      <TitleSection>
        <MaxWidth>
          {props.map(({ rot, x, y, scale, opacity }, i) => {
            switch (i) {
              case 0:
                return (
                  <AnimatedPadder
                    style={{
                      opacity: opacity,
                      transform: interpolate(
                        [x, y],
                        (x, y) => `translate3d(${x}px, ${y}px, 0)`
                      )
                    }}
                    key={i}
                  >
                    <MarginMediumTb>
                      <Text
                        variant="title"
                        element="h1"
                        textColor="white"
                        size="huge"
                        gutterBottom
                        fontType="alternative"
                      >
                        Tyrill
                      </Text>
                    </MarginMediumTb>
                  </AnimatedPadder>
                );
              case 1:
                return (
                  <AnimatedPadder
                    style={{
                      opacity: opacity,
                      transform: interpolate(
                        [x, y],
                        (x, y) => `translate3d(${x}px, ${y}px, 0)`
                      )
                    }}
                    key={i}
                  >
                    <MottoWrapper>
                      {transitions.map(({ item, key, props }) => (
                        <AnimatedMotto style={{
                          ...props,
                          width: "100%"
                        }} key={key}>
                          <Text
                            element="h2"
                            size="bigger"
                            variant="title"
                            textColor="white"
                          >
                            {item}
                          </Text>
                        </AnimatedMotto>
                      ))}
                    </MottoWrapper>
                  </AnimatedPadder>
                );
              case 2:
                return (
                  <AnimatedPadder
                    style={{
                      opacity: opacity,
                      transform: interpolate(
                        [x, y],
                        (x, y) => `translate3d(${x}px, ${y}px, 0)`
                      )
                    }}
                    key={i}
                  >
                    <Text size="bigger" textColor="white">
                      <Text bold element="span">{` Tyrill`}</Text> brings the
                      content you care about, straight into your very own card
                      deck. Throw in a new card with a spontaneous moment, an
                      inspiring idea or simply a shot of your best dance move.
                      Let other people see your awesomeness! 🎉
                    </Text>
                  </AnimatedPadder>
                );
              case 3:
                return (
                  <AnimatedPadder
                    style={{
                      opacity: opacity,
                      transform: interpolate(
                        [x, y],
                        (x, y) => `translate3d(${x}px, ${y}px, 0)`
                      )
                    }}
                    key={i}
                  >
                    <MarginLargeTb>
                      {hasLoadedUser ? (
                        <ButtonGroup>
                          <StyledButton
                            secondary
                            rounded
                            onClick={() => loginWithFacebook()}
                          >
                            Continue with Facebook{" "}
                            <FontAwesomeIcon icon="arrow-right" />
                          </StyledButton>
                          <StyledButton
                            as={Link}
                            to="/about"
                            faded="true"
                            rounded="true"
                          >
                            About Tyrill
                          </StyledButton>
                        </ButtonGroup>
                      ) : (
                        <LoaderWrapper>
                          <span>
                            <FontAwesomeIcon icon="dharmachakra" spin />
                          </span>
                          <Text textColor="white">Verifying your ID...</Text>
                        </LoaderWrapper>
                      )}
                    </MarginLargeTb>
                  </AnimatedPadder>
                );
              case 4:
                return (
                  <AnimatedPadder
                    style={{
                      opacity: opacity,
                      transform: interpolate(
                        [x, y],
                        (x, y) => `translate3d(${x}px, ${y}px, 0)`
                      )
                    }}
                    key={i}
                  >
                    <Text size="smaller" textColor="white">
                      By continuing you agree to our{" "}
                      <StyledTextLink to="/terms">
                        Terms of Use
                      </StyledTextLink>,{" "}
                      <StyledTextLink to="/privacy-policy">
                        Privacy Policy
                      </StyledTextLink>{" "}
                      and{" "}
                      <StyledTextLink to="/cookie-policy">
                        Cookie Policy
                      </StyledTextLink>
                      .
                    </Text>
                  </AnimatedPadder>
                );
              default:
                return null;
            }
          })}
        </MaxWidth>
      </TitleSection>
      </ScrollableView>
  );
}
