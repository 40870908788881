import React, { useContext, useEffect } from "react";
import { CardBrowserContext } from "../context/CardBrowserContext";
import CardDeck from "./CardDeck";
import MiddleScreenLoadingIndicator from "./MiddleScreenLoadingIndicator";
import { getRandomPositiveEmoji } from "../utils/positiveReactionEmojis";
import { getRandomNegativeEmoji } from "../utils/negativeReactionEmojis";
import { TitleContext } from "../context/TitleContext";
import CreateCard from "./CreateCard";

import { CreateCardProvider } from "../context/CreateCardContext";
import { CreateCardToolboxProvider } from "../context/CreateCardToolboxContext";
import { tyrillErrorToast } from "../utils/tyrillToaster";

const dislikeEmoji = getRandomNegativeEmoji();
const likeEmoji = getRandomPositiveEmoji();

export default function CardsByTag(props) {
  const {
    match: {
      params: { tag }
    }
  } = props;

  const {
    posts,
    postQueryKey,
    isLoadingCards,
    hasLoadedCards,
    refetchCards,
    isAddingCard,
    storageError,
    cardError
  } = useContext(CardBrowserContext);

  const { setTitle, setPath, setTabTitle, tabTitle, setLastPath } = useContext(TitleContext);

  useEffect(() => {
    if (tag) {
      const browsingTag = tag.toUpperCase();
      const titleTag = `#${tag.toLowerCase()}`;
      refetchCards(browsingTag);
      setTitle(titleTag);
      setPath("/");
      setTabTitle(`Tyrill - ${titleTag}`);
      setLastPath(`/discover/${tag}`);
    }
  }, [tag]);

  useEffect(() => {
    if (storageError) {
      tyrillErrorToast(storageError.message, 5000, "card-error");
    }
    if (cardError) {
      tyrillErrorToast(cardError.message, 5000, "card-error");
    }
  }, [storageError, cardError]);

  return (
    <React.Fragment>
      {(isLoadingCards || !hasLoadedCards) && (
        <MiddleScreenLoadingIndicator label={`Hang in there.. ${likeEmoji}`} />
      )}
      {(!isLoadingCards && hasLoadedCards) && (!posts || !(posts.length > 0)) && (
        <div>
          <MiddleScreenLoadingIndicator
            label={`Nothing more for #${tag.toLowerCase()}. ${dislikeEmoji}`}
          />
        </div>
      )}
      {isAddingCard && (
        <CreateCardProvider>
          <CreateCardToolboxProvider>
            <CreateCard {...props} previousDocumentTitle={tabTitle} />
          </CreateCardToolboxProvider>
        </CreateCardProvider>
      )}
      <CardDeck posts={posts} key={postQueryKey} />
    </React.Fragment>
  );
}
