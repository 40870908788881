import { useEffect, useState } from "react";

const emptyDefault = null;
const emptyMethod = "GET";

function useGiphy(opts) {
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(opts.initialData || emptyDefault);
  const [error, setError] = useState(null);

  function fetchData(query, queryEndpoint) {
      setBusy(true);
      const endpoint = (query && query.length) ? queryEndpoint : opts.endpoint;

      fetch(`https://us-central1-tyrill.cloudfunctions.net/sec/giphy?params=${encodeURIComponent(`/${endpoint}?limit=${opts.limit || 10}&offset=${opts.offset || 0}&lang=en${query ? `&q=${encodeURIComponent(query)}` : ''}`)}`, {
        method: opts.method || emptyMethod,
        headers: {
          authorization: opts.idToken
        }
      })
      .then(res => { return res.json()})
      .then(data => { 
        setData(data); 
        if(typeof opts.onSuccess === "function") {
          opts.onSuccess(data);
        }
      })
      .catch(e => {
      setError(e && e.message ? e.message : "Request to GIPHY failed!");
      setData(opts.initialData)
      }).finally(() => {
        setBusy(false);
      });
  }

  if (opts.fetchOnMount) {
      useEffect(() => {
        fetchData();
      }, []);
  }

  return [busy, data, error, fetchData];
}

export default useGiphy;
