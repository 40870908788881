export function getFontColorForThemeColor(color) {
    const darkFontColor =  "#777777";
    const lightFontColor = "#ffffff";
  switch (color) {
    case "#eeeeee":
      return darkFontColor;
    case "#ffeb3b":
      return darkFontColor;
    default:
      return lightFontColor;
  }
}
