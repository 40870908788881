export default [
    {
        id: 1,
        url: "35deg, #fa8072 0%, #ff1177 100%",
        name: "Tyrill"
    },
    {
        id: 2,
        url: "35deg, #0f1c21, #203a43, #2c5364",
        name: "Moon Rock"
    },
    {
        id: 3,
        url: "35deg, #ef32d9, #89fffd",
        name: "Astral"
    },
    {
        id: 4,
        url: "35deg, #1b203a, #35384a",
        name: "Asphalt"
    },
    {
        id: 5,
        url: "35deg, rgb(255, 230, 138), rgb(230, 195, 130), rgb(241, 197, 73)",
        name: "Legendary"
    },
    {
        id: 6,
        url: "35deg, #fe3b0f 0%, #228dff 100%",
        name: "Fiery Sky"
    },
    {
        id: 7,
        url: "35deg, rgb(75, 158, 69), rgb(128, 176, 112), rgb(159, 201, 53)",
        name: "Muddy"
    },
    {
        id: 8,
        url: "35deg, rgb(223, 96, 35), rgb(253, 255, 137)",
        name: "Soft Orange"
    },
    {
        id: 9,
        url: "35deg, rgb(55, 55, 55), rgb(33, 34, 38)",
        name: "Dragon Black"
    },
    {
        id: 10,
        url: "315deg, #ffac81 0%, #ff928b 74%",
        name: "Peach Spring"
    },
    {
        id: 11,
        url: "35deg, #e786ff 0%, #ecc4f6 74%",
        name: "Ice Cream"
    },
    {
        id: 12,
        url: "35deg, rgb(54, 137, 240) 0%, rgb(120, 212, 255) 100%",
        name: "I'm Blue"
    }
]
