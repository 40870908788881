import React, { useContext, useState } from "react";
import styled, { withTheme } from "styled-components";
import { MentionsInput, Mention } from "react-mentions";
import textAreaStyle from "../styleguides/textAreaStyle";
import demoUsers from "../demo/demoUsers";
import demoTags from "../demo/demoTags";
import { CreateCardContext, MAX_LIMIT_CONTENT_LENGTH } from "../context/CreateCardContext";
import useMedia from "../hooks/useMedia";
import StyledSpan from "../styleguides/StyledSpan";

const CommentFieldWrapper = styled.div`
  position: relative;
  z-index: 55;
  width: 100%;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 4px solid #eee;
  textarea {
    color: ${props => props.theme.colors.text.primary.dark};
    &::placeholder  {
      color: ${props => `${props.theme.colors.text.primary.dark}77`};
      font-style: italic;
      font-size: ${props => props.fontSize};
    }
  }
`;

const GentleReminder = styled.div``;

function CardTextInput({ theme }) {
  const { setContent, content, themeColor } = useContext(CreateCardContext);
  const [isLongText, setIsLongText] = useState(false);
  const fontSize = useMedia(
    [
       `(min-width: ${theme.screenSizes.largex})`,
       `(min-width: ${theme.screenSizes.large})`,
    ],
    [theme.fontSizes.medium, theme.fontSizes.smallx],
    theme.fontSizes.small
  );

  const fetchMentions = (query, callback) => {
    if (!query) return;
    const catchNonWordCharactersRegex = /[\W_]+/g;
    const strippedQuery = query.trim().replace(catchNonWordCharactersRegex, "");
    if (!strippedQuery || !strippedQuery.length) return;

    callback(
      demoUsers
        .filter(
          demoUser =>
            demoUser.username.toUpperCase().includes(query.toUpperCase()) ||
            demoUser.firstName.toUpperCase().includes(query.toUpperCase()) ||
            demoUser.lastName.toUpperCase().includes(query.toUpperCase())
        )
        .map(user => ({
          display: `${user.firstName} ${user.lastName} (@${user.username})`,
          id: `@${user.username}`
        }))
    );

    /* 
         fetch(`endpoint/${query}`).
        then(data => data.json())
        .then(data => data.map(user => ({
            display: `${user.fullName}`,
            id: `@${user.username}`
        }))).then(callback);
        */
  };

  const fetchTags = (query, callback) => {
    if (!query) return;

    const tagSuggestions = demoTags
      .filter(demoTag =>
        demoTag.tag.toUpperCase().includes(query.toUpperCase())
      )
      .map(tag => ({ display: "#" + tag.tag, id: tag.tag }));

    if (!tagSuggestions.length) {
      callback([
        {
          display: `#${query}`,
          id: `${query}x`
        }
      ]);
    } else {
      callback(tagSuggestions);
    }

    /* 
         fetch(`endpoint/tag/${encodeURIComponent(query)}`).then(
            res => res.json()
        ).then(res => ( res.map(tag => ({display: tag.name, id: tag.name})))).then(tags => {
            if(!tags || !tags.length) {
                callback([
                    {
                        display: `#${query}`,
                        id: `${query}x`
                    }
                ]);
            } else {
                callback(tags);
            }
        });
        */
  };

  const handleInputChange = e => {
    if(e.target.value.length > MAX_LIMIT_CONTENT_LENGTH) {
      setIsLongText(true);
      setContent(e.target.value.slice(0, MAX_LIMIT_CONTENT_LENGTH));
    } else {
      setContent(e.target.value);
      setIsLongText(false);
    }
  };

  return (
    <CommentFieldWrapper>
      <TextAreaWrapper fontSize={fontSize} color={themeColor.fontColor} background={themeColor.background}>
        <MentionsInput
          onChange={handleInputChange}
          value={content}
          style={textAreaStyle(
            theme,
            fontSize,
            themeColor
          )}
          placeholder={`Write something inspiring? 💅 Add #tags, or @mention your friends.`}
        >
          <Mention
            markup="@{__id__}"
            trigger="@"
            data={fetchMentions}
            appendSpaceOnAdd={true}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => (
              <div>
                {highlightedDisplay}
              </div>
            )}
            style={{
              fontSize: theme.fontSizes.small
            }}
          />
          <Mention
            trigger="#"
            markup="#{__display__}"
            data={fetchTags}
            appendSpaceOnAdd={true}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => (
              <div >
                {highlightedDisplay}
              </div>
            )}
            style={{
              fontSize: theme.fontSizes.small
            }}
          />
        </MentionsInput>
      </TextAreaWrapper>
      {
        isLongText && (
          <GentleReminder>
            <StyledSpan bold error tiny>Dear creative Tyriller, there is an upper limit of {MAX_LIMIT_CONTENT_LENGTH} characters on a cards content. 😕</StyledSpan>
          </GentleReminder>
        )
      }
    </CommentFieldWrapper>
  );
}

export default withTheme(CardTextInput);
