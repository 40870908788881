import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import noTouchHighlight from "../styleguides/noTouchHighlight";
import { colorPulsate } from "../utils/animations";

export const StyledHref = styled.a`
  color: ${props => props.color || props.theme.colors.text.tertiary};
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: bold;
`;

export const StyledMentionsLink = styled(({ ...props }) => <Link {...props} />)`
  color: ${props => props.color || props.theme.colors.text.tertiary};
  font-weight: bold;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.secondary};
`;

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  olor: ${props => props.color || props.theme.colors.text.contrast};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
`;

export const circularActionStyles = `
height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
  text-decoration: none;
  margin: 0;
  padding: 0;

  ${noTouchHighlight}
`;

export const CircularActionLink = styled(({ ...props }) => <Link {...props} />)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
  text-decoration: none;
  margin: 0;
  padding: 0;

  ${noTouchHighlight}

  color: ${props => props.theme.colors.text.secondary};
  background: ${props => props.theme.colors.section.primary};

  @media screen and (min-width: ${props => props.theme.screenSizes.mediumx}) {
      height: 50px;
      width: 50px;
      font-size: 20px;
  }

  ${props =>
    props.includesimage &&
    css`
      background-image: url(${props => props.image});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    `}
`;

export const CircularActionDiv = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
  margin: 0;
  padding: 0;

  ${noTouchHighlight}

  color: ${props => props.theme.colors.text.secondary};
  background: ${props => props.theme.colors.section.primary};

  ${props =>
    props.circleSizeSmallScreen &&
    css`
      height: ${props.circleSizeSmallScreen}px;
      width: ${props.circleSizeSmallScreen}px;
    `}
    
  ${props =>
    props.animate &&
    css`
      padding: ${props => props.theme.spacing.small}
        ${props => props.theme.spacing.smallx};
      animation: ${colorPulsate} 1.1s cubic-bezier(0.17, 0.67, 0.83, 0.67) 1;
    `}
    
  @media screen and (min-width: ${props => props.theme.screenSizes.mediumx}) {
      height: 50px;
      width: 50px;
      font-size: 20px;

    ${props =>
      props.circleSizeLargeScreen &&
      css`
        height: ${props.circleSizeLargeScreen}px;
        width: ${props.circleSizeLargeScreen}px;
      `}
  }

${props =>
  props.includesimage &&
  css`
    background-image: url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  `}
`;
