import React, { useEffect, useState } from "react";
import app, { FieldValue } from "../utils/firebase";
import { UserContext } from "./UserContext";

const UserProfileContext = React.createContext({
  profileInformation: null,
  error: null
});

const UserProfileProvider = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const [profileInformation, setProfileInformation] = useState(false);
  const [hasLoadedProfile, setHasLoadedProfile] = useState(false);

  const loadUserInformation = React.useCallback(async () => {
      const userRef = app
      .firestore()
      .collection("users")
      .doc(user.uid);

    const userRefDataReq = await userRef.get();

    if (!userRefDataReq.exists) {

      userRef.set({
        displayName: user.displayName,
        photoURL: user.photoURL || "",
        id: user.uid,
        lastActive: FieldValue.serverTimestamp(),
        settings: {
          dataCollectionConsent: false,
          performanceAnalyticsConsent: false,
        },
      });
    } else {
      userRef.update("lastActive", FieldValue.serverTimestamp());
      const userInfo = userRefDataReq.data();
      
      if (userInfo) {
        setProfileInformation(userInfo);
      }
    }
    setHasLoadedProfile(true);
  }, [user]);

  useEffect(() => {
    if(!!user && !profileInformation) {
        setHasLoadedProfile(false);
        loadUserInformation();
    }
    // app.auth().getRedirectResult().then(function(result) { // result.credential.token -> Facebook API access. });
  }, [user]);


  return (
    <UserProfileContext.Provider
      value={{
        profileInformation,
        hasLoadedProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export { UserProfileContext, UserProfileProvider };
