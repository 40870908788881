import styled, { css } from "styled-components";
import noTouchHighlight from "./noTouchHighlight";

const StyledInput = styled.input`
    padding: 0.7rem 1rem;
    border: 0;
    height: 50px;
    color: ${props => props.color || props.theme.colors.text.primary.dark};
    background: ${props => props.theme.colors.section.primary};
    outline: 0;
    transition: all 0.1s ease-in-out;
    width: 100%;
    border-bottom: 2px solid ${props => props.theme.colors.section.primary};
    font-size: ${props => props.theme.fontSizes.input};
    font-family: ${props => props.theme.fonts.primary};

    &::placeholder {
        color: ${props => props.theme.colors.text.primary.light};
    }

    ${props =>
      props.transformUpper &&
      css`
        text-transform: uppercase;
      `}

    ${props =>
      props.lightColor &&
      css`
        color: ${props.theme.colors.text.primary.main};
      `}

    ${noTouchHighlight}


  ${props => ` 
  @media screen and (min-width: ${props.theme.screenSizes.large}) {
    font-size: ${props.theme.fontSizes.smallx};
  }
  @media screen and (min-width: ${props.theme.screenSizes.largex}) {
    font-size: ${props.theme.fontSizes.medium};
  }
`}
`;

export const StyledLabel = styled.label`
  color: ${props => props.theme.colors.text.primary.light};
  font-size: ${props => props.theme.fontSizes.small};

  ${props => ` 
  @media screen and (min-width: ${props.theme.screenSizes.mediumx}) {
    font-size: ${props.theme.fontSizes.medium};
  }
  @media screen and (min-width: ${props.theme.screenSizes.largex}) {
    font-size: ${props.theme.fontSizes.mediumx};
  }
  `}
`;

export default StyledInput;
