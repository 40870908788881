import React, { useContext } from "react";
import styled from "styled-components";
import StyledButton from "../styleguides/StyledButton";
import Text from "../styleguides/Text";
import { CreateCommentContext } from "../context/CreateCommentContext";
import CommentInput from "./CommentInput";
import CommentPublish from "./CommentPublish";

const NewComment = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  top: 100vh;
  padding-top: ${props => props.theme.spacing.small};
`;

export default function CommentCreate({ card, onPublished }) {
  const { toggleWriteComment, isWritingComment } = useContext(
    CreateCommentContext
  );

  return (
    <NewComment>
      {isWritingComment && <CommentInput />}
      {isWritingComment && <CommentPublish card={card} onPublished={onPublished}/>}
        <StyledButton
        backgroundColor={`transparent`}
        color={`${card.themeColor.fontColor}`}
        onClick={() => toggleWriteComment()}
      >
        {isWritingComment ? (
          <Text element="span">Hide</Text>
        ) : (
          <Text element="span">
            New Comment
          </Text>
        )}
      </StyledButton>
    </NewComment>
  );
}
