import React from "react";
import styled from "styled-components";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import loadIcons from "../utils/faLibraryLoader";
import NavToolbar from "./NavToolbar";
import Welcome from "./Welcome";
import About from "./About";
import PrivateRoute from "./PrivateRoute";
import UserLogin from "./UserLogin";
import MyProfile from "./MyProfile";
import { UserContext } from "../context/UserContext";
import SwitchRoute from "./SwitchRoute";
import Navbar from "./Navbar";
import Terms from "./Terms";
import IubendaPrivacy from "./IubendaPrivacy";
import Notifications from "./Notifications";
import CardsDiscover from "./CardsDiscover";
import CardsByTag from "./CardsByTag";
import CardsFollowing from "./CardsFollowing";

const AppContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: #ffffff;
`;

loadIcons();

export default function App() {
  const { user } = React.useContext(UserContext);

  function renderPolicy(policyUrl, fallbackUrl, tabtitle, props) {
    return <IubendaPrivacy policyUrl={policyUrl} fallbackUrl={fallbackUrl} tabtitle={tabtitle} {...props} />;
  }

  return (
    <AppContainer>
      <BrowserRouter>
        <Route path="/about" component={About} />
        <Route path="/" component={Navbar} />
        <Route
          path="/privacy-policy"
          render={props => {
            return renderPolicy(
              "https://www.iubenda.com/api/privacy-policy/39044463",
              "https://www.iubenda.com/privacy-policy/39044463",
              "Tyrill - Privacy Policy",
              props
            );
          }}
        />
        <Route
          path="/terms"
          component={Terms}
        />
        <Route
          path="/cookie-policy"
          render={props => {
            return renderPolicy(
              "https://www.iubenda.com/api/privacy-policy/39044463/cookie-policy",
              "https://www.iubenda.com/privacy-policy/39044463/cookie-policy",
              "Tyrill - Cookie Policy",
              props
            );
          }}
        />
        <SwitchRoute
          path="/"
          exact
          condition={!!user}
          onTrue={CardsFollowing}
          onFalse={Welcome}
        />
        <PrivateRoute path="/user" exact component={MyProfile} />
        <PrivateRoute path="/notifications" exact component={Notifications} />
        <Route path="/" component={NavToolbar} />
        <Route path="/login" exact component={UserLogin} />
        <Switch>
          <PrivateRoute path="/discover" exact component={CardsDiscover} />
          <PrivateRoute path="/discover/:tag" exact component={CardsByTag} />
        </Switch>
      </BrowserRouter>
    </AppContainer>
  );
}
