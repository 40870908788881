import React, { memo } from "react";
import Text from '../styleguides/Text';

const patternMatcher = new RegExp("[^{}]+(?=})", "i");

// Let's memomize this "costly" function.
// This function renders @ or # tags as highlighted text, and HTML-links as links.

const NotificationText = memo(({ textColor, text, placeholders }) => {
  const lines = text.split("\n");
  const textComment = [];
  lines.forEach((element , idx)=> {
    const words = element.split(" ");
    words.forEach((word, i) => {
      const isPlaceholder = patternMatcher.exec(word);
      if (isPlaceholder) {

        const matchingPlaceholder = placeholders.find(ph => ph.key === isPlaceholder[0]);

        if (matchingPlaceholder && matchingPlaceholder.displayValue) {

          if(matchingPlaceholder.pathname) {
            textComment.push(
              <Text
                asLink
                key={i + matchingPlaceholder.key + idx}
                to={matchingPlaceholder.pathname}
                bold={"true"}
                textColor={textColor}
              >
                {`${matchingPlaceholder.displayValue} `}
              </Text>
            );
          } else {
            textComment.push(
              <Text element="span">{`${matchingPlaceholder.displayValue} `}</Text>
            );
          }
          
        } 
      } else {
        textComment.push(`${word} `);
      }
    });
    textComment.push("\n");
  });
  textComment.splice(-1);
  return <Text textColor={textColor}>{textComment}</Text>;
});

export default NotificationText;
