import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Text from "../styleguides/Text";
import { fontColorChanger } from '../utils/animations';

const LoaderWrapper = styled.div`
  span {
    animation: ${fontColorChanger} 1.5s linear infinite;
    font-size: ${props => props.theme.fontSizes.large};
    margin-right: 0.5rem;
  }

  display: flex;
  align-items: center;
`;

export default function LoadingIndicator(props) {

  return (
    <LoaderWrapper>
      <span>
        <FontAwesomeIcon icon="dharmachakra" spin />
      </span>
      <Text textColor={props.textColor || "white"}>{props.message || "Publishing.."}</Text>
    </LoaderWrapper>
  );
}
