import { useState } from 'react';
import Cookie from 'js-cookie';

export default function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return Cookie (in case try also failed in setting the value) - else initialValue.
        // This might happen in safari incognito-mode.
        return Cookie.get(key) || initialValue;
      }
    });
  
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
      value instanceof Function ? value(storedValue) : value;
      try {
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // Set a cookie for handling the value - as backup implementation if localstorage is not available!
        Cookie.set(key, JSON.stringify(valueToStore), { expires: 7})
      }
    };

    const removeItem = () => {
      try {
        window.localStorage.removeItem(key);
      } catch(e) {
        
      }
    }
  
    return [storedValue, setValue, removeItem];
  }