import styled, { css } from "styled-components";
import { animated } from "react-spring";
import noTouchHighlight from "./noTouchHighlight";

const Modal = styled(animated.div)`
  position: absolute;
  top: 0;
  padding: ${props => props.theme.spacing.smallx} 0;
  z-index: 61;
  width: 100%;
  overflow: hidden;
  height: 100vh;
`;

const Overlay = styled.div`
  background-color: transparent;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  height: 100vh;
`;

const TopSection = styled.div`
  z-index: 3;
  padding: 0 ${props => props.theme.spacing.smallx};
  display: flex;
  width: 100%;
  align-items: center;
`;

const CloseWrapper = styled.div`
  color: ${props => props.theme.colors.text.contrast};
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
  ${noTouchHighlight};
`;

const Container = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.columns},
    minmax(${props => `${props.columnsize || 150}px`}, 1fr)
  );
  grid-gap: ${props => props.theme.spacing.small};
  padding: ${props => props.paddingtb || props.theme.spacing.small}
    ${props => props.theme.spacing.small};
  cursor: pointer;
  will-change: width, height;
  min-height: 50px;
  width: 100%;
  max-height: ${props => (props.height ? props.height : "85vh")};
  overflow: auto;
  margin: 0 auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${noTouchHighlight}
`;

const ModalFooter = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  padding-top: ${props => props.theme.spacing.small};
  padding-bottom: ${props => props.theme.spacing.small};
  z-index: 3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.section.primary};
  border-radius: 10px;
  
  box-shadow: 0 12.5px 40px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
  z-index: 2;
  height: 75vh;
  position: relative;

  ${props => `
  @media all and (max-width: ${props.theme.screenSizes.smallx}) {
    margin: ${props.theme.spacing.small};
  }
  @media all and (min-width: ${props.theme.screenSizes.smallx}) {
    margin:  ${props.theme.spacing.smallx};
  }
  @media all and (min-width: ${props.theme.screenSizes.medium}) {
    margin: ${props.theme.spacing.medium};
  }
  @media all and (min-width: ${props.theme.screenSizes.largex}) {
    margin: ${props.theme.spacing.mediumx};
  }
  `}
  ${props => props.excessiveBordered && css`
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  `}
  overflow: hidden;
  overflow-y: auto;
`;

export { Modal, TopSection, CloseWrapper, Container, ContentWrapper, Overlay, ModalFooter };
