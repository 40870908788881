import React from "react";
import { FlexKid } from "../styleguides/Flex";
import Text from "../styleguides/Text";
import styled, { withTheme } from "styled-components";
import NotificationText from "./NotificationText";
import { CircularActionDiv } from "../styleguides/StyledLink";
import moment from "moment";
import { useGesture } from "react-with-gesture";
import { useSpring, useTransition, animated, interpolate } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledButton from "../styleguides/StyledButton";

const Item = styled(animated.div)`
  padding: 2rem;
  background-color: ${props => props.theme.colors.section.primary};
  cursor: pointer;
`;

const SlidableContent = styled(animated.div)`
  display: flex;
  backface-visibility: hidden;
`;

const now = moment();

function Notification({
  notification,
  onNotificationClick,
  onNotificationRemove,
  theme
}) {
  if (!notification) {
    return null;
  }
  const isNew = !notification.seen;

  const [bind, { delta, down }] = useGesture();

  const { x, bg, size } = useSpring({
    x: down ? delta[0] : 0,
    bg: `linear-gradient(${isNew ? "50" : "90"}deg, ${delta[0] < -30 ? `${isNew ? theme.colors.section.secondary : "#fda6b7"} 0%, #fb5373)` : `${isNew ? `${theme.colors.section.secondary} 0%, ${theme.colors.section.secondary}` : `${theme.colors.section.primary} 0%, ${theme.colors.section.primary}`}`}`,
    size: down ? 1.05 : 1,
    immediate: name => down && name === "x"
  });

  const isDeleting = delta[0] < -30;

  const transitions = useTransition(isDeleting, null, {
    from: { opacity: 0, position: "absolute" },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });


  return (
    <Item
      {...bind()}
      style={{ background: bg }}
      unseen={isNew.toString()}
      onClick={() => {
        onNotificationClick();
      }}
    >
      <SlidableContent
        style={{
          transform: interpolate(
            [x, size],
            (x, s) => `translate3d(${delta[0] > 0 ? 0 : x}px,0,0) scale(${s})`
          )
        }}
      >
        {notification.picture && (
          <FlexKid spaceRight>
            <CircularActionDiv
              includesimage="true"
              image={notification.picture}
              circleSizeSmallScreen={40}
            />
          </FlexKid>
        )}
        <FlexKid spaceRight>
          <NotificationText
            placeholders={notification.placeholders}
            text={notification.text}
            textColor={
              notification.seen
                ? isDeleting
                  ? "white"
                  : "primary.main"
                : "white"
            }
          />
          <Text
            size="smallest"
            textColor={
              notification.seen
                ? isDeleting
                  ? "white"
                  : "primary.main"
                : "white"
            }
          >
            {" "}
            {moment
              .duration(
                now.diff(
                  notification.updatedAt
                    ? notification.updatedAt.toDate
                      ? notification.updatedAt.toDate()
                      : notification.updatedAt
                    : new Date()
                )
              )
              .humanize()}{" "}
            ago
          </Text>
        </FlexKid>
        <FlexKid hugRight centerContent>
          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div key={key} style={props}>
                  <StyledButton
                    title="Remove this notification"
                    backgroundColor="transparent"
                    onClick={() => onNotificationRemove(notification.id)}
                  >
                    <Text textColor="white" size="bigger">
                      <FontAwesomeIcon
                        icon={["far", "trash-alt"]}
                      ></FontAwesomeIcon>
                    </Text>
                  </StyledButton>
                </animated.div>
              )
          )}
        </FlexKid>
      </SlidableContent>
    </Item>
  );
}

export default withTheme(Notification);
