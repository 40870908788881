import * as React from 'react';
import { NotificationContext } from './NotificationContext';

const DEFAULT_APP_TITLE = 'Tyrill';
const DEFAULT_APP_COLOR = '#72bbfa';

const TitleContext = React.createContext({
  titleColor: DEFAULT_APP_COLOR,
  title: DEFAULT_APP_TITLE,
  path: "/",
  tabTitle: DEFAULT_APP_TITLE,
  lastPath: "/"
});


function TitleContextProvider(props) {
  const [titleColor, setTitleColor] = React.useState(DEFAULT_APP_COLOR);
  const [title, setTitle] = React.useState(DEFAULT_APP_TITLE);
  const [path, setPath] = React.useState("/");
  const [lastPath, setLastPath] = React.useState("/");
  const [tabTitle, setTabTitle] = React.useState(DEFAULT_APP_TITLE);
  const { notifications, queryId } = React.useContext(NotificationContext);
  
  React.useEffect(() => {
    if (notifications && notifications.length > 0) {
      const countUnseenNotifications = notifications.filter(
        n => !Object.keys(n).includes("seen")
      );

      if (countUnseenNotifications.length > 0) {
          document.title = `(${countUnseenNotifications.length}) ${tabTitle}`;
      } else {
        document.title = `${tabTitle}`;
      }
    } else {
      document.title = `${tabTitle}`;
    }
  }, [queryId, tabTitle]);

  return (
    <TitleContext.Provider value={{
      titleColor,
      setTitleColor,
      title,
      setTitle,
      path,
      setPath,
      tabTitle,
      setTabTitle,
      lastPath,
      setLastPath
    }}>
      {props.children}
    </TitleContext.Provider>
  );
}

export {
  TitleContext,
  TitleContextProvider,
  DEFAULT_APP_COLOR,
  DEFAULT_APP_TITLE
};
