import React from "react";
import { LocationContext } from "../context/LocationContext";
import styled, { css } from "styled-components";
import Flex from "../styleguides/Flex";
import Text from "../styleguides/Text";
import {
  MarginMediumTb,
  MarginSmallTb,
  MarginMedium
} from "../styleguides/ContentPadders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colorPulsate } from "../utils/animations";
import StyledButton, { ButtonGroup } from "../styleguides/StyledButton";

const AnimatedPulse = styled.div`
  animation: ${colorPulsate} 1.1s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MaxWidthDescription = styled.div`
  max-width: 500px;
`;

const LocationIcon = styled(MarginMedium)`
  @media all and (max-height: 500px) {
    display: none;
  }
  @media all and (max-width: 380px) {
    display: none;
  }
`;

const ButtonGroupPadder = styled(MarginMediumTb)`
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  max-width: 550px;
`;

export default function LocationRequiredPopup({
  fullScreen,
  title,
  description
}) {
  const {
    isPrompt,
    isDenied,
    triggerLocationPrompt,
    isAvailable
  } = React.useContext(LocationContext);

  return (
    <Flex
      centerContent
      direction="column"
      height={fullScreen && "100vh"}
      fullWidth={fullScreen}
    >
      <Flex>
        <LocationIcon>
          <AnimatedPulse>
            <Text
              size="biggest"
              variant="title"
              element="h2"
              textColor="secondary"
              aria-label="location icon"
            >
              {isDenied ? (
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon="map-marker"></FontAwesomeIcon>
                  <FontAwesomeIcon
                    icon="lock"
                    transform="shrink-12 up-2"
                    inverse
                  ></FontAwesomeIcon>
                </span>
              ) : (
                <FontAwesomeIcon icon="globe-europe"></FontAwesomeIcon>
              )}
            </Text>
          </AnimatedPulse>
        </LocationIcon>
        <div>
          <Text
            size="bigger"
            variant="title"
            element="h2"
            fontType="alternative"
            textColor={isDenied ? "error" : "secondary"}
            bold
          >
            {isDenied ? `No Access` : title ? title : "Discover"}
          </Text>
          <MaxWidthDescription>
            <Text element="p">
              {isDenied &&
                `It looks like you've denied Tyrill access to your current location.`}
              {!isDenied &&
                isAvailable &&
                (description
                  ? description
                  : `Tyrill uses your current location to find cards shared by others nearby. We will not share your location.`)}
              {!isAvailable &&
                "(GPS) Location services does not seem to be supported in your browser, or has been disabled."}
            </Text>
            {(isDenied || !isAvailable) && (
              <MarginSmallTb>
                <Text element="p">
                  We've gathered a few helpul resources on how to re-enable this
                  for Firefox, Safari and Chrome.
                </Text>
              </MarginSmallTb>
            )}
          </MaxWidthDescription>
        </div>
      </Flex>

      {isPrompt && (
        <ButtonGroupPadder fullWidth>
          <StyledButton
            fullWidth
            secondary
            rounded
            onClick={() => triggerLocationPrompt()}
          >
            Activate location <FontAwesomeIcon icon="map-marker" />
          </StyledButton>
        </ButtonGroupPadder>
      )}

      {isDenied && (
        <ButtonGroupPadder fullWidth={window.innerWidth < 550}>
          <ButtonGroup fullWidth={window.innerWidth < 550}>
            <StyledButton
              fullWidth={window.innerWidth < 550}
              rounded
              as={"a"}
              href="https://support.google.com/chrome/answer/114662?hl=en"
              target="_blank"
              title="Open Google Chrome support article for Location Services"
            >
              Chrome Support <FontAwesomeIcon icon={["fab", "chrome"]} />
            </StyledButton>

            <StyledButton
              fullWidth={window.innerWidth < 550}
              rounded
              as={"a"}
              href="https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites"
              target="_blank"
              title="Open Firefox support article for Location Services"
            >
              Firefox Support <FontAwesomeIcon icon={["fab", "firefox"]} />
            </StyledButton>

            <StyledButton
              fullWidth={window.innerWidth < 550}
              rounded
              as={"a"}
              href="https://support.apple.com/en-us/HT203033"
              target="_blank"
              title="Open iOS Safari support article for Location Services"
            >
              iOS Safari Support <FontAwesomeIcon icon={["fab", "safari"]} />
            </StyledButton>
            {isDenied && (
              <Text textColor="primary.light" size="smallest">
                On iOS, refresh the page and touch "Activate location" again.
              </Text>
            )}
          </ButtonGroup>
        </ButtonGroupPadder>
      )}
    </Flex>
  );
}
