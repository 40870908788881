import { useState } from "react";
import app from "../utils/firebase";

export default function useFirestoreDocument(opts) {
  const [error, setError] = useState(null);

  function createNewDoc() {
    try {
      return app
        .firestore()
        .collection(opts.collection || "cards")
        .doc();
    } catch (e) {
      setError(e);
    }
  }

  function removeDoc(id, onRemoveComplete) {
    app.firestore().collection(opts.collection).doc(id).delete().then(function() {
      onRemoveComplete(id);
  }).catch(function(error) {
      setError(error);
  });
  }

  function setDocData(doc, docData) {
    doc.set(docData);
  }

  return [createNewDoc, setDocData, error, removeDoc];
}

