export default function(theme, noBorderRadius) {
    return {
        control: {},
    highlighter: {
        overflow: 'hidden'
    },
    input: {
        margin: 0
    },
    '&singleLine': {
        control: {
            display: 'inline-block',
            width: 130
        },
        highlighter: {
            padding: 1,
            border: '2px inset transparent'
        },
        input: {
            padding: 3,
            border: '3px inset'
        }
    },
    '&multiLine': {
        control: {
            backgroundColor: "#ffffff",
            borderRadius: noBorderRadius ? "none" : "5px",
        },
        highlighter: {
            padding: "0.4rem 0.8rem",
            width: "100%",
            overflow: "auto",
        },
        input: {
            padding: "0.4rem 0.8rem",
            outline: 'none',
            fontSize: theme.fontSizes.small,
            border: 0,
            resize: 'none',
            width: "100%",
            height: "150px",
            overflow: "auto",
            "&focused": {
                overflow: "auto"
            }
        }
    },
    suggestions: {
        list: {
            backgroundColor: "#fff",
            border: "1px solid #eee",
            zIndex: 154
        },
        item: {
            padding: "0.7rem 1rem",
            color: "#666",
            borderBottom: "1px solid #eee",
            transition: "all 0.15s ease-in-out",
            "&focused": {
                color: theme.colors.text.secondary
            },
            zIndex: 155
        }
    }
    }
}