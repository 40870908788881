import styled, { css } from "styled-components";

const StyledParagraph = styled("p")`
    font-size: ${props => props.theme.fontSizes.medium};
    margin: 0;
    
    ${props =>
      props.color &&
      css`
        color: ${props => props.color};
      `}
      ${props =>
        props.white &&
        css`
          color: ${props => props.theme.colors.text.contrast};
        `}  
    ${props =>
      props.error &&
      css`
        color: ${props => props.theme.colors.text.error};
      `}
    ${props =>
      props.offWhite &&
      css`
        color: ${props => props.theme.colors.text.light};
      `}
    ${props =>
      props.tiny &&
      css`
        font-size: ${props => props.theme.fontSizes.tiny};

        ${props => `
        @media screen and (min-width: ${props.theme.screenSizes.largex}) {
          font-size: ${props.theme.fontSizes.small}
         }
        `};
      `}
      ${props =>
        props.tinyx &&
        css`
          font-size: ${props => props.theme.fontSizes.tinyx};

          ${props => `
          @media screen and (min-width: ${props.theme.screenSizes.largex}) {
            font-size: ${props.theme.fontSizes.small}
           }
          `};
        `}  
    ${props =>
      props.small &&
      css`
        font-size: ${props => props.theme.fontSizes.small};
      `}
    ${props =>
      props.medium &&
      css`
        font-size: ${props => props.theme.fontSizes.medium};
      `}
    ${props =>
      props.large &&
      css`
        font-size: ${props => props.theme.fontSizes.large};
      `}  
    ${props =>
      props.italic &&
      css`
        font-style: italic;
      `}  
      ${props =>
        props.underlined &&
        css`
          text-decoration: underline;
        `}  
        ${props =>
          props.bold &&
          css`
            font-weight: bold;
          `}   
    ${props =>
      props.alternative &&
      css`
        font-family: ${props => props.theme.fonts.alternative};
      `}  
      ${props =>
        props.withIconRight &&
        css`
          svg {
            margin-left: ${props => props.theme.spacing.tinyx};
          }
        `}       
`;

export default StyledParagraph;
