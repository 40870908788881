import React, { useContext, useEffect } from "react";
import { CardBrowserContext } from "../context/CardBrowserContext";
import CardDeck from "./CardDeck";
import MiddleScreenLoadingIndicator from "./MiddleScreenLoadingIndicator";
import { getRandomPositiveEmoji } from "../utils/positiveReactionEmojis";
import {
  TitleContext,
  DEFAULT_APP_TITLE,
  DEFAULT_APP_COLOR
} from "../context/TitleContext";
import CreateCard from "./CreateCard";

import { CreateCardProvider } from "../context/CreateCardContext";
import { CreateCardToolboxProvider } from "../context/CreateCardToolboxContext";
import { tyrillErrorToast } from "../utils/tyrillToaster";

const likeEmoji = getRandomPositiveEmoji();

export default function CardsFollowing(props) {
  
  const {
    posts,
    postQueryKey,
    isLoadingCards,
    hasLoadedCards,
    refetchCards,
    isAddingCard,
    storageError,
    cardError
  } = useContext(CardBrowserContext);

  const { setTitle, setTitleColor, setTabTitle, setPath, tabTitle, setLastPath } = useContext(TitleContext);

  useEffect(() => {
    setTitle(DEFAULT_APP_TITLE);
    setTitleColor(DEFAULT_APP_COLOR);
    setPath("/");
    setTabTitle("Tyrill - Home");
    setLastPath("/");
    refetchCards();
  }, []);

  useEffect(() => {
    if (storageError) {
      tyrillErrorToast(storageError.message, 5000, "card-error");
    }
    if (cardError) {
      tyrillErrorToast(cardError.message, 5000, "card-error");
    }
  }, [storageError, cardError]);

  return (
    <React.Fragment>
      {(isLoadingCards || !hasLoadedCards) && (
        <MiddleScreenLoadingIndicator label={`Hang in there.. ${likeEmoji}`} />
      )}
      {(!isLoadingCards && hasLoadedCards) && (!posts || !(posts.length > 0)) && (
        <div>
          <MiddleScreenLoadingIndicator
            label={`You've seen it all. For now. ${likeEmoji}`}
          />
        </div>
      )}
      {isAddingCard && (
        <CreateCardProvider>
          <CreateCardToolboxProvider>
            <CreateCard {...props} previousDocumentTitle={tabTitle} />
          </CreateCardToolboxProvider>
        </CreateCardProvider>
      )}
      <CardDeck posts={posts} key={postQueryKey} />
    </React.Fragment>
  );
}
