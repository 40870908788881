import React, { createContext, useState, useEffect, useContext } from "react";
import useCardFirestore from "../hooks/useCardFirestore";
import useFirestoreDocument from "../hooks/useFirestoreDocument";
import useCloudStorage from "../hooks/useCloudStorage";
import uuidv4 from "uuid/v4";
import app, { FieldValue, perf } from "../utils/firebase";
import { UserContext } from "./UserContext";
import demoPosts from '../demo/demoPosts';

const CardBrowserContext = createContext({
  posts: [],
  refetchCards: tag => {},
  setPosts: () => {},
  addPost: () => {},
  isAdding: false,
  progress: 0,
  processingItem: 1,
  fetchNextCards: () => {},
  swipeCard: card => {},
  postQueryKey: 2,
  isLoadingCards: false,
  isUploading: false,
  hasLoadedCards: false,
  isAddingCard: false,
  setIsAddingCard: state => {},
  cardError: null,
  storageError: null,
  hasHeartedCard: false,
  setHasHeartedCard: hasHeartedCard => {},
  heartCard: post => {}
});

function CardBrowserProvider({ children }) {
  const [posts, setPosts] = useState(demoPosts);
  const [isAdding, setIsAdding] = useState(false);
  const [hasLoadedCards, setHasLoadedCards] = useState(false);
  const [progress, setProgress] = useState(0);
  const [processingItem, setProcessingItem] = useState(1);
   // eslint-disable-next-line
  const [browsingTag, setBrowsingTag] = useState(null);
  const [postQueryKey, setPostQueryKey] = useState(uuidv4());
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [storageError, setStorageError] = useState(null);
  const [hasHeartedCard, setHasHeartedCard] = useState(false);

  const userContext = useContext(UserContext);

  const [
    isLoadingCards,
    cards, // eslint-disable-next-line
    error,  // eslint-disable-next-line
    fetchCards,
    fetchCardsStartingAfter
  ] = useCardFirestore({
    collection: "cards",
    initialData: null,
    limit: 5,
    onError: e => {
      setCardError(e);
    }
  });

  const [createNewDoc, setDocData] = useFirestoreDocument({
    collection: `cards`
  });

  // eslint-disable-next-line
  const [isUploading, err, uploadFile, downloadFile] = useCloudStorage({
    onProgress: progress => {
      setProgress(progress);
    },
    onError: e => {
      const trace = perf.trace("cloud-storage-errors");

      if (e.code) {
        switch (e.code) {
          case "storage/quota-exceeded":
            trace.incrementMetric('error-storage-q-exceceded', 1);

            setStorageError({
              message:
                "Tyrill cannot retrieve any more data in a while! Check in later. 😥",
              code: 402
            });
            break;
          default:
            trace.incrementMetric(e.code, 1);
            setStorageError(e);
            break;
        }
      } else {
        trace.incrementMetric("unknown-error-code", 1);
        setStorageError(e);
      }
    }
  });

  useEffect(() => {
    setStorageError(null);
    setCardError(null);
    setHasLoadedCards(false);
    resolveCardUrls();
  }, [cards]);

  function fetchNextCards() {
    setPosts([]);
    setHasLoadedCards(false);
    fetchCardsStartingAfter(browsingTag);
  }

  function refetchCards(tag) {
    const isProduction = true;

    if(isProduction) {
      const trace = perf.trace("fetch-cards-data");
      trace.start();
      setPosts([]);
      setHasLoadedCards(false);
      setBrowsingTag(tag);
      fetchCards(tag);
      trace.stop();
    }
   
  }

  function swipeCard(card) {
    const swipeRef = app
      .firestore()
      .collection("cards")
      .doc(card.id)
      .collection("views")
      .doc(userContext.user.uid);

    swipeRef.get().then(docSnapshot => {
      if (!docSnapshot.exists) {
        swipeRef.set({
          createdAt: FieldValue.serverTimestamp(),
          displayName: userContext.user.displayName,
          photoURL: userContext.user.photoURL
        });
      }
    });
  }
  
  function heartCard(card) {
    const heartRef = app
      .firestore()
      .collection("cards")
      .doc(card.id)
      .collection("hearts")
      .doc(userContext.user.uid);

      heartRef.get().then(docSnapshot => {
      if (!docSnapshot.exists) {
        heartRef.set({
          id: userContext.user.uid,
          createdAt: FieldValue.serverTimestamp(),
          displayName: userContext.user.displayName,
          photoURL: userContext.user.photoURL
        });
      }
    });

    swipeCard(card);
  }

  async function resolveCardUrls() {
    
    if (cards && cards.length) {
      const trace = perf.trace("download-cards-data");
      trace.start();
      const psts = await Promise.all(
        cards.map(async card => {
          card.overlays = await Promise.all(
            card.overlays.map(async overlay => {
              if (overlay.source === "UPLOAD") {
                overlay.sources = await Promise.all(
                  overlay.sources.map(async source => {
                    const resolvedUrl = await downloadFile(source.path).then(
                      url => Promise.resolve(url)
                    );
                    return {
                      ...source,
                      resolvedUrl
                    };
                  })
                );
                return overlay;
              } else {
                return overlay;
              }
            })
          );

          return card;
        })
      );
      setPosts(psts);
      setHasLoadedCards(true);
      setPostQueryKey(uuidv4());
      trace.stop();
    } else {
      setHasLoadedCards(true);
    }
  }

  async function addPost(post, onComplete) {
    setIsAdding(true);

    const newCardRef = createNewDoc();

    post.overlays = await Promise.all(
      post.overlays.map(async (overlay, idx) => {
        setProgress(0);
        setProcessingItem(idx + 1);

        if (overlay.source === "UPLOAD") {
          const s = await uploadFile(overlay, newCardRef.id).then(source => {
            return Promise.resolve({
              id: Math.floor(Math.random() * 13301) + 1,
              type: overlay.type,
              source: "UPLOAD",
              sources: [source]
            });
          });

          return s;
        } else {
          return overlay;
        }
      })
    );

    setDocData(newCardRef, post);

    setIsAdding(false);
    onComplete();
  }

  return (
    <CardBrowserContext.Provider
      value={{
        posts,
        setPosts,
        addPost,
        processingItem,
        progress,
        isAdding,
        refetchCards,
        fetchNextCards,
        swipeCard,
        postQueryKey,
        isLoadingCards,
        isUploading,
        hasLoadedCards,
        browsingTag,
        isAddingCard,
        setIsAddingCard,
        cardError,
        storageError,
        hasHeartedCard,
        setHasHeartedCard,
        heartCard
      }}
    >
      {children}
    </CardBrowserContext.Provider>
  );
}

export { CardBrowserProvider, CardBrowserContext };
