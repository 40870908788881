import React from "react";
import styled from "styled-components";
import { pulsate } from "../utils/animations";

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PulsatingLabel = styled.div`
    animation: ${pulsate} 2s cubic-bezier(.17,.67,.83,.67) infinite; 
    font-size: 5vw;
    color: #ffffff;
    background-color: rgba(0,0,0,0.55);
    border-radius: 10px;
    padding: 1rem 0.5rem;
    position: absolute;
    top: 20vh;
    z-index: 2;
    @media screen and (min-width: 1200px) {
        font-size: 2rem;
    }
    `;

export default function MiddleScreenLoadingIndicator({ label, children }) {
  
   return (
    <div>
      <LoaderWrapper>
          <PulsatingLabel>
              <span>{label || "Hang on..."}</span>
          </PulsatingLabel>
      
      </LoaderWrapper>
    </div>
  );
}
