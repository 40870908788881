import React from "react";
import styled from "styled-components";
import Text from "../styleguides/Text";
import {
  MarginLarge,
  MarginMedium
} from "../styleguides/ContentPadders";
import ScrollableView from "../styleguides/ScrollableView";
import { backgroundColorChanger } from "../utils/animations";
import { StyledLink } from "../styleguides/StyledLink";
import StyledTextLink from "../styleguides/StyledTextLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TitleContext } from "../context/TitleContext";

const Section = styled.section`
  padding: ${props => props.theme.spacing.smallx};
`;

const AnimatedSection = styled(Section)`
  animation: ${backgroundColorChanger} 75s linear infinite;
`;

const StickySectionHeader = styled(Section)`
  background-color: ${props => props.theme.colors.section.primary};
  position: sticky;
  top: 45px;
  z-index: 2;
`;

const BackStickySection = styled(Section)`
  position: sticky;
  top: 0;
  z-index: 2;
  animation: ${backgroundColorChanger} 75s linear infinite;
`;

export default function Terms() {

  const { setTabTitle } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTabTitle("Tyrill - Terms of Use")
  }, []);

  return (
      <ScrollableView zIndex={8} background={"#ffffff"}>
        <BackStickySection>
          <StyledLink to="/">
            <Text element="span" size="bigger" bold textColor="white">
              <FontAwesomeIcon icon="arrow-left" /> Back to Tyrill
            </Text>
          </StyledLink>
        </BackStickySection>
        <AnimatedSection>
          <Text
            variant="title"
            element="h1"
            textColor="white"
            size="biggest"
            fontType="alternative"
          >
            Terms of Use
          </Text>
          <MarginLarge>
            <Text size="smaller" textColor="white">
              Also see our{" "}
              <StyledTextLink to="/privacy-policy">
                Privacy Policy
              </StyledTextLink>{" "}
              and{" "}
              <StyledTextLink to="/cookie-policy">Cookie Policy</StyledTextLink>
              .
            </Text>
          </MarginLarge>
        </AnimatedSection>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            1. General
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Agreement
            </Text>
            <Text variant="subtitle" element="p">
              By using the web service provided by tyrill.com (“Tyrill”, “the
              Service”, “the Web Service”), you are agreeing to all of the terms
              and conditions of this Agreement between you and Tyrill. By using
              the Service, you also agree that you will (i) not violate these
              Terms (ii) not violate, plagiarize, or infringe upon the rights of
              any third party, including copyright, trademark, privacy or other
              personal or proprietary rights.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Rights
            </Text>
            <Text variant="subtitle" element="p">
              Tyrill reserves the right, in its discretion, to change or modify
              all or any part of this Agreement at any time, effective
              immediately upon notice published on the Web Service.
            </Text>
          </MarginLarge>
        </Section>

        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            2. Web Service
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Copyrighted Material
            </Text>
            <Text variant="subtitle" element="p">
              You acknowledge that this service contains information and/ or
              software, photographs, audio, video clips, graphics, links and
              other material that are protected by copyright, trademark or other
              proprietary rights of tyrill.com or third parties, including but
              not limited to product names, logos, designs, titles, and words or
              phrases which may be registered in certain jurisdictions
              (collectively, the «Content»). You agree to comply with any
              additional copyright notices, information, or restrictions
              contained in any Content available on or accessed through
              tyrill.com.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Automated and Unauthorized Use
            </Text>
            <Text variant="subtitle" element="p">
              You may not use spiders, robots, data mining techniques or other
              automated devices or programs to catalog, download or otherwise
              reproduce, store or distribute content available on Tyrill. You
              may only access and modify the content on Tyrill through the
              interfaces provided to you by tyrill.com.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Selling Access
            </Text>
            <Text variant="subtitle" element="p">
              You may not sell or resell use of, or access to, the Web Service
              and the Content provided to any third party.
            </Text>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            3. Publishing Content and Use
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Trademarks and Protected Content
            </Text>
            <Text variant="subtitle" element="p">
              You are not allowed without express authorization from the company
              or business, to publish content including a company or business
              trademark-protected material (like logo, product name or company
              name) in a way that may mislead others in regards to your
              affiliation with the company or business. By publishing any
              content to Tyrill, you confirm that you have the legal rights to
              the content you publish.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Pornographic, Explicit Graphic or Sexual Content
            </Text>
            <Text variant="subtitle" element="p">
              You may not publish, link to, or otherwise spread content that is
              pornographic or sexually explicit. You agree that by publishing
              any sensitive content (such as content including nudity and mild
              violence), the content may be given certain access restrictions,
              modifications or be removed without notice. You may not publish,
              link to, or otherwise spread content that includes explicit
              graphic or violent content, such as animal abuse or bodily harm.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Hateful and Threatening Content
            </Text>
            <Text variant="subtitle" element="p">
              You may not publish, link to, or otherwise spread content that is
              hateful, or used to mock others. You may not publish, link to, or
              otherwise spread content that includes threat of violence to
              others.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Private Content
            </Text>
            <Text variant="subtitle" element="p">
              You may not publish, link to, or otherwise spread content that
              includes other people's private or sensitive information
              (information used to identify an individual), such as National
              Identity Numbers / Social Security Numbers, credit card numbers,
              street address, email or phone numbers without their explicit
              consent.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Promoted Content
            </Text>
            <Text variant="subtitle" element="p">
              You may not use Tyrill to promote third-party content, such as
              products, services or brands, without clearly notifying other
              users that your content is promoted (in example, by adding a "This
              is an ad." or "Promoted." description where it is possible to do
              so).
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Spam
            </Text>
            <Text variant="subtitle" element="p">
              You may not publish content to Tyrill for the purpose of spamming,
              such as repeatedly publishing content. Behavior that may be
              identified as spam includes, but is not limited to:
            </Text>

            <MarginMedium>
              <ul>
                <li>
                  <Text variant="body" element="p">
                    Posting repeatedly, or frequently comments with the same or
                    similar message.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Posting repeatedly, or frequently cards with the same or
                    similar content in the same or multiple categories.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Posting cards with content that has the purpose to lead
                    users to unrelated external sites, or includes content in
                    the card that is unrelated to the category the card is
                    posted in.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Posting cards with content that is used for marketing
                    purposes (in order to sell or promote a product) in
                    unrelated, or popular categories.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Using multiple accounts to post the same content. This
                    includes purchase of services that provides this or similar
                    functionality.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Repeatedly (or in a short period of time) giving out
                    "Hearts" to cards, in an attempt to either increase yours or
                    others popularity. This includes purchase of such services.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Repeatedly (or in a short period of time) follow multiple
                    users, in an attempt to either increase your popularity.
                    This includes purchase of such services.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Repeatedly (or in a short period of time) follow / unfollow
                    a user, or multiple users. This includes purchase of such
                    services.
                  </Text>
                </li>
                <li>
                  <Text variant="body" element="p">
                    Cards, Accounts or Comments that have been flagged by an
                    adequate amount of users as Spam.
                  </Text>
                </li>
              </ul>
            </MarginMedium>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            4. Conduct
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Access
            </Text>
            <Text variant="subtitle" element="p">
              In order to take advantage of some aspects of the Web Service, it
              may be necessary for you to register and create an account. Your
              account is for your sole, personal use, you may not authorize
              others to use your account, and you may not assign or otherwise
              transfer your account to any other person or entity.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Posting Content
            </Text>
            <Text variant="subtitle" element="p">
              Including terms outlined in point "3. Content and Use", You shall
              not post on the Web Service any content which (a) is libelous,
              defamatory, obscene, pornographic, abusive, harassing or
              threatening; (b) contains viruses or other contaminating or
              destructive features; (c) violates the rights of others, such as
              content which infringes any copyright, trademark, patent, trade
              secret or violates any right of privacy or publicity; or (d)
              otherwise violates any applicable law or reputation. You may not
              post on the Web Service any links to any external Internet sites
              that are obscene or pornographic, or used to compromise a user's
              privacy or gain access to a user's private or sensitive
              information (such as login information).
            </Text>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            5. No Endorsement
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              External content
            </Text>
            <Text variant="subtitle" element="p">
              The Web Service may contain links to sites on the Internet, which
              are owned and operated by third parties (the «External Sites»).
              You acknowledge that Tyrill is not responsible for the
              availability of, or the content located on or through any External
              Site.
            </Text>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            6. Indemnity
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Indemnity
            </Text>
            <Text variant="subtitle" element="p">
              You agree to indemnify, defend and hold Tyrill and its affiliates,
              and their respective officers, directors, owners, agents,
              information providers and licensors (collectively, «the Parties»,
              "Party") harmless from and against any and all claims, liability,
              losses, costs and expenses (including attorneys’ fees) incurred by
              any Party in connection with any use or alleged use of the service
              under your user name by any person, whether or not authorized by
              you. Tyrill reserves the right, at your expense, to assume the
              exclusive defense and control of any matter otherwise subject to
              indemnification by you, and in such case, you agree to cooperate
              with Tyrill’s defense of such claim.
            </Text>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            7. Termination of Service
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Termination of Access
            </Text>
            <Text variant="subtitle" element="p">
              Administrators (“Webmasters”, “Developers”, “Moderators”) of
              Tyrill may, in its sole discretion, terminate or suspend your
              access to all or part of the Site or the Services offered for any
              reason, including breach or assignment of this Agreement.
              Termination of this Agreement for any reason, will not affect
              survival of those provisions of this Agreement for which survival
              is equitable or appropriate.
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              Termination of the Web Service
            </Text>
            <Text variant="subtitle" element="p">
              Tyrill may change, suspend or discontinue all or any aspect of the
              Web Service at any time, including the availability of any
              feature, database, or Content, without prior notice or liability.
            </Text>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            8. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text
              variant="title"
              element="h3"
              gutterBottom
              fontType="alternative"
            >
              PROVIDED "AS IS"
            </Text>
            <Text variant="subtitle" element="p">
              THIS SITE AND ALL CONTENT AND OTHER MATERIALS MADE AVAILABLE ON
              THE SITE, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
              “WITH ALL FAULTS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER
              EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
              LAW, TYRILL.COM EXPRESSLY DISCLAIMS ALL WARRANTIES,
              REPRESENTATIONS, AND UNDERTAKINGS OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF TITLE,
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NONINFRINGEMENT. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR
              USE OF THE SITE AND YOUR RELIANCE THEREON. NO OPINION, ADVICE, OR
              STATEMENT OF TYRILL.COM, WHETHER MADE ON THE SITE, OR OTHERWISE,
              SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT
              LIMITING THE FOREGOING, TYRILL.COM DOES NOT WARRANT THAT THE SITE
              WILL BE AVAILABLE; THAT YOUR USE OF THE SITE WILL BE TIMELY,
              UNINTERRUPTED, ERROR-FREE, OR SECURE; THAT DEFECTS OR ERRORS WILL
              BE CORRECTED; OR THAT THE SITE (OR THE SERVER THAT MAKES IT
              AVAILABLE) IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.{" "}
              <Text variant="subtitle" element="span" bold>
                SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED OR
                OTHER WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.{" "}
              </Text>
            </Text>
          </MarginLarge>
          <MarginLarge>
            <Text variant="subtitle" element="p">
              TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, IN
              NO EVENT SHALL TYRILL.COM OR ITS THIRD PARTIES, BE LIABLE FOR ANY
              DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
              ECONOMIC, EXEMPLARY OR OTHER DAMAGES ARISING OUT OF OR IN ANY WAY
              CONNECTED WITH OR RELATING TO YOUR USE OF THE SITE OR WITH THE
              DELAY OR INABILITY TO USE THE SITE, OR FOR ANY INFORMATION,
              CONTENT, PRODUCTS, AND/OR SERVICES OBTAINED THROUGH THE SITE, OR
              OTHERWISE ARISING OUT OF YOUR USE OF THE SITE, WHETHER BASED ON
              CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF TYRILL.COM
              HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. YOUR ACCESS TO AND
              USE OF THE SITE IS AT YOUR RISK. IF YOU ARE DISSATISFIED WITH THE
              SITE OR ANY OF THE CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
              DISCONTINUE ACCESSING AND USING THE SITE AND ITS PROVIDED
              SERVICES.
            </Text>
          </MarginLarge>
        </Section>
      </ScrollableView>
  );
}
