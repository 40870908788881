import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import styled, { css } from "styled-components";
import { circularActionStyles } from "../styleguides/StyledLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TitleContext } from "../context/TitleContext";
import StyledButton from "../styleguides/StyledButton";
import { backgroundColorChanger, fadeInBottom, colorPulsate } from "../utils/animations";

const CircularIcon = styled.div`
  ${circularActionStyles};
  height: 45px;
  width: 45px;
  background-color: ${props => props.theme.colors.section.primary};
  color: ${props => props.theme.colors.text.secondary};
  ${props => props.animated && css`
      animation: ${colorPulsate} 1.1s cubic-bezier(.17,.67,.83,.67) infinite; 
  `}
`;

const PrimarySection = styled.div`
  width: 100%;
  animation: ${backgroundColorChanger} 50s linear infinite, ${fadeInBottom} 0.45s linear 1;
  ${props => `
@media screen and (max-width: ${props.theme.screenSizes.mediumx}) {
    height: 60px;
}
@media screen and (min-width: ${props.theme.screenSizes.mediumx}) {
    height: 75px;
}
`};
  border-bottom: 5px solid white;
`;

const SecondarySection = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.text.contrast};
  min-height: 100px;
  align-items: center;
  justify-content: space-around;
  display: flex;
  padding: 2rem;
`;

const Indicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0.5rem;
`;

const TitleMedium = styled.h3`
  margin: 0.5rem 0;
  color: ${props => props.theme.colors.text.contrast};
`;

export default function MyProfile() {
  const { logout, user } = useContext(UserContext);
  const { setTitle, setTabTitle, setPath, setTitleColor, setLastPath } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Back");
    setTabTitle(`Tyrill - ${user.displayName}`);
    setPath("/");
    setTitleColor("#ffffff");
    setLastPath("/user");
  }, []);

  return (
    <>
      <PrimarySection>
       
      </PrimarySection>

      <SecondarySection>
        <Indicator>
          <CircularIcon animated>
            <FontAwesomeIcon icon="heart" />
          </CircularIcon>
          <TitleMedium>0</TitleMedium>
        </Indicator>
        <Indicator>
          <CircularIcon>
            <FontAwesomeIcon icon="camera" />
          </CircularIcon>
          <TitleMedium>3</TitleMedium>
        </Indicator>
        <Indicator>
          <CircularIcon>
            <FontAwesomeIcon icon="user-friends" />
          </CircularIcon>
          <TitleMedium>0</TitleMedium>
        </Indicator>
      </SecondarySection>
      <StyledButton secondary onClick={() => logout()}>
        Logout
      </StyledButton>
    </>
  );
}
