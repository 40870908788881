import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './Text';
import { colorPulsate } from '../utils/animations';

const CircularIcon = styled.button`
  background-color: ${props => props.backgroundColor || props.theme.colors.section.primary}
  border: 0;
  border-radius: 50%;
  box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.45);
  display: block;
  height: 45px;
  width: 45px;
  padding: ${props => props.theme.spacing[props.padding]};  

  ${props => props.pulse && css`
    animation: ${colorPulsate} 1.3s cubic-bezier(.17,.67,.83,.67) infinite; 
  `}
`;

export default function CircularFab({ icon="heart", element="button", textColor, backgroundColor, pulse=false, size="normal", padding="small"}) {
    return (
        <CircularIcon as={element} padding={padding} backgroundColor={backgroundColor} pulse={pulse}>
            <Text size={size} element="span" textColor={textColor}><FontAwesomeIcon icon={icon} /></Text>
        </CircularIcon>
    )
}

