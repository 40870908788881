import React from "react";
import styled, { css, keyframes } from "styled-components";
import { animated } from "react-spring";
import noTouchHighlight from "../styleguides/noTouchHighlight";
import { fontColorChanger } from "../utils/animations";
import { Link } from "react-router-dom";

const CardWrapper = styled(({ ...props }) => <animated.div {...props} />)`
  position: absolute;
  width: 100vw;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  ${noTouchHighlight}
  top: -15px;

  @media screen and (max-width: 650px) {
    top: 40px;
  }

  @media screen and (max-width: 500px) and (min-height: 650px) {
    top: 10vh;
  }
`;

const CardHighlight = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 10;
  position: absolute;
  top: 0;
  background-color: rgba(0,0,0,0.8);
`;

const CardHighlightClose = styled.div`
  display: flex;
  position: absolute;
  top: 0.5rem;
  z-index: 11;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
`;

const CardHighlightAuthor = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Card = styled(({ ...props }) => <animated.div {...props} />)`
  display: flex;
  flex-direction: column;
  background: ${props => props.themecolor || "#ffffff"};
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 40px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
  width: calc(40vh * 1.25);
  height: calc(40vh * 2.2);
  min-width: 280px;
  min-height: 450px;
  max-width: 1000px;
  max-height: 1450px;
  ${noTouchHighlight}

  @media screen and (max-height: 550px) {
    min-width: 280px;
    min-height: 82vh;
    height: 82vh;
    width: 300px;
  }

  @media screen and (max-width: 500px) and (min-height: 551px) {
    min-width: 280px;
    min-height: 440px;
    height: 500px;
    width: 300px;
  }

  ${props =>
    props.defaultperspective &&
    css`
      transform: perspective(1500px) rotateX(0) rotateY(0) rotateZ(0) scale(1);
    `}
`;

const CardImage = styled.div`
  height: 100%;
  width: 100%;
  background: transparent;
  background-image: ${props => props.backgroundImage};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  border-radius: 10px;
`;

const CardVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 10px;
  z-index: -2;
  object-fit: fill;
`;

const CardVideoWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  border-radius: 10px;
  z-index: -1;
`;

const CardContentWrapper = styled.div`
  ${noTouchHighlight}
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;
  background-color: ${props => props.background || "#000000"}65;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: height, background-color 0.5s ease-in-out;
  cursor: pointer;

  ${props =>
    props.noContent &&
    css`
      background-color: transparent;
    `}

  ${props =>
    props.isItemSelected &&
    css`
      height: 100%;
      border-radius: 10px;
      background-color: ${props => props.background || "#000000"}cc;
      box-shadow: none;
    `}
`;

const CardContentPadder = styled.div`
  padding: ${props => props.theme.spacing.small};
  overflow: hidden;
`;

const CardReaction = styled(({ ...props }) => <animated.div {...props} />)`
  font-size: ${props => props.theme.fontSizes.large};
  z-index: 2;
  position: absolute;

  ${props =>
    props.left &&
    css`
      left: -50px;
    `}
  ${props =>
    props.right &&
    css`
      right: -50px;
    `}
`;

const CardDescriptionWrapper = styled.div`
  max-height: 15vh;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    max-height: 10vh;
  }

  ${props =>
    props.isItemSelected &&
    css`
      max-height: 50vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      @media screen and (min-width: 768px) and (min-height: 551px) {
        max-height: 70vh;
      }

      @media screen and (max-height: 550px) {
        max-height: 50vh;
      }
    `}
`;

const CardDescription = styled.span`
  color: ${props => props.color || props.theme.colors.text.contrast};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: ${props => props.theme.fontSizes.tinyx};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;

  @media screen and (min-width: 1321px) {
    font-size: ${props => props.theme.fontSizes.smallx};
  }

  @media screen and (min-width: 1921px) {
    font-size: ${props => props.theme.fontSizes.medium};
  }

  @media screen and (max-width: 375px) {
    font-size: ${props => props.theme.fontSizes.tinyx};
  }
`;

const CardAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding: 0 0.5rem;
  left: 0;
`;

const CardAuthorName = styled.span`
  color: ${props => props.color || props.theme.colors.text.contrast};
  font-family: ${props => props.theme.fonts.secondary};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  width: 80%;
  display: block;
  font-size: ${props => props.theme.fontSizes.tinyx};

  @media screen and (min-width: 1321px) {
    font-size: ${props => props.theme.fontSizes.smallx};
  }

  @media screen and (min-width: 1921px) {
    font-size: ${props => props.theme.fontSizes.medium};
  }
`;

const AuthorNameWrapper = styled.div`
  width: 73%;
`;

const CardAuthorThumb = styled.div`
  background: ${props => props.theme.colors.section.tertiary};
  ${props =>
    props.url &&
    css`
      background-image: ${props => props.url};
    `}

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || props.theme.colors.text.contrast};
  ${props =>
    props.leftPadded &&
    css`
      margin-left: ${props => props.theme.spacing.small};
    `}
  ${props =>
    props.rightPadded &&
    css`
      margin-right: ${props => props.theme.spacing.small};
    `}

@media screen and (min-width: 1321px) {
    width: 40px;
    height: 40px;
  }
`;

const CardCommentThumb = styled("div")`
  position: absolute;
  right: 5px;
  border-radius: 5px;
  border: 1px solid #ffffff75;
  padding: ${props => props.theme.spacing.tinyx}
    ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || props.theme.colors.text.contrast};
  z-index: 4;
`;

const CardAuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${props =>
    props.gutterBottom &&
    css`
      margin-bottom: 0.5rem;
    `}
`;

const SelectedAuthorInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardPaddedText = styled.p`
  background-color: #88888845;
  margin: 0;
  font-weight: bold;
  color: ${props => props.theme.colors.text.contrast};
  z-index: 3;
  padding: ${props => props.theme.spacing.small}
    ${props => props.theme.spacing.small};
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: ${props => props.theme.fontSizes.tinyx};

  @media screen and (min-width: 1321px) {
    font-size: ${props => props.theme.fontSizes.small};
  }

  @media screen and (min-width: 1921px) {
    font-size: ${props => props.theme.fontSizes.medium};
  }

  @media screen and (max-width: 375px) {
    font-size: ${props => props.theme.fontSizes.tiny};
  }
`;

const CardLikes = styled(CardPaddedText)``;

const CardTopLeftWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 25px;
  z-index: 3;
`;

const CardTopRightWrapper = styled.div`
  position: absolute;
  z-index: 3;
  right: 15px;
  top: 25px;
  display: flex;
`;

const CardMute = styled(CardPaddedText)``;

const CardTag = styled(({ ...props }) => <Link {...props} />)`
  margin: 0;
  font-weight: bold;
  color: ${props => props.color || props.theme.colors.text.contrast};
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  padding: ${props => props.theme.spacing.small} 0.7rem;
  background-color: ${props => props.background}75;
  font-family: ${props => props.theme.fonts.tertiary};

  font-size: ${props => props.theme.fontSizes.tinyx};

  @media screen and (min-width: 1321px) {
    font-size: ${props => props.theme.fontSizes.small};
  }

  @media screen and (min-width: 1921px) {
    font-size: ${props => props.theme.fontSizes.medium};
  }

  @media screen and (max-width: 375px) {
    font-size: ${props => props.theme.fontSizes.tiny};
  }

  ${noTouchHighlight}
`;

const CardImagePickerWrapper = styled.div`
  display: flex;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const CardImagePicker = styled.div`
  flex: 33%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const HighlightedImageCrumbs = styled.div`
  position: absolute;
  bottom: 1rem;
  z-index: 3;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CardPictureCrumbs = styled.div`
  z-index: 2;
  margin-top: ${props => props.theme.spacing.small};
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CardPictureCrumb = styled.div`
  height: 10px;
  width: 10px;
  margin: 0 ${props => props.theme.spacing.small};
  border-radius: 50%;
  background-color: #ededed;

  ${props =>
    props.isCurrent &&
    css`
      background-color: ${props => props.theme.colors.text.secondary};
    `}
`;

const StaticCardLoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 47%;
  z-index: -3;

  span {
    animation: ${fontColorChanger} 1.5s linear infinite;
    font-size: ${props => props.theme.fontSizes.large};
  }

  ${props =>
    props.lifted &&
    css`
      z-index: 3;
    `}
`;

const CardCreatedAt = styled.p`
  color: ${props => props.color || props.theme.colors.text.contrast};
  margin: 0;
  font-family: ${props => props.theme.fonts.secondary};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes.tinier};

  @media screen and (min-width: 1321px) {
    font-size: ${props => props.theme.fontSizes.tiny};
  }
  @media screen and (min-width: 1921px) {
    font-size: ${props => props.theme.fontSizes.small};
  }
`;

const NonSelectedAuthorWrapper = styled.div`
  display: flex;
`;

const cardHeartAnimation = keyframes`
0% { opacity: 0.3; }
100% { opacity: 1; }
`;

const CardOptionsContainer = styled.div`
  z-index: 4;
  position: absolute;
  top: 0px;
  left: -40px;
  animation: ${cardHeartAnimation} .15s linear 1;

  @media screen and (min-width: 1321px) {
    left: -55px;
  }
`;

const CircularIcon = styled.button`
  background-color: ${props => props.theme.colors.section.primary}
  border: 0;
  border-radius: 50%;
  box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.45);
  cursor: pointer;
  display: block;
  margin: ${props => props.theme.spacing.smallx} 0;
  padding: ${props => props.theme.spacing.smallx};

  @media screen and (min-width: 1321px) {
    font-size: ${props => props.theme.fontSizes.medium};
  }
`;

const TransparentCardSwipeButton = styled.button`
  width: 100px;
  height: 100%;
  z-index: 3;
  border: 0;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  
  ${noTouchHighlight};
`;

const CardSwipeLeft = styled(TransparentCardSwipeButton)`
  position: absolute;
  left: -105px;
`;

const CardSwipeRight = styled(TransparentCardSwipeButton)`
  position: absolute;
  right: -105px;
`;

export {
  CircularIcon,
  CardOptionsContainer,
  Card,
  CardAuthor,
  CardWrapper,
  CardAuthorName,
  CardAuthorThumb,
  CardCommentThumb,
  CardAuthorWrapper,
  CardContentPadder,
  CardContentWrapper,
  CardDescriptionWrapper,
  CardDescription,
  CardImage,
  CardTag,
  CardReaction,
  CardLikes,
  CardImagePickerWrapper,
  CardImagePicker,
  CardPictureCrumbs,
  CardPictureCrumb,
  CardVideo,
  CardVideoWrapper,
  StaticCardLoaderWrapper,
  NonSelectedAuthorWrapper,
  CardCreatedAt,
  SelectedAuthorInfoWrapper,
  CardHighlight,
  CardHighlightClose,
  CardHighlightAuthor,
  AuthorNameWrapper,
  CardMute,
  CardTopLeftWrapper,
  CardTopRightWrapper,
  HighlightedImageCrumbs,
  CardSwipeLeft,
  CardSwipeRight
};
