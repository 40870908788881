import styled from 'styled-components';

export const Menu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    
    li:not(:last-child) {
        border-bottom: 1px solid #dddddd;
    }
    li:first-child {
        border-top-left-radius: 5px;
    }
    li:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

export const MenuItem = styled.li`
    cursor: pointer;
    background: ${props => props.background || props.theme.colors.section.primary};
    padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.smallx};
    display: flex;
    align-items: center;
    justify-content: center;
`;