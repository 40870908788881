import styled, { css } from 'styled-components';


const ScrollableView = styled.div`
position: absolute;
top: 0;
-webkit-overflow-scrolling: touch;
height: calc(100% + 1px);
overflow: auto;
overflow-x: hidden;
width: 100%;

${props => props.background && css`
    background-color: ${props.background};
`}
${props => props.zIndex && css`
    z-index: ${props.zIndex};
`}
`;

export default ScrollableView;