import React, { useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import { CardAuthorThumb } from "../styleguides/CardStyles";
import CardText from "./CardText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentConfirmRemove from "./CommentConfirmRemove";
import Text from "../styleguides/Text";
import noTouchHighlight from "../styleguides/noTouchHighlight";
import { Menu, MenuItem } from "../styleguides/MenuStyles";
import CommentReport from "./CommentReport";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: background-color 0.15s ease-in-out;
  ${props =>
    props.isModifying &&
    css`
      background-color: #ffffff15;
    `}
  padding: ${props => props.theme.spacing.small} 0;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  width: 100%;

  ${props =>
    props.isLast &&
    css`
      margin-bottom: 25px;
    `}
`;

const MessageText = styled.div`
  font-family: "Roboto", sans-serif;
  overflow-wrap: break-word;
  word-break: break-word;
  margin: ${props => props.theme.spacing.small} 0;
`;

const MessageContentWrapper = styled.div`
  display: flex;
  padding: 0 ${props => props.theme.spacing.small};
  width: 100%;
`;

const Author = styled.div`
  display: flex;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AuthorName = styled.div`
  cursor: pointer;
`;

const AuthorNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

const ActionsButton = styled.button`
  cursor: pointer;
  color: ${props => props.color};
  background-color: #ffffff35;
  font-size: ${props => props.theme.fontSizes.tiny};
  padding: ${props => props.theme.spacing.tiny}
    ${props => props.theme.spacing.small};
  border-radius: 5px;
  border: 0;
  outline: 0;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #ffffff65;
  }
  ${noTouchHighlight}
`;

const ActionsMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 53px;
`;

const UserComment = ({
  comment,
  isLast,
  isOwnComment,
  fontColor,
  isCardOwner,
  background,
  onRemove
}) => {
  const [removing, setRemoving] = useState(false);
  const [reporting, setReporting] = useState(false);
  const [isActionsVisible, setIsActionsVisible] = useState(false);

  const now = moment();

  const hasUserAvatar =
    comment.createdBy &&
    comment.createdBy.photoURL &&
    comment.createdBy.photoURL.length > 0;

  const toggleActions = () => {
    setIsActionsVisible(!isActionsVisible);
  };

  const renderAuthor = () => (
    <Author>
      <CardAuthorThumb
        rightPadded
        url={hasUserAvatar && `url(${comment.createdBy.photoURL})`}
      >
        {!hasUserAvatar && <FontAwesomeIcon icon={["far", "user"]} />}
      </CardAuthorThumb>
    </Author>
  );

  const renderActions = () => {
    return (
      <ActionsMenuWrapper>
        <Menu>
          {!isOwnComment && (
            <MenuItem
              onClick={() => {
                setReporting(!reporting);
                setRemoving(false);
                setIsActionsVisible(false);
              }}
            >
              <Text element="span" withIconRight>
                {reporting ? "Cancel" : "Report"}{" "}
                {!reporting && <FontAwesomeIcon icon={"flag"} />}
              </Text>
            </MenuItem>
          )}
          {(isOwnComment || isCardOwner) && (
            <MenuItem
              onClick={() => {
                setRemoving(!removing);
                setReporting(false);
                setIsActionsVisible(false);
              }}
            >
              <Text element="span" withIconRight textColor="error">
                {removing ? "Cancel" : "Remove"}{" "}
                {!removing && <FontAwesomeIcon icon={["far", "trash-alt"]} />}
              </Text>
            </MenuItem>
          )}
        </Menu>
      </ActionsMenuWrapper>
    );
  };

  return (
    <MessageContainer isModifying={removing || reporting}>
      <MessageWrapper isLast={isLast}>
        <MessageContentWrapper>
          {renderAuthor()}
          <Message>
            <AuthorNameWrapper>
              <AuthorName>
                <Text bold size="smaller" color={fontColor} ellipsis>
                  {comment.createdBy.displayName}{" "}
                </Text>
                <Text size="smallest" color={fontColor}>
                  {moment
                    .duration(
                      now.diff(
                        comment.createdAt
                          ? comment.createdAt.toDate()
                          : new Date()
                      )
                    )
                    .humanize()}{" "}
                  ago
                </Text>
              </AuthorName>
              <ActionsButton color={fontColor} onClick={toggleActions}>
                {isActionsVisible ? (
                  "Close"
                ) : (
                  <FontAwesomeIcon icon="ellipsis-h" />
                )}
              </ActionsButton>

              {isActionsVisible && renderActions()}
            </AuthorNameWrapper>

            <MessageText>
              <CardText text={comment.text} color={fontColor} />
            </MessageText>
          </Message>
        </MessageContentWrapper>
        {removing && <CommentConfirmRemove id={comment.id} onRemove={onRemove} />}
        {reporting && (
          <CommentReport
            onReportSuccess={() => setReporting(false)}
            id={comment.id}
          />
        )}
      </MessageWrapper>
    </MessageContainer>
  );
};

export default UserComment;
