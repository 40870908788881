import { useEffect } from 'react'
import useSafeState from '../hooks/useSafeState';

export default function useMedia(queries, values, defaultValue) {
  const match = () => values[queries.findIndex(q => matchMedia(q).matches)] || defaultValue
  const [value, set] = useSafeState(match)
  useEffect(() => {
    const handler = () => set(match)
    const listener = window.addEventListener('resize', handler)
    return () => window.removeEventListener(handler, listener)
  }, [])
  return value
}