import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import {
  CreateCardContext,
  MAX_LIMIT_TAG_LENGTH
} from "../context/CreateCardContext";
import StyledInput from "../styleguides/StyledInput";
import demoTags from "../demo/demoTags";
import AutoComplete from "react-autocomplete";
import determineColorForString from "../utils/determineColorForString";
import StyledSpan from "../styleguides/StyledSpan";
import { MarginSmallTb } from "../styleguides/ContentPadders";

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSuggestionsMenu = styled.ul`
  list-style: none;
  position: absolute;
  z-index: 56;
  width: 100%;
  background: #fff;
  height: 250px;
  overflow: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #eee;
`;

const StyledSuggestion = styled.li`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  border-top: 1px solid #eee;
  border-left: 5px solid #fff;
  width: 100%;
  background-color: #fff;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  ${props =>
    props.isHighlighted &&
    css`
      border-left: 5px solid ${props => props.highlightColor};
    `}
`;

const TagSearchInputWrapper = styled.div`
  width: 100%;
`;

const StyledSuggestionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TagSpan = styled.span`
  color: ${props => props.color};
  margin-right: 5px;
`;

const TagValueSpan = styled.span`
  color: ${props => props.theme.colors.text.primary.light};
  font-size: ${props => props.theme.fontSizes.small};
`;
const StyledTagInput = styled(StyledInput)`
  font-weight: bold;
  border-bottom: 5px solid ${props => props.theme.colors.border.primary};

  &:focus {
    border-bottom: 5px solid ${props => props.borderColor};
  }
`;

export default function CreateCardTagInput() {
  const [{ hasBlurred, hasInputFocus }, setInputFocus] = useState({
    hasBlurred: false,
    hasInputFocus: false
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isForceClosed, setIsForceClosed] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { tag, setTag, themeColor } = useContext(CreateCardContext);
  const [isLongText, setIsLongText] = useState(false);

  const fetchTagSuggestions = query => {
    if (!query.length) return;

    setIsLoading(true);

    const filteredSuggestions = demoTags.filter(tag =>
      tag.tag.toUpperCase().includes(query.toUpperCase())
    );
    setSuggestions(filteredSuggestions);

    setIsLoading(false);
  };

  const handleKeyPressed = e => {
    if (e.key === "Escape" || e.key === "Enter") {
      setIsForceClosed(true);
    } else {
      setIsForceClosed(false);
    }
  };

  const handleOnChange = (e, v) => {
    const catchNonWordChars = /[\W_]+/g;
    let sanitizedValue = v.trim().replace(catchNonWordChars, "");

    if (sanitizedValue.length > MAX_LIMIT_TAG_LENGTH) {
      setIsLongText(true);
      sanitizedValue = sanitizedValue.slice(0, MAX_LIMIT_TAG_LENGTH);
    } else {
      setIsLongText(false);
    }

    if (tag !== sanitizedValue && sanitizedValue && sanitizedValue.length) {
      fetchTagSuggestions(sanitizedValue.toUpperCase());
    }

    setTag(sanitizedValue.toUpperCase());
  };

  const handleOnSelect = v => {
    if (hasBlurred) {
      return;
    }

    setTag(v.toUpperCase());
    setIsForceClosed(true);
  };

  const hasCurrentWordInSuggestion =
    suggestions &&
    suggestions
      .map(suggestion => suggestion.tag)
      .some(aTag => aTag.toLowerCase().includes(tag.toLowerCase()));

  const shouldDisplayAutoComplete =
    !isForceClosed &&
    hasInputFocus &&
    tag.length > 0 &&
    hasCurrentWordInSuggestion &&
    suggestions.length > 0;

  return (
    <React.Fragment>
      <ContentWrapper>
        <TagSearchInputWrapper
          onMouseEnter={() =>
            setInputFocus({ hasBlurred: false, hasInputFocus })
          }
        >
          <AutoComplete
            open={shouldDisplayAutoComplete}
            value={"#" + tag}
            items={!tag ? [] : suggestions}
            getItemValue={item => item.tag}
            shouldItemRender={item => {
              return item.tag.toLowerCase().includes(tag.toLowerCase());
            }}
            inputProps={{
              onFocus: () => {
                setInputFocus({ hasInputFocus: true, hasBlurred: false });
              },
              onBlur: () => {
                setInputFocus({ hasInputFocus: false, hasBlurred: true });
              }
            }}
            renderInput={props => {
              const { ref, ...rest } = props;

              return (
                <StyledTagInput
                  ref={ref}
                  {...rest}
                  placeholder={isLoading ? "Loading.." : "Add a tag.."}
                  onKeyUp={e => handleKeyPressed(e)}
                  borderColor={themeColor.background}
                  color={themeColor.background}
                />
              );
            }}
            wrapperStyle={{ width: "100%", position: "relative" }}
            onChange={(e, v) => handleOnChange(e, v)}
            onSelect={v => handleOnSelect(v)}
            selectOnBlur={true}
            renderMenu={children => (
              <StyledSuggestionsMenu>{children}</StyledSuggestionsMenu>
            )}
            renderItem={(item, isHighlighted) => (
              <StyledSuggestion
                highlightColor={themeColor.background}
                key={item.tag}
                isHighlighted={isHighlighted}
              >
                <StyledSuggestionTextWrapper>
                  <span>
                    <TagSpan color={determineColorForString(item.tag)}>
                      #
                    </TagSpan>
                    <TagValueSpan offWhite>{item.tag}</TagValueSpan>
                  </span>
                </StyledSuggestionTextWrapper>
              </StyledSuggestion>
            )}
          />
        </TagSearchInputWrapper>
      </ContentWrapper>
      {isLongText && (
        <MarginSmallTb>
          <StyledSpan bold error tiny>
            Dear creative Tyriller, there is an upper limit of{" "}
            {MAX_LIMIT_TAG_LENGTH} characters on a card tag. 😕 
          </StyledSpan>
        </MarginSmallTb>
      )}
    </React.Fragment>
  );
}
