import React, { memo } from "react";
import urlRegex from "url-regex";
import { StyledMentionsLink, StyledHref } from "../styleguides/StyledLink";
import { CardDescription } from "../styleguides/CardStyles";

const patternMatcher = new RegExp("[^{}]+(?=})", "i");
const isUrl = urlRegex({ exact: true });

// Let's memomize this "costly" function.
// This function renders @ or # tags as highlighted text, and HTML-links as links.

const CardText = memo(({ color, text }) => {
  const lines = text.split("\n");
  const textComment = [];
  lines.forEach((element , idx)=> {
    const words = element.split(" ");
    words.forEach((word, i) => {
      const isMention = patternMatcher.exec(word);
      if (isMention) {
        if (isMention[0].includes("@")) {
          textComment.push(
            <StyledMentionsLink
              key={i + "@" + idx}
              color={color}
              to={`/profile/${isMention[0]}`}
            >
              {`${isMention[0]} `}
            </StyledMentionsLink>
          );
        } else if (isMention[0].includes("#")) {
          textComment.push(
            <StyledMentionsLink
              key={i + "#" + idx}
              color={color}
              to={`/discover/${isMention[0].replace("#", "")}`}
            >
              {`${isMention[0]} `}
            </StyledMentionsLink>
          );
        }
      } else if (isUrl.test(word.trim())) {
        textComment.push(
          <StyledHref
            target="_blank"
            href={word.trim()}
            key={i + "href" + idx}
            color={color}
          >
            {`${word} `}
          </StyledHref>
        );
      } else {
        textComment.push(`${word} `);
      }
    });
    textComment.push("\n");
  });
  textComment.splice(-1);
  return <CardDescription color={color}>{textComment}</CardDescription>;
});

export default CardText;
