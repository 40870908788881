import { useState, useContext } from "react";
import app from "../utils/firebase";
import { UserContext } from "../context/UserContext";

function useCloudStorage(opts) {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const {
    user
  } = useContext(UserContext);

  function removeFile(path) {
    setBusy(true);

    let fileToRemoveRef = app.storage().child(path);

    // Delete the file
    fileToRemoveRef
      .delete()
      .then(() => {
        if (opts.onRemoveSuccess === "function") {
          opts.onRemoveSuccess();
        }
      })
      .catch(function(error) {
        setError(error);

        if(opts.onError instanceof Function) {
          opts.onError(error);
        }
      })
      .finally(() => {
        setBusy(false);
      });
  }

  async function downloadFile(path) {
    return app
      .storage()
      .ref(path)
      .getDownloadURL()
      .then(url => url)
      .catch(e => {
        if(opts.onError instanceof Function) {
          opts.onError(e);
          setError(e);
        }
      });
  }

  function getAllFilesByCardPath(path) {
    setBusy(true);
    let storageRef = app.storage().ref();
    // Create a reference under which you want to list
    let listRef = storageRef.child(path);
    // Find all the prefixes and items.
    let items = [];

    listRef
      .listAll()
      .then(function(res) {
        // Given that this edge had more directories...
        // -> res.prefixes.forEach(function(folderRef) {
        // -> All the prefixes under listRef.
        // -> You may call listAll() recursively on them.
        // -> });
        res.items.forEach(function(itemRef) {
          itemRef.getDownloadURL().then(downloadURL => {
            items.push(downloadURL);
          });
        });
      })
      .catch(function(error) {
        setError(error);
      })
      .finally(() => {
        setBusy(false);
      });

    return items;
  }

  function uploadFile(overlay, cardId) {
    
    if(!user) {
        return;
    }

    setBusy(true);

    let file = overlay.sources[0];
    
    const path = `/${user.uid}/${cardId}/${file.name}`;

    let storageRef = app.storage().ref();

    const imageRef = storageRef.child(path);

    let uploadTask = imageRef.put(overlay.file);

    return new Promise((success, error) => {
        uploadTask.on(
            "state_changed",
            function(snapshot) {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      
              if (opts.onProgress === "function") {
                opts.onProgress(progress);
              }
            },
            function(error) {
              error(error);
            },
            function() {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                setBusy(false);
                success({
                    url,
                    path,
                    type: file.type
                  })
              });
            }
          );
        
    })
    }

  return [
    busy,
    error,
    uploadFile,
    downloadFile,
    removeFile,
    getAllFilesByCardPath
  ];
}

export default useCloudStorage;
