import { useEffect, useState } from "react";
import app from "../utils/firebase";

const emptyDefault = null;


async function getCommentsCount(documentSnapshot) {
  return await app.firestore()
  .collection("cards").doc(documentSnapshot.id).collection('shard-comments').get().then(snapshot => {
    let total_count = 0;
    snapshot.forEach(doc => {
        total_count += doc.data().count;
    });

    return total_count;
});
}

//eslint-disable-next-line
async function getHeartsCount(documentSnapshot) {
  return await app.firestore()
  .collection("cards").doc(documentSnapshot.id).collection('shard-hearts').get().then(snapshot => {
    let total_count = 0;
    snapshot.forEach(doc => {
        total_count += doc.data().likes;
    });

    return total_count;
}).catch(e => {
  return 0;
});
}

function useCardFirestore(opts) {
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(opts.initialData || emptyDefault);
  const [error, setError] = useState(null);
  const [lastDocument, setLastDocument] = useState(null);

  function getQuery(tag) {

    if(tag) {
      return app
      .firestore()
      .collection(opts.collection)
      .orderBy("createdAt", "desc")
      .limit(opts.limit || 5)
      .where("tag", "==", tag);
    }

    return app
    .firestore()
    .collection(opts.collection)
    .orderBy("createdAt", "desc")
    .limit(opts.limit || 5);
  }

  function fetchCardsStartingAfter(tag) {
    setBusy(true);
    setData([]);

    try {
        getQuery(tag)
        .startAfter(lastDocument)
        .get()
        .then(async dataz => {
          const data = await Promise.all(dataz.docs.reverse().map(async function(documentSnapshot) {
            const aDocument = documentSnapshot.data();
            aDocument.commentCount = await getCommentsCount(documentSnapshot);
            aDocument.id = documentSnapshot.id;
            return aDocument;
          }));
          setLastDocument(dataz.docs[dataz.docs.length - 1]);
          setData(data);
          setBusy(false);
        })
        .catch(e => {
          throw e;
        });
    } catch (e) {
      setError(e);
      setBusy(false);

      if(opts.onError instanceof Function) {
        opts.onError(e);
      }
    }
  }

  function fetchData(tag) {
    setBusy(true);

    try {
        getQuery(tag)
        .get()
        .then(async dataz => {
          const data = await Promise.all(dataz.docs.reverse().map(async function(documentSnapshot) {
            const aDocument = documentSnapshot.data();
            aDocument.commentCount = await getCommentsCount(documentSnapshot);
            aDocument.id = documentSnapshot.id;
            return aDocument;
          }));

          setLastDocument(dataz.docs[dataz.docs.length - 1]);
          setData(data);
          setBusy(false);
        });
    } catch (e) {
      setError(e);
      setBusy(false);
      if(opts.onError instanceof Function) {
        opts.onError(e);
      }
    }
  }

  if (opts.fetchOnMount) {
    useEffect(() => {
      fetchData();
    }, []);
  }

  return [busy, data, error, fetchData, fetchCardsStartingAfter, lastDocument];
}

export default useCardFirestore;
