import styled from "styled-components";
import { animated } from "react-spring";

const GiphyTile = styled(animated.div)`
  height: 260px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  border-radius: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity, border;
  width: 210px;

  @media all and (max-width: 700px) {
    height: 210px;
    width: 175px;
  }
  
  @media all and (max-width: 400px) {
    height: 195px;
    width: 150px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  font-weight: bold;
  color: ${props => props.theme.colors.text.contrast};
`;

export default GiphyTile;
