import React, { useContext, useRef } from "react";
import GiphyTile from "./GiphyTile";
import { useTransition, useSpring, useChain, config } from "react-spring";
import useMedia from "../hooks/useMedia";
import { CreateCardContext } from "../context/CreateCardContext";
import StyledSpan from "../styleguides/StyledSpan";
import solidPalette from "../assets/solidPalette";
import {
  Modal,
  TopSection,
  CloseWrapper,
  Container,
  ContentWrapper
} from "../styleguides/ModalStyles";

export default function GiphySelector() {
  const { selectedItems, addItem, removeItem, setView } = useContext(
    CreateCardContext
  );

  const columns = useMedia(
    [
      "(min-width: 1900px)",
      "(min-width: 1205px)",
      "(min-width: 900px)",
      "(min-width: 600px)"
    ],
    [7, 5, 4, 3],
    2
  );

  const columnSize = useMedia(
    ["(min-width: 1000px)", "(max-width: 600px)", "(max-width: 400px)"],
    [230, 180, 160],
    160
  );

  const springRef = useRef();

  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: { size: "20%" },
    to: { size: "100%" }
  });

  const transRef = useRef();

  const transitions = useTransition(solidPalette, item => item.id, {
    ref: transRef,
    unique: true,
    trail: 400 / solidPalette.length,
    from: { opacity: 0, transform: "scale(0)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0)" }
  });

  const fadeInBackground = useTransition("s", null, {
    from: { backgroundColor: "rgba(0,0,0,0.0)" },
    enter: { backgroundColor: "rgba(0,0,0,0.75)" },
    leave: { backgroundColor: "rgba(0,0,0,0.0)" }
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain([springRef, transRef], [0, 0.6]);

  return (
    <React.Fragment>
      {fadeInBackground.map(({ key, props }) => (
        <Modal
          style={{
            ...props
          }}
          key={key}
        >
          <TopSection>
            <CloseWrapper onClick={() => setView("NONE")}>
              <StyledSpan>Close</StyledSpan>
            </CloseWrapper>
          </TopSection>
          <ContentWrapper>
            <Container
              paddingtb="1rem"
              style={{
                ...rest,
                width: size,
                height: size,
                overflowY: "scroll"
              }}
              columns={columns}
              columnsize={columnSize}
            >
              {transitions.map(({ item, k, propz }) => (
                <GiphyTile
                  style={{
                    ...propz,
                    backgroundImage: `linear-gradient(${item.url})`,
                    border: selectedItems.some(
                      selItem => selItem.id === item.id
                    )
                      ? "5px solid salmon"
                      : "none"
                  }}
                  key={item.id}
                  onClick={() => {
                    if (selectedItems.some(selItem => selItem.id === item.id)) {
                      removeItem(item);
                    } else {
                      addItem({
                        ...item,
                        source: "SOLID",
                        type: "SOLID",
                        sources: [
                          {
                            type: "SOLID",
                            url: item.url
                          }
                        ]
                      });
                    }
                  }}
                >
                  <StyledSpan alternative>{item.name}</StyledSpan>
                </GiphyTile>
              ))}
            </Container>
          </ContentWrapper>
        </Modal>
      ))}
      }
    </React.Fragment>
  );
}
