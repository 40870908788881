import React, { useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledIconButton } from "../styleguides/StyledButton";
import { CreateCardToolboxContext } from "../context/CreateCardToolboxContext";
import { CardBrowserContext } from "../context/CardBrowserContext";
import { CreateCardContext } from "../context/CreateCardContext";

const Toolbox = styled.div`
  background-color: ${props => props.theme.colors.section.primary};
  transition: all 0.25s cubic-bezier(0.4, 0.35, 0.63, 0.47);
  width: 100vw;
`;

const ToolboxTitle = styled.h3`
  color: ${props => props.color || props.theme.colors.text.contrast};
  margin: 0;
  font-size: ${props => props.theme.titleSizes.small};
  ${props => `
    @media screen and (max-width: ${props.theme.screenSizes.small}) {
      font-size: ${props.theme.fontSizes.tinyx}
    }
    @media screen and (min-width: ${props.theme.screenSizes.mediumx}) {
      font-size: ${props.theme.fontSizes.mediumx}
    }
    @media screen and (min-width: ${props.theme.screenSizes.largex}) {
      font-size: ${props.theme.fontSizes.mediumx}
    }
  `};
`;

const TopContent = styled.div`
  background-color: ${props =>
    props.backgroundColor || props.theme.colors.section.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: ${props => (props.zIndex ? props.zIndex : 50)};
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.small};
  transition: background-color 0.25s ease-in-out;
  ${props => `
  @media screen and (min-width: ${props.theme.screenSizes.smallx}) {
    padding: ${props.theme.spacing.medium} ${props.theme.spacing.small};
  }
`};
`;

const IconPadder = styled.span`
  margin-right: ${props => props.theme.spacing.tinyx};
`;

const ContentWrapper = styled.div`
  width: 100vw;
  @media all and (min-width: 700px) {
    transition: all 0.25s cubic-bezier(0.4, 0.35, 0.63, 0.47);
  }
`;

const ToolboxToggleText = styled.span`
  color: ${props => props.color || props.theme.colors.text.contrast};
  margin: 0;
  font-size: ${props => props.theme.titleSizes.small};
  ${props => `
@media screen and (max-width: ${props.theme.screenSizes.small}) {
  font-size: ${props.theme.fontSizes.tinyx}
}
@media screen and (min-width: ${props.theme.screenSizes.mediumx}) {
  font-size: ${props.theme.fontSizes.mediumx}
}
@media screen and (min-width: ${props.theme.screenSizes.largex}) {
  font-size: ${props.theme.fontSizes.mediumx}
}
`};
`;

export default function CreateCardToolbox({
  children,
  title,
  toolboxIcon,
  backgroundColor,
  color,
  zIndex
}) {
  const { openToolboxes, toggleToolboxOpen } = useContext(
    CreateCardToolboxContext
  );

  const { content, selectedItems } = useContext(CreateCardContext);

  const { setIsAddingCard } = useContext(CardBrowserContext);

  const isOpen = openToolboxes.includes(title);
  const isAnyToolboxOpen = openToolboxes.length > 0;
  return (
    <Toolbox isOpen={isOpen}>
      <TopContent
        color={color}
        backgroundColor={backgroundColor}
        onClick={() => title !== "New Tyrill Card" && toggleToolboxOpen(title)}
        zIndex={zIndex}
      >
        <ToolboxTitle color={color}>
          {isAnyToolboxOpen ? (
            <span>
              <IconPadder>
                <FontAwesomeIcon icon={toolboxIcon} />
              </IconPadder>{" "}
              {title}
            </span>
          ) : (
            <FontAwesomeIcon icon={toolboxIcon} />
          )}
        </ToolboxTitle>
        <StyledIconButton
          background={backgroundColor}
          color={"#fff"}
          onClick={() => {
            if(title === "New Tyrill Card") {
              if((selectedItems.length > 0 || content.length > 0) && window.confirm("Do you really want to discard this new Tyrill card?")) {
                setIsAddingCard(false);
              } else {
                setIsAddingCard(false);
              }
            } else {
              toggleToolboxOpen(title) 
            }
          }}
        >
          <ToolboxToggleText color={color}>
            {isOpen ? title === "New Tyrill Card" ? "Cancel" : "Hide" : <FontAwesomeIcon icon={"chevron-right"} />}
          </ToolboxToggleText>
        </StyledIconButton>
      </TopContent>
      <ContentWrapper isOpen={isOpen}>{isOpen && children()}</ContentWrapper>
    </Toolbox>
  );
}
