import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import browserHistory from "./utils/history";
import { TitleContextProvider } from "./context/TitleContext";
import { ToastContainer } from "react-toastify";
import defaultTheme from "./theme/default";
import { ThemeProvider } from "styled-components";
import { CardBrowserProvider } from "./context/CardBrowserContext";
import { UserProvider } from "./context/UserContext";
import { UserProfileProvider } from "./context/ProfileContext";
import { NotificationProvider } from "./context/NotificationContext";
import { LocationProvider } from "./context/LocationContext";

ReactDOM.render(
  <Router history={browserHistory}>
    <UserProvider>
      <LocationProvider>
        <UserProfileProvider>
          <NotificationProvider>
            <CardBrowserProvider>
              <TitleContextProvider>
                <ThemeProvider theme={defaultTheme}>
                  <App />
                </ThemeProvider>
                <ToastContainer />
              </TitleContextProvider>
            </CardBrowserProvider>
          </NotificationProvider>
        </UserProfileProvider>
      </LocationProvider>
    </UserProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
