import React, { useContext, useState } from "react";
import {
  CreateCardContext,
  MAX_SELECTABLE_ITEMS
} from "../context/CreateCardContext";
import styled, { css } from "styled-components";
import CreateCardToolbox from "./CreateCardToolbox";
import CreateCardPictureOptions from "./CreateCardPictureOptions";
import CreateCardPicturesView from "./CreateCardPicturesView";
import useMedia from "../hooks/useMedia";
import StyledParagraph from "../styleguides/StyledParagraph";
import CardTextInput from "./CardTextInput";
import noTouchHighlight from "../styleguides/noTouchHighlight";
import CreateCardTagInput from "./CreateCardTagInput";
import CreateCardThemeColor from "./CreateCardThemeColor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledSpan from "../styleguides/StyledSpan";
import CreateCardPublishOptions from "./CreateCardPublishOptions";
import { UserContext } from "../context/UserContext";
import Text from "../styleguides/Text";
import { MarginMediumTb, MarginSmallTb } from "../styleguides/ContentPadders";
import Flex from "../styleguides/Flex";

const TextInputWrapper = styled.div`
  padding: ${props => `${props.theme.spacing.small}`};
`;

const Wrapper = styled.div`
  width: 100%;
`;

const CenteredContent = styled.div`
  padding: ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayActionsWrapper = styled.div`
  max-width: 650px;
`;

const OverlayDescription = styled.span`
  color: ${props => props.theme.colors.text.primary.light};
  font-size: ${props => props.theme.fontSizes.tiny};

  @media all and (min-width: ${props => props.theme.screenSizes.medium}) {
    font-size: ${props => props.theme.fontSizes.medium};
  }
`;

const CurrentUserImage = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: ${props => props.theme.colors.section.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${props => `
  @media all and (max-width: ${props.theme.screenSizes.tinyx}) {
    display: none;
  }
  @media screen and (min-width: ${props.theme.screenSizes.mediumx}) {
    height: 50px;
    width: 50px;
  }
  `}

  ${noTouchHighlight}
`;

const CardCustomization = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${props => props.theme.spacing.small};
`;

const AddContentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.background || "#777"};
  outline: 0;
  border: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: ${props => props.color || props.theme.colors.text.contrast};
  font-size: ${props => props.theme.fontSizes.small};
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  ${noTouchHighlight}
  transition: background-color 0.25s ease-in-out;
  ${props =>
    props.isSelected &&
    css`
      background-color: ${props => props.theme.colors.button.secondary};
    `}

  span {
    margin-top: 2px;
  }
`;

const PickerPopup = styled.div`
  position: absolute;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.section.primary};
  top: 40px;
  right: 5px;
  z-index: 57;
  border: 2px solid ${props => props.theme.colors.border.primary};
`;

const TagInputWrapper = styled.div`
  margin: 0 ${props => props.theme.spacing.medium};
  flex-basis: 50%;
`;

const ContentButtons = styled.div`
  display: flex;
  margin-left: auto;
  position: relative;
  button:first-child {
    margin: 0 ${props => props.theme.spacing.small};
  }
`;

const CreateContentWrapper = styled.div`
  padding: ${props => props.theme.spacing.small};
  background: ${props => props.background};
  color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PublishedWithoutContentDisclaimer = styled.div`
  padding: ${props => props.theme.spacing.small};
  margin: ${props => props.theme.spacing.small} 0;
  background-color: ${props => props.theme.colors.section.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ToolboxMargin = styled.div`
  @media screen and (max-width: ${props => props.theme.screenSizes.large}) {
    margin-bottom: 100px;
  }
`;

export default function CreateCardPictureToolbox(props) {
  const {
    selectedItems,
    themeColor,
    isDisplayingUploadContent,
    setIsDisplayingUploadContent,
    hasPublishedWithoutContent,
    setHasPublishedWithoutContent
  } = useContext(CreateCardContext);
  const { user } = useContext(UserContext);

  const [isDisplayingThemePicker, setIsDisplayingThemePicker] = useState(false);
  const isComplete = selectedItems.length === MAX_SELECTABLE_ITEMS;

  const shouldDisplaySingleRows = useMedia(
    ["(min-width: 1000px)", "(max-width: 700px)"],
    [false, false],
    false
  );

  return (
    <ToolboxMargin>
      <CreateCardToolbox {...props}>
        {() => (
          <Wrapper>
            <div>
              <CardCustomization>
                <CurrentUserImage image={user.photoURL} />
                <TagInputWrapper>
                  <CreateCardTagInput />
                </TagInputWrapper>
                <ContentButtons>
                  <AddContentButton
                    title="Upload a picture, choose a GIF or a solid palette"
                    isSelected={isDisplayingUploadContent}
                    onClick={() => {
                      setHasPublishedWithoutContent(false);
                      setIsDisplayingUploadContent(!isDisplayingUploadContent);
                    }}
                  >
                      <FontAwesomeIcon icon="camera-retro" />
                  </AddContentButton>
                  <AddContentButton
                    title="Update the theme color for this card"
                    color={themeColor.fontColor}
                    background={themeColor.background}
                    onClick={() =>
                      setIsDisplayingThemePicker(!isDisplayingThemePicker)
                    }
                  >
                      <FontAwesomeIcon icon="paint-brush" />
                  </AddContentButton>
                  {isDisplayingThemePicker && (
                    <PickerPopup>
                      <CreateCardThemeColor
                        isClosable={true}
                        onClose={() => setIsDisplayingThemePicker(false)}
                      />
                    </PickerPopup>
                  )}
                </ContentButtons>
              </CardCustomization>

              {isDisplayingUploadContent && (
                <CreateContentWrapper
                  color={themeColor.fontColor}
                  background={themeColor.background}
                >
                  {!isComplete && (
                    <Flex center>
                      <OverlayActionsWrapper>
                        <MarginMediumTb>
                          <Text variant="subtitle" element="h3" size="smaller">Pick a Card Surface</Text>
                          <Text element="p" size="smallest" color={themeColor.fontColor}>
                            Without a card surface, there's really no card.
                            Upload something people will like!
                          </Text>
                        </MarginMediumTb>

                        <CreateCardPictureOptions
                          onUploadedItem={() => {
                            setHasPublishedWithoutContent(false);
                            setIsDisplayingUploadContent(false);
                          }}
                          singleRows={shouldDisplaySingleRows}
                        />

                        <MarginSmallTb>
                          <Text element="p" size="smallest" color={themeColor.fontColor}>
                            By uploading you agree to the terms defined in the{" "}
                            <Text asLink to="/terms" target="_blank" clickable="true" underlined="true" color={themeColor.fontColor}>
                              Terms of Use
                            </Text>
                            .
                          </Text>
                        
                        </MarginSmallTb>
                        {hasPublishedWithoutContent && (
                          <PublishedWithoutContentDisclaimer>
                            <StyledSpan tiny error>
                              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                              Pick a card surface to publish your card.
                            </StyledSpan>
                          </PublishedWithoutContentDisclaimer>
                        )}
                      </OverlayActionsWrapper>
                    </Flex>
                  )}
                  {isComplete && (
                    <CenteredContent>
                      <StyledParagraph offWhite>
                        You've added all available card surfaces! Remove an
                        overlay to add another.
                      </StyledParagraph>
                    </CenteredContent>
                  )}
                </CreateContentWrapper>
              )}
              <TextInputWrapper>
                <CardTextInput />
              </TextInputWrapper>
            </div>
            <CenteredContent>
              <OverlayDescription>
                {selectedItems.length === 1 &&
                  `Great choice! You may add up to ${MAX_SELECTABLE_ITEMS} card surfaces.`}
                {selectedItems.length > 1 &&
                  "Drag the cards to reorder the surfaces."}
              </OverlayDescription>
            </CenteredContent>
            <CreateCardPicturesView key={selectedItems.length} />
            <CreateCardPublishOptions
              onPublishSuccess={() => props.onPublishSuccess()}
            />
          </Wrapper>
        )}
      </CreateCardToolbox>
    </ToolboxMargin>
  );
}
