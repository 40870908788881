import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";
import { GeoFirestore } from 'geofirestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = firebase.initializeApp(firebaseConfig);

const geofirestore = new GeoFirestore(firebase.firestore());

const perf = firebase.performance();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const FieldValue = firebase.firestore.FieldValue;
facebookProvider.addScope('email');

const providers = {
    facebook: facebookProvider
}

export default app;

export {
    perf,
    providers,
    FieldValue,
    geofirestore
}