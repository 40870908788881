import React, { createContext, useState } from 'react';
import moment from 'moment';

const MAX_SELECTABLE_ITEMS = 5;
const MAX_LIMIT_CONTENT_LENGTH = 4000;
const MAX_LIMIT_TAG_LENGTH = 20;


const CreateCardContext = createContext({
  selectedItems: [],
  currentView: "NONE",
  themeColor: { background: "#35384a", fontColor: "#ffffff" },
  isDisplayingUploadContent: true,
  hasPublishedWithoutContent: false,
  removeItem: () => {},
  replaceItem: () => {},
  addItem: () => {},
  setView: () => {},
  resetView: () => {},
  setTag: () => {},
  setText: () => {},
  setLocation: () => {},
  setThemeColor: () => {},
  setIsSelectionComplete: () => {},
  setSelectedItems: () => {},
  setIsDisplayingUploadContent: () => {},
  setHasPublishedWithoutContent: () => {}
});

function CreateCardProvider({ children }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tag, setTag] = useState(moment().format('dddd').toUpperCase());
  const [location, setLocation] = useState("Oslo, Norway");
  const [content, setContent] = useState("");
  const [themeColor, setThemeColor] = useState({background: "#35384a", fontColor: "#ffffff" });
  const [currentView, setCurrentView] = useState("NONE");
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  const [isDisplayingUploadContent, setIsDisplayingUploadContent] = useState(true);
  const [hasPublishedWithoutContent, setHasPublishedWithoutContent] = useState(false);

  function addItem(item) {
    if(selectedItems.length < MAX_SELECTABLE_ITEMS) {
      
      if(selectedItems.length + 1 === MAX_SELECTABLE_ITEMS) {
        
        setIsSelectionComplete(true);
      }
      setSelectedItems(
        [...selectedItems, item]
      );
    } else {
      replaceItem(item);
    }
    
    setCurrentView("NONE");
  }

  function resetView() {
    setCurrentView("NONE");
  }

  function removeItem(itemToRemove) {
    const filteredSelectedItems = selectedItems.filter(item => item.id !== itemToRemove.id);
    
    if(!filteredSelectedItems.length) {
      setIsSelectionComplete(false);
    }

    setSelectedItems(
      filteredSelectedItems
      );
  }

  function setView(view) {
    setCurrentView(view);
  }

  function replaceItem(item) {
    setSelectedItems(
      [...selectedItems.splice(0, item)]
    )
  }


  return (
    <CreateCardContext.Provider value={{
      selectedItems,
      setSelectedItems,
      currentView,
      addItem,
      removeItem,
      resetView,
      setView,
      tag,
      setTag,
      content,
      setContent,
      location,
      setLocation,
      themeColor,
      setThemeColor,
      replaceItem,
      isSelectionComplete,
      setIsSelectionComplete,
      isDisplayingUploadContent,
      setIsDisplayingUploadContent,
      hasPublishedWithoutContent, 
      setHasPublishedWithoutContent
    }}>
      {children}
    </CreateCardContext.Provider>
  )
}

export { 
    CreateCardProvider,
    CreateCardContext,
    MAX_SELECTABLE_ITEMS,
    MAX_LIMIT_CONTENT_LENGTH,
    MAX_LIMIT_TAG_LENGTH
}