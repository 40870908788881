import React, { useContext, useEffect } from "react";
import { CardBrowserContext } from "../context/CardBrowserContext";
import CardDeck from "./CardDeck";
import MiddleScreenLoadingIndicator from "./MiddleScreenLoadingIndicator";
import { getRandomPositiveEmoji } from "../utils/positiveReactionEmojis";
import { TitleContext, DEFAULT_APP_COLOR } from "../context/TitleContext";
import CreateCard from "./CreateCard";

import { CreateCardProvider } from "../context/CreateCardContext";
import { CreateCardToolboxProvider } from "../context/CreateCardToolboxContext";
import { tyrillErrorToast } from "../utils/tyrillToaster";
import { LocationContext } from "../context/LocationContext";
import LocationRequiredPopup from "./LocationRequiredPopup";
import { MarginLargeLr } from "../styleguides/ContentPadders";
import Text from "../styleguides/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../styleguides/Flex";

const likeEmoji = getRandomPositiveEmoji();

export default function CardsDiscover(props) {
  const {
    posts,
    postQueryKey,
    isLoadingCards,
    hasLoadedCards,
    refetchCards,
    isAddingCard,
    storageError,
    cardError
  } = useContext(CardBrowserContext);

  const { hasLoadedLocation, isGranted, cityLocation } = React.useContext(
    LocationContext
  );
  const {
    setTitle,
    setTitleColor,
    setTabTitle,
    setPath,
    tabTitle,
    setLastPath
  } = useContext(TitleContext);

  useEffect(() => {
    if (isGranted) {
      refetchCards();
    }
  }, [isGranted]);

  useEffect(() => {
    if (cityLocation) {
      setTitle(() => (
        <Flex>
          <Text gutterRight>
            <FontAwesomeIcon icon="map-marker-alt"></FontAwesomeIcon>
          </Text>
          {cityLocation}
        </Flex>
      ));
      setTitleColor(DEFAULT_APP_COLOR);
      setPath("/");
      setLastPath("/discover");
      setTabTitle(`Tyrill - Discover ${cityLocation}`);
    } else {
      setTitle("Discover");
      setTitleColor(DEFAULT_APP_COLOR);
      setPath("/");
      setLastPath("/discover");
      setTabTitle("Tyrill - Discover");
    }
  }, [cityLocation]);

  useEffect(() => {
    if (storageError) {
      tyrillErrorToast(storageError.message, 5000, "card-error");
    }
    if (cardError) {
      tyrillErrorToast(cardError.message, 5000, "card-error");
    }
  }, [storageError, cardError]);

  return (
    <React.Fragment>
      {(isLoadingCards || !hasLoadedCards) && (
        <MiddleScreenLoadingIndicator label={`Hang in there.. ${likeEmoji}`} />
      )}
      {!isLoadingCards && hasLoadedCards && (!posts || !(posts.length > 0)) && (
        <div>
          <MiddleScreenLoadingIndicator
            label={`Come back later for more cards. ${likeEmoji}`}
          />
        </div>
      )}
      {isAddingCard && (
        <CreateCardProvider>
          <CreateCardToolboxProvider>
            <CreateCard {...props} previousDocumentTitle={tabTitle} />
          </CreateCardToolboxProvider>
        </CreateCardProvider>
      )}
      {hasLoadedLocation && isGranted && (
        <CardDeck posts={posts} key={postQueryKey} />
      )}
      {hasLoadedLocation && !isGranted && (
        <MarginLargeLr>
          <LocationRequiredPopup fullScreen={true} />
        </MarginLargeLr>
      )}
    </React.Fragment>
  );
}
