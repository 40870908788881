import React, { useContext, useRef } from "react";
import {
  CreateCardContext,
  MAX_SELECTABLE_ITEMS
} from "../context/CreateCardContext";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clamp from "lodash-es/clamp";
import swap from "lodash-move";
import { useGesture } from "react-with-gesture";
import { useSprings, animated, interpolate } from "react-spring";
import { CardVideo, CardVideoWrapper } from "../styleguides/CardStyles";

const SelectedItemsWrapper = styled.div`
  transition: all 0.2s ease-in-out;
  will-change: height, opacity, background;
  overflow: auto;
  border-radius: 10px;
`;

const DraggableCard = styled(animated.div)`
  position: absolute;
  overflow: visible;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  color: white;
  background: transparent;

  height: 260px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  border-radius: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 180px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  color: ${props => props.theme.colors.text.contrast};

  ${props =>
    props.availabletile &&
    css`
      height: 120px;
      width: 90px;
      border: 1px solid ${props => props.theme.colors.general.secondary};
    `}
    z-index: 2;
`;

const GiphyItemsGrid = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.small};
  position: relative;
  height: 330px;
  align-items: center;

  ${props => `
  @media screen and (max-width: ${props.theme.screenSizes.medium}) {
    overflow-x: scroll;
  }
`}
 
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const RemoveItem = styled.div`
  position: absolute;
  top: 5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  right: 5px;
  background-color: ${props => props.theme.colors.button.primary};
  color: ${props => props.theme.colors.general.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

// Returns fitting styles for dragged/idle items
const fn = (order, down, originalIndex, curIndex, x) => index =>
  down && index === originalIndex
    ? {
        x: curIndex * 210 + x,
        scale: 1.05,
        zIndex: "1",
        shadow: 15,
        immediate: n => n === "x" || n === "zIndex"
      }
    : {
        x: order.indexOf(index) * 210,
        scale: 1,
        zIndex: "0",
        shadow: 1,
        immediate: false
      };

export default function CreateCardPicturesView() {
  const { selectedItems, setSelectedItems, removeItem } = useContext(
    CreateCardContext
  );

  const order = useRef(selectedItems.map((_, index) => index));
  const originalSelectedItems = useRef(selectedItems);

  const availableItemsLength = MAX_SELECTABLE_ITEMS - selectedItems.length;
  let availableItemCards = [];

  for (let i = 0; i < availableItemsLength; i++) {
    availableItemCards.push({ key: i });
  }

  const [springs, setSprings] = useSprings(
    selectedItems.length,
    fn(order.current)
  );

  const bind = useGesture(({ args: [originalIndex], down, delta: [x] }) => {
    const curIndex = order.current.indexOf(originalIndex);

    const curCol = clamp(
      Math.round((curIndex * 180 + x) / 180),
      0,
      selectedItems.length - 1
    );

    const newOrder = swap(order.current, curIndex, curCol);

    setSprings(fn(newOrder, down, originalIndex, curIndex, x)); // Feed springs new style data, they'll animate the view without causing a single render

    if (!down) {
      order.current = newOrder;
      const newSelectedItemsOrder = [];

      for (let i = 0; i < newOrder.length; i++) {
        newSelectedItemsOrder.push(selectedItems[newOrder[i]]);
      }

      setSelectedItems(newSelectedItemsOrder);
    }
  });

  if (!selectedItems || !selectedItems.length) {
    return null;
  }

  return (
    <SelectedItemsWrapper>
      <GiphyItemsGrid>
        {springs.map(({ zIndex, shadow, x, scale }, i) => {
          const selectedItem = originalSelectedItems.current[i];

          if (selectedItem.type.startsWith("image/")) {
            return (
              <DraggableCard
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  transform: interpolate(
                    [x, scale],
                    (x, s) => `translate3d(${x}px,0px,0) scale(${s})`
                  ),
                  backgroundImage: `url(${selectedItem.sources[0].url})`,
                  boxShadow: shadow.interpolate(
                    s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  )
                }}
              >
                <RemoveItem
                  onClick={() => {
                    if (
                      window.confirm(
                        "Remove this image? \n (Don't worry, we didn't store anything!)"
                      )
                    ) {
                      removeItem(selectedItem);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="times" />
                </RemoveItem>
              </DraggableCard>
            );
          } else if (selectedItem.type.startsWith("video/")) {
            return (
              <DraggableCard
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  transform: interpolate(
                    [x, scale],
                    (x, s) => `translate3d(${x}px,0px,0) scale(${s})`
                  ),
                  boxShadow: shadow.interpolate(
                    s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  )
                }}
              >
                <CardVideoWrapper>
                  <CardVideo
                    playsInline
                    autoPlay
                    muted
                    loop
                    preload="auto"
                    key={selectedItem.url}
                  >
                   {
          selectedItem.sources.map(source => (
            <source src={source.url} type={source.type} key={source.url} />
          ))
        }
                   
                  </CardVideo>
                </CardVideoWrapper>
                <RemoveItem
                  onClick={() => {
                    if (
                      window.confirm(
                        `Remove this uploaded ${
                          selectedItem.source === "GIPHY" ? "GIPHY" : "video"
                        }? \n (Don't worry, we didn't store anything!)`
                      )
                    ) {
                      removeItem(selectedItem);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="times" />
                </RemoveItem>
              </DraggableCard>
            );
          } else if (selectedItem.type === "SOLID") {
            return (
              <DraggableCard
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  transform: interpolate(
                    [x, scale],
                    (x, s) => `translate3d(${x}px,0px,0) scale(${s})`
                  ),
                  boxShadow: shadow.interpolate(
                    s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  backgroundImage: `linear-gradient(${selectedItem.sources[0].url})`
                }}
              >
                <RemoveItem
                  onClick={() => {
                    if (window.confirm("Remove this beautiful piece?")) {
                      removeItem(selectedItem);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="times" />
                </RemoveItem>
              </DraggableCard>
            );
          } else {
            return null;
          }
        })}
      </GiphyItemsGrid>
    </SelectedItemsWrapper>
  );
}
