import React from "react";
import styled from "styled-components";
import { CardAuthorThumb } from "../styleguides/CardStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledSpan from "../styleguides/StyledSpan";

const StyledProfileListItem = styled("li")`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${props => props.theme.spacing.medium};
  border-top: 1px solid #ffffff75;
`;

const StyledProfileListName = styled(StyledSpan)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  width: 80%;
  display: block;
  margin-left: ${props => props.theme.spacing.small};
`;

export default function UserListItem({ userProfile }) {
  if (!userProfile) {
    return null;
  }

  const hasUserAvatar = userProfile.photoURL && userProfile.photoURL.length > 0;

  return (
    <StyledProfileListItem>
      <CardAuthorThumb
        rightPadded
        url={hasUserAvatar && `url(${userProfile.photoURL})`}
      >
        {!hasUserAvatar && <FontAwesomeIcon icon={["far", "user"]} />}
      </CardAuthorThumb>
      <StyledProfileListName small white>
        {userProfile.displayName}
      </StyledProfileListName>
    </StyledProfileListItem>
  );
}
