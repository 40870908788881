import styled, { css } from "styled-components";

const StyledSpan = styled("span")`
    font-size: ${props => props.theme.fontSizes.medium};
    
    ${props => `
  @media screen and (min-width: ${props.theme.screenSizes.largex}) {
    font-size: ${props.theme.fontSizes.mediumx}
   }
  `};
${props =>
  props.color &&
  css`
    color: ${props => props.color};
  `}  
${props =>
  props.light &&
  css`
    color: ${props => props.theme.colors.text.primary.light};
  `}
    ${props =>
      props.white &&
      css`
        color: ${props => props.theme.colors.text.contrast};
      `}
      ${props =>
        props.secondary &&
        css`
          color: ${props => props.theme.colors.text.secondary};
        `}  
      ${props =>
        props.tertiary &&
        css`
          color: ${props => props.theme.colors.text.tertiary};
        `}    
    ${props =>
      props.offWhite &&
      css`
        color: ${props => props.theme.colors.text.primary.light};
      `}
    ${props =>
      props.tiny &&
      css`
        font-size: ${props => props.theme.fontSizes.tiny};
        ${props => `
        @media screen and (min-width: ${props.theme.screenSizes.largex}) {
          font-size: ${props.theme.fontSizes.small}
         }
        `};
      `}
    ${props =>
      props.small &&
      css`
        font-size: ${props => props.theme.fontSizes.small};
      `}
    ${props =>
      props.medium &&
      css`
        font-size: ${props => props.theme.fontSizes.medium};
      `}
    ${props =>
      props.large &&
      css`
        font-size: ${props => props.theme.fontSizes.large};
      `}  
    ${props =>
      props.italic &&
      css`
        font-style: italic;
      `}  
      ${props =>
        props.underlined &&
        css`
          text-decoration: underline;
        `}    
      ${props =>
        props.bold &&
        css`
          font-weight: bold;
        `} 
    ${props =>
      props.clickable &&
      css`
        cursor: pointer;
      `}     
    ${props =>
      props.alternative &&
      css`
        font-family: ${props => props.theme.fonts.alternative};
      `}  
      ${props =>
        props.error &&
        css`
          color: ${props => props.theme.colors.text.error};
        `}   
        ${props =>
          props.withIconRight &&
          css`
            svg {
              margin-left: ${props => props.theme.spacing.tinyx};
            }
          `}             
`;

export default StyledSpan;
