import { useEffect, useState } from "react";
import app from "../utils/firebase";

const emptyDefault = [];

function useFirestoreQuery(opts) {
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(opts.initialData || emptyDefault);
  const [limit] = useState(opts.limit || 5);
  const [error, setError] = useState(null);
  const [lastDocument, setLastDocument] = useState(opts.lastDocument || null);
  const [firstDocument, setFirstDocument] = useState(
    opts.firstDocument || null
  );
  const [didLoad, setDidLoad] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);

  function getQuery() {
    return app
      .firestore()
      .collection(opts.collection)
      .orderBy(opts.orderByField || "createdAt", opts.order || "desc")
      .limit(limit);
  }

  function fetchMoreData() {
    setBusy(true);

    try {
      getQuery()
        .startAfter(lastDocument)
        .get()
        .then(dataz => {
          if (dataz && dataz.docs && dataz.docs.length > 0) {
            const possibleReversedDocs = opts.reverseOnMore
              ? dataz.docs.reverse()
              : dataz.docs;
            const localData = possibleReversedDocs.map(function(
              documentSnapshot
            ) {
              const aDocument = documentSnapshot.data();
              aDocument.id = documentSnapshot.id;
              return aDocument;
            });
            setLastDocument(dataz.docs[dataz.docs.length - 1]);
            setData([...localData]);

            if (localData.length >= limit) {
              setHasMoreData(true);
            } else {
              setHasMoreData(false);
            }
          }
          setBusy(false);
        })
        .catch(e => {
          throw e;
        });
    } catch (e) {
      setHasMoreData(false);
      setError(e);
      setBusy(false);
    }
  }

  function subscribeToCollection() {
    const q = getQuery();
    return q.onSnapshot(handleSnapshotChanges);
  }

  function handleSnapshotChanges(snapshot) {
    const changes = snapshot.docChanges().map(function(change) {
      if (change.type === "added") {
        const newdoc = change.doc.data();
        newdoc.id = change.doc.id;
        return {
          type: "added",
          data: newdoc
        };
      }

      return {
        type: "unwanted",
        data: null
      };
    });

    if (snapshot.docs && snapshot.docs.length > 0) {
      if (changes && changes.length > 0) {
        const added = changes.filter(e => e.type === "added");

        if (opts.onNewData instanceof Function) {
          opts.onNewData(added.map(d => d.data));
        }
      }

      setLastDocument(snapshot.docs[snapshot.docs.length - 1]);

      if (snapshot.docs.length >= limit) {
        setHasMoreData(true);
      } else {
        setHasMoreData(false);
      }
    }
  }

  function fetchData() {
    setBusy(true);
    try {
      const q = getQuery();

      q.get().then(dataz => {
        if (dataz && dataz.docs && dataz.docs.length > 0) {
          const possibleReversedDocs = opts.reverseOnInitial
            ? dataz.docs.reverse()
            : dataz.docs;
          const localData = possibleReversedDocs.map(function(
            documentSnapshot
          ) {
            const aDocument = documentSnapshot.data();
            aDocument.id = documentSnapshot.id;
            return aDocument;
          });

          setFirstDocument(dataz.docs[0]);
          setLastDocument(dataz.docs[dataz.docs.length - 1]);
          setData(localData);

          if (localData.length >= limit) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
        }
        setBusy(false);
      });
    } catch (e) {
      setError(e);
      setBusy(false);
    } finally {
      if (!didLoad) {
        setDidLoad(true);
      }
    }
  }

  function subscribe() {
    return subscribeToCollection();
  }

  useEffect(() => {
    if (opts.fetchOnMount) {
      fetchData();
    }
  }, []);

  return {
    busy,
    data,
    error,
    fetchData,
    fetchMoreData,
    lastDocument,
    firstDocument,
    hasMoreData,
    subscribe,
    didLoad
  };
}

export default useFirestoreQuery;
