import React from "react";
import { Route } from "react-router-dom";

const SwitchRoute = ({ onTrue: RouteComponent, onFalse: SecondRouteComponent, condition, ...rest }) => {

  return (
    <Route
      {...rest}
      render={routeProps =>
        condition ? (
          <RouteComponent {...routeProps} />
        ) : (
          <SecondRouteComponent {...routeProps} />
        )
      }
    />
  );
};

export default SwitchRoute;