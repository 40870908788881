import React, { useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import useGiphy from "../hooks/useGiphy";
import GiphyTile from "./GiphyTile";
import {
  useTransition,
  useSpring,
  useChain,
  config,
} from "react-spring";
import useMedia from "../hooks/useMedia";
import { CreateCardContext } from "../context/CreateCardContext";
import { UserContext } from '../context/UserContext';
import StyledInput from "../styleguides/StyledInput";
import StyledButton from "../styleguides/StyledButton";
import useDebouncedCallback from "use-debounce/lib/callback";
import poweredByGiphy from "../assets/images/giphy/giphy_animated.gif";
import StyledSpan from "../styleguides/StyledSpan";
import numberWithCommas from "../utils/numberFormatter";
import useSafeState from "../hooks/useSafeState";
import { CardVideoWrapper, CardVideo } from "../styleguides/CardStyles";
import {
  Modal,
  TopSection,
  CloseWrapper,
  Container,
  ContentWrapper
} from '../styleguides/ModalStyles';

const SearchWrapper = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  background-color: ${props => props.theme.colors.section.primary};
  z-index: 1;
  > input {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const PaginationInfo = styled.div`
  font-weight: bold;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.fontSizes.tiny};
  padding: ${props => props.theme.spacing.tiny} 0;
`;

const PoweredByGiphyTag = styled.img`
  height: 130px;

  @media screen and (max-width: 500px) {
    height: 100px;
  }
`;

const QueryLabel = styled.p`
  margin: ${props => props.theme.spacing.tiny};
  text-align: center;
  color: salmon;
`;

export default function GiphySelector() {
  const [searchQuery, setSearchQuery] = useSafeState("");
  const { selectedItems, addItem, removeItem, setView } = useContext(
    CreateCardContext
  );

  const { idToken } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useSafeState(1);
  const [totalResults, setTotalResults] = useSafeState(0);
  
  const columns = useMedia(
    [
      "(min-width: 1900px)",
      "(min-width: 1205px)",
      "(min-width: 900px)",
      "(min-width: 600px)"
    ],
    [7, 5, 4, 3],
    2
  );

  const columnSize = useMedia(
    ["(min-width: 1000px)", "(max-width: 400px)"],
    [230, 160],
    160
  );

  // Let's debounce the queries to Giphy (reducing API-calls on input change).
  const [debouncedCallback] = useDebouncedCallback(
    value => {
      setTotalResults(0);
      fetchData(value, "search");
    },
    700,
    []
  );
  const resultsPerPage = columns * 4 - 1;
  const offset = (currentPage - 1) * resultsPerPage + 1;

  // eslint-disable-next-line
  const [busy, data, error, fetchData] = useGiphy({
    endpoint: "trending",
    query: searchQuery,
    initialData: { data: [] },
    limit: resultsPerPage,
    offset: offset > totalResults ? totalResults : offset,
    onSuccess: data => {
      setTotalResults(data.pagination.total_count);
    },
    idToken
  });

  useEffect(() => {
    setTotalResults(0);
    fetchData(searchQuery, "search");
  }, [offset]);

  const springRef = useRef();

  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: { size: "20%" },
    to: { size: "100%" }
  });

  const transRef = useRef();

  const transitions = useTransition(data.data, item => item && item.id ? item.id : null, {
    ref: transRef,
    unique: true,
    trail: 400 / data && data.data ? data.data.length : 1,
    from: { opacity: 0, transform: "scale(0)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0)" }
  });
  
  const fadeInBackground = useTransition("s", null, {
    from: { backgroundColor: "rgba(0,0,0,0.0)" },
    enter: { backgroundColor: "rgba(0,0,0,0.75)" },
    leave: { backgroundColor: "rgba(0,0,0,0.0)" },
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain([springRef, transRef], [0, 0.6]);

  return (
    <React.Fragment>
    {fadeInBackground.map(({ key:k, props:pz }) => (
      <Modal
      style={{
        ...pz,
      }}
      key={k}
    >
 <TopSection>
        <CloseWrapper onClick={() => setView("NONE")}><StyledSpan>Close</StyledSpan></CloseWrapper>
      </TopSection>
        <ContentWrapper>
          <SearchWrapper>
            <StyledInput
              value={searchQuery}
              onChange={e => {
                debouncedCallback(e.target.value);
                setSearchQuery(e.target.value);
                setCurrentPage(1);
              }}
              type="text"
              placeholder="Search GIPHY 🔍"
            />
            <PaginationInfo>
              {!totalResults && !busy && !transitions.length ? (
                <>
                  {error ? <StyledSpan tiny error>Wops! Tyrill wasn't able to serve you any GIPHYs. <span role="img" aria-label="sad-no-content">
                    😞
                  </span></StyledSpan> : <StyledSpan>No results <span role="img" aria-label="sad-no-content">
                    😞
                  </span></StyledSpan> }
                </>
              ) : (
                <StyledSpan>
                  {busy || Math.ceil(totalResults / resultsPerPage) === 0
                    ? "Loading.."
                    : `Page ${currentPage} of ${Math.ceil(
                        totalResults / resultsPerPage
                      )}`}
                </StyledSpan>
              )}
            </PaginationInfo>
            <Navigation>
              <StyledButton
                backgroundColor="#ffffff"
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
                disabled={currentPage <= 1}
                disabledState={currentPage <= 1}
              >
                Previous
              </StyledButton>

              <StyledButton
                backgroundColor="#ffffff"
                onClick={() => {
                  if (currentPage * resultsPerPage + 1 < totalResults) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
                disabled={currentPage * resultsPerPage + 1 >= totalResults}
                disabledState={currentPage * resultsPerPage + 1 >= totalResults}
              >
                Next
              </StyledButton>
            </Navigation>
          </SearchWrapper>

          <Container
            paddingtb="1rem"
            style={{ ...rest, width: size, height: size, overflowY: "scroll" }}
            columns={columns}
            columnsize={columnSize}
          >
            <GiphyTile>
              <StyledSpan white>
                {busy ? "..." : numberWithCommas(totalResults)}
              </StyledSpan>
              <StyledSpan offWhite tiny>
                Results for
              </StyledSpan>
              <QueryLabel>{searchQuery.toUpperCase() || "TRENDING"}</QueryLabel>
              <PoweredByGiphyTag src={poweredByGiphy} />
            </GiphyTile>

            {transitions.filter(t => t.item !== null).map(({ item, key, props }) => (
              <GiphyTile
                style={{
                  ...props,
                  border: selectedItems.some(selItem => selItem.id === item.id)
                    ? "5px solid salmon"
                    : "none"
                }}
                key={item.id}
                onClick={() => {
                  if (selectedItems.some(selItem => selItem.id === item.id)) {
                    removeItem(item);
                  } else {
                    addItem({
                      id: Math.floor(Math.random() * 13337) + 5,
                      source: "GIPHY",
                      type: "video/mp4",
                      sources: [
                        {
                          type: "video/webp",
                          url: item.images["original"].webp
                        },
                        { type: "video/mp4", url: item.images["original"].mp4 }
                      ]
                    });
                  }
                }}
              >
                <CardVideoWrapper>
                  <CardVideo
                    playsInline
                    autoPlay
                    muted
                    loop
                    preload="auto"
                    key={item.images["original"].webp}
                  >
                    <source
                      src={item.images["original"].webp}
                      type={"video/webp"}
                    />
                    <source
                      src={item.images["original"].mp4}
                      type={"video/mp4"}
                    />
                  </CardVideo>
                </CardVideoWrapper>
              </GiphyTile>
            ))}
          </Container>
        </ContentWrapper>
      </Modal>))
    }
   </React.Fragment>
  );
}
