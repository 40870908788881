import React from "react";
import { MarginMedium } from "../styleguides/ContentPadders";
import StyledTextLink from "../styleguides/StyledTextLink";
import ScrollableView from "../styleguides/ScrollableView";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledLink } from "../styleguides/StyledLink";
import Text from "../styleguides/Text";
import { TitleContext } from "../context/TitleContext";

const Top = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.colors.section.secondary};
  padding: ${props => props.theme.spacing.medium};
  z-index: 8;
`;

export default function IubendaPrivacy({ policyUrl, tabtitle, fallbackUrl }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [err, setErr] = React.useState(false);
  const [content, setContent] = React.useState(false);
  const { setTabTitle } = React.useContext(TitleContext);

  React.useEffect(() => {
    fetchPolicy();
    setTabTitle(tabtitle);
  }, []);

  async function fetchPolicy() {
    setErr(false);
    const res = await fetch(policyUrl);
    if (await res.ok) {
      const iubendaResponse = await res.json();
      setContent(iubendaResponse.content);
    } else {
      setErr(true);
    }
    setIsLoading(false);
  }

  return (
    <ScrollableView zIndex={8} background={"#ffffff"}>
      <Top>
        <StyledLink to="/">
          <Text element="span" size="bigger" bold textColor="white">
            <FontAwesomeIcon icon="arrow-left" /> Back to Tyrill
          </Text>
        </StyledLink>
      </Top>
      <MarginMedium>
        <Text size="smaller">
          Also see our{" "}
          <StyledTextLink to="/terms">Terms of Use</StyledTextLink>.
        </Text>
      </MarginMedium>

      <MarginMedium>
        {isLoading && "Loading.."}
        {content && (
          <div
            dangerouslySetInnerHTML={{
              __html: content
            }}
          ></div>
        )}
      </MarginMedium>
      {err && (
        <MarginMedium>
          <Text element="p" textColor="error">
            It looks like we have trouble viewing our Privacy Policy here, visit{" "}
            {fallbackUrl} to view the full policy at Iubenda.
          </Text>
        </MarginMedium>
      )}
    </ScrollableView>
  );
}
