import React, { useState, useContext } from "react";
import { useSprings, interpolate } from "react-spring";
import { useGesture } from "react-with-gesture";
import CardText from "./CardText";
import moment from "moment";
import {
  CardWrapper,
  Card,
  CardTag,
  CardContentPadder,
  CardAuthor,
  CardAuthorName,
  CardAuthorThumb,
  CardImage,
  CardContentWrapper,
  CardDescriptionWrapper,
  CardMute,
  CardImagePickerWrapper,
  CardImagePicker,
  CardPictureCrumbs,
  CardPictureCrumb,
  CardVideo,
  CardVideoWrapper,
  StaticCardLoaderWrapper,
  CardAuthorWrapper,
  CardCreatedAt,
  NonSelectedAuthorWrapper,
  CardCommentThumb,
  CardHighlight,
  CardHighlightAuthor,
  CardHighlightClose,
  AuthorNameWrapper,
  CardTopRightWrapper,
  HighlightedImageCrumbs,
  CardOptionsContainer,
  CircularIcon,
  CardSwipeLeft,
  CardSwipeRight
} from "../styleguides/CardStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { CardBrowserContext } from "../context/CardBrowserContext";
import StyledSpan from "../styleguides/StyledSpan";
import Text from "../styleguides/Text";
import CardLikeList from "./CardLikeList";
import Comments from "./Comments";

const CardHightlightClose = styled("div")`
  display: flex;
  align-items: center;

  div {
    margin: 0 ${props => props.theme.spacing.small};
  }
`;

const to = i => ({
  x: 0,
  y: i * -2,
  scale: 1,
  rot: -2 + Math.random() * 4,
  delay: i * 40
});

const from = i => ({ x: i % 2 === 0 ? 1000 : -1000, rot: 0, scale: 1.5, y: 0 });

const trans = (r, s, p) => {
  const transform = `perspective(2200px) rotateX(${p}deg) rotateY(${r /
    10}deg) rotateZ(${r}deg) scale(${s})`;
  return transform;
};
const now = moment();

function CardDeck({ posts }) {
  if(!posts || !posts.length > 0) {
    return null;
  }

  const [removedCards] = useState(() => new Set());
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentItem, setCurrentItem] = useState(posts[posts.length - 1].id);
  const [isDisplayingLikes, setIsDisplayingLikes] = useState(null);
  const [isDisplayingComments, setIsDisplayingComments] = useState(null);
  const [nextImage, setNextImage] = useState(0);
  const [highlightedImage, setHighlightedImage] = useState(null);
  const [hasVolume, setHasVolume] = useState(false);
  const {
    fetchNextCards,
    swipeCard,
    heartCard,
    setHasHeartedCard
  } = useContext(CardBrowserContext);

  const [props, set] = useSprings(posts.length, i => ({
    ...to(i),
    from: from(i)
  }));

  const bind = useGesture(
    ({
      args: [index, isItemSelected],
      down,
      delta: [xDelta],
      direction: [xDir],
      velocity
    }) => {
      const indexOfCurrentCard = posts.length - removedCards.size - 1;
      if (isItemSelected || !(index === indexOfCurrentCard)) {
        return;
      }

      const dir = xDir < 0 ? -1 : 1;
      const trigger = velocity > 0.6;
      const isRemovingCard = !down && trigger;
      set(i => {
        if (index !== i) return;
        const x = isRemovingCard
          ? (200 + window.innerWidth) * dir
          : down
          ? xDelta
          : 0;
        const rot = xDelta / 100 + (isRemovingCard ? dir * 10 * velocity : 0);
        const scale = down ? 1.02 : 1;

        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: {
            friction: 50,
            tension: down ? 700 : isRemovingCard ? 300 : 600
          }
        };
      });

      if (isRemovingCard) {
        window.setTimeout(() => {
          removedCards.add(index);
          setNextImage(0);
          swipeCard(posts[indexOfCurrentCard]);
          if (removedCards.size !== posts.length) {
            updateCurrentItem(indexOfCurrentCard);
          }

          if (removedCards.size === posts.length) {
            setSelectedItem(null);
            fetchNextCards();
          }
        }, 150);
      }
    }
  );

  const updateCurrentItem = prevIdx => {
    if (prevIdx !== 0 && !!posts[prevIdx - 1]) {
      setCurrentItem(posts[prevIdx - 1].id);
    }
  };

  const handleItemClick = (item, i) => {
    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
    } else if (posts.length - removedCards.size - 1 === i) {
      setSelectedItem(item);
    }
  };

  const handleLikesClick = (item, i) => {
    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
      setIsDisplayingLikes(false);
    } else if (posts.length - removedCards.size - 1 === i) {
      setSelectedItem(item);
      setIsDisplayingLikes(item);
    }
  };

  const handleCommentsClick = (item, i) => {
    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
      setIsDisplayingComments(false);
    } else if (posts.length - removedCards.size - 1 === i) {
      setSelectedItem(item);
      setIsDisplayingComments(item);
    }
  };

  const handleItemRemove = dir => {
    const indexOfCurrentCard = posts.length - removedCards.size - 1;

    set(i => {
      if (indexOfCurrentCard !== i) return;
      const isGone = true;
      const x = (200 + window.innerWidth) * dir;
      const rot = 10 / 100 + (isGone ? dir * 10 * 6 : 0);
      const scale = 1.02;

      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: 300 }
      };
    });

    window.setTimeout(() => {
      removedCards.add(indexOfCurrentCard);
      setNextImage(0);
      swipeCard(posts[indexOfCurrentCard]);
      if (removedCards.size !== posts.length) {
        updateCurrentItem(indexOfCurrentCard);
      }

      if (removedCards.size === posts.length) {
        setSelectedItem(null);
        fetchNextCards();
      }
    }, 150);
   
  };

  const handleHeartCard = () => {
    const indexOfCurrentCard = posts.length - removedCards.size - 1;

    set(i => {
      if (indexOfCurrentCard !== i) return;
      const nextRandom = Math.random();
      const x = window.innerWidth;
      const y =  -1 * window.innerHeight;
      const rot = Math.floor(nextRandom * 70 + 1) * (nextRandom > 0.3 ? -1 : 1);
      const scale = 0;

      return {
        x,
        y,
        rot,
        scale,
        delay: undefined,
        config: { friction: 95, tension: 300 }
      };
    });

    heartCard(posts[indexOfCurrentCard]);
    removedCards.add(indexOfCurrentCard);

    window.setTimeout(() => {
      if (removedCards.size !== posts.length) {
        updateCurrentItem(indexOfCurrentCard);
        setNextImage(0);
      }
      setHasHeartedCard(posts[indexOfCurrentCard].id);
    }, 250);

    if (removedCards.size === posts.length) {
      setSelectedItem(null);
      fetchNextCards();
    }
  };

  const renderCardOverlay = (isCurrentCard, post) => {
    const itemToRender = isCurrentCard
      ? post.overlays[nextImage]
      : post.overlays[0];

    if (!itemToRender) {
      return null;
    }

    if (itemToRender.type.startsWith("image/")) {
      return (
        <CardImage
          onClick={() => setHighlightedImage(null)}
          backgroundImage={`url(${itemToRender.sources[0].resolvedUrl ||
            itemToRender.sources[0].url})`}
        />
      );
    } else if (itemToRender.type.startsWith("video/")) {
      return (
        <CardVideoWrapper>
          <CardVideo
            playsInline
            autoPlay
            muted={!(hasVolume === itemToRender.id && isCurrentCard)}
            loop
            preload="auto"
            key={
              itemToRender.sources[0].resolvedUrl || itemToRender.sources[0].url
            }
          >
            {itemToRender.sources.map(source => (
              <source
                src={source.resolvedUrl || source.url}
                type={source.type}
                key={source.resolvedUrl || source.url}
              />
            ))}
          </CardVideo>
        </CardVideoWrapper>
      );
    } else if (itemToRender.type === "SOLID") {
      return (
        <CardImage
          onClick={() => setHighlightedImage(null)}
          backgroundImage={`linear-gradient(${itemToRender.sources[0].url})`}
        />
      );
    } else {
      return null;
    }
  };

  if (!posts) {
    return null;
  }

  return (
    <React.Fragment>
      {highlightedImage && (
        <CardHighlight>
          <CardHighlightClose>
            <CardHighlightAuthor>
              <CardAuthorThumb
                rightPadded
                url={`url(${highlightedImage.createdBy.photoURL})`}
              />
              <AuthorNameWrapper>
                <Text size="smaller" color={highlightedImage.themeColor.fontColor} withShadow>
                  {highlightedImage.createdBy.displayName}
                </Text>
                <Text size="smallest" color={highlightedImage.themeColor.fontColor} withShadow>
                  {moment
                    .duration(
                      now.diff(
                        highlightedImage.createdAt
                          ? highlightedImage.createdAt.toDate
                            ? highlightedImage.createdAt.toDate()
                            : highlightedImage.createdAt
                          : new Date()
                      )
                    )
                    .humanize()}{" "}
                  ago
                </Text>
              </AuthorNameWrapper>
            </CardHighlightAuthor>
            <CardHightlightClose>
              {highlightedImage.overlays[nextImage].type.startsWith(
                "video"
              ) && (
                <CardMute
                  onClick={() =>
                    setHasVolume(
                      highlightedImage.overlays[nextImage].id === hasVolume
                        ? false
                        : highlightedImage.overlays[nextImage].id
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      !(hasVolume === highlightedImage.overlays[nextImage].id)
                        ? "volume-mute"
                        : "volume-up"
                    }
                  />
                </CardMute>
              )}
              <div onClick={() => setHighlightedImage(null)}>
                <Text underlined textColor="white" withShadow>
                  Close
                </Text>
              </div>
            </CardHightlightClose>
          </CardHighlightClose>
          <CardImagePickerWrapper>
            <CardImagePicker
              onClick={() =>
                setNextImage(nextImage - 1 <= 0 ? 0 : nextImage - 1)
              }
            />
            <CardImagePicker onClick={() => setHighlightedImage(null)} />
            <CardImagePicker
              onClick={() =>
                setNextImage(
                  nextImage + 1 === highlightedImage.overlays.length
                    ? nextImage
                    : nextImage + 1
                )
              }
            />
          </CardImagePickerWrapper>
          {highlightedImage.overlays && highlightedImage.overlays.length > 1 && (
            <HighlightedImageCrumbs>
              <CardPictureCrumbs>
                {highlightedImage.overlays.map((url, idx) => (
                  <CardPictureCrumb key={idx} isCurrent={nextImage === idx} />
                ))}
              </CardPictureCrumbs>
            </HighlightedImageCrumbs>
          )}

          {renderCardOverlay(true, highlightedImage)}
        </CardHighlight>
      )}

      {props.map(({ rot, x, y, scale }, i) => {
        if (removedCards.has(i)) {
          return null;
        }

        const item = posts[i];
        const isItemSelected = selectedItem && item.id === selectedItem.id;
        const perspective = isItemSelected ? 13 : 20;
        const isCurrentCard = item.id === currentItem;
        const hasCardContent = item.content && item.content.length > 0;
        const hasUserAvatar =
          item.createdBy &&
          item.createdBy.photoURL &&
          item.createdBy.photoURL.length > 0;

        const isVideo =
          isCurrentCard && item.overlays[nextImage].type.startsWith("video");

        return (
          <CardWrapper
            key={item.id}
            style={{
              transform: interpolate(
                [x, y],
                (x, y) => `translate3d(${x}px, ${y}px, 0)`
              )
            }}
          >
            <Card
              themecolor={item.themeColor.background}
              {...bind(i, isItemSelected)}
              style={{
                transform: interpolate([rot, scale], (r, s) =>
                  trans(r, s, perspective)
                )
              }}
            >
              <div>
                {isCurrentCard && !isDisplayingLikes && !isDisplayingComments && (
                  <CardOptionsContainer>
                    <CircularIcon
                      onClick={() => handleHeartCard()}
                      title={`Heart this card`}
                    >
                      <Text element="span" textColor="secondary">
                        <FontAwesomeIcon icon="heart" />
                      </Text>
                    </CircularIcon>
                    <CircularIcon
                      onClick={() =>
                        alert("It will soon be possible to report cards.")
                      }
                      title="Report this card"
                    >
                      <Text element="span" textColor="primary.light">
                        <FontAwesomeIcon icon={"flag"} />
                      </Text>
                    </CircularIcon>
                  </CardOptionsContainer>
                )}
                {isCurrentCard && !isDisplayingLikes && !isDisplayingComments && (
                  <React.Fragment>
                    <CardSwipeLeft
                      title="Swipe the card left"
                      onClick={() => handleItemRemove(-1)}
                    />
                    <CardSwipeRight
                      title="Swipe the card right"
                      onClick={() => handleItemRemove(1)}
                    />
                  </React.Fragment>
                )}

                <CardTopRightWrapper>
                  <CardTag
                    color={item.themeColor.fontColor}
                    background={item.themeColor.background}
                    to={`/discover/${item.tag.toLowerCase()}`}
                  >
                    #{item.tag}
                  </CardTag>

                  {isVideo && (
                    <CardMute
                      onClick={() =>
                        setHasVolume(
                          item.overlays[nextImage].id === hasVolume
                            ? false
                            : item.overlays[nextImage].id
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          !(
                            isCurrentCard &&
                            hasVolume === item.overlays[nextImage].id
                          )
                            ? "volume-mute"
                            : "volume-up"
                        }
                      />
                    </CardMute>
                  )}
                </CardTopRightWrapper>
              </div>
              {isCurrentCard && item.overlays.length > 1 && (
                <CardPictureCrumbs>
                  {item.overlays.map((url, idx) => (
                    <CardPictureCrumb key={idx} isCurrent={nextImage === idx} />
                  ))}
                </CardPictureCrumbs>
              )}
              <CardContentWrapper
                isItemSelected={isItemSelected}
                background={item.themeColor.background}
                noContent={!hasCardContent}
              >
                {isCurrentCard && isDisplayingLikes && (
                  <CardLikeList
                    key={item.id}
                    onClose={() => handleLikesClick(item, i)}
                    cardId={item.id}
                  />
                )}
                {isCurrentCard && isDisplayingComments && (
                  <Comments
                    key={item.id}
                    onClose={() => handleCommentsClick(item, i)}
                    card={item}
                  />
                )}
                <CardContentPadder>
                  {isItemSelected &&
                    (!isDisplayingLikes && !isDisplayingComments) && (
                      <CardAuthor>
                        <CardAuthorWrapper gutterBottom={hasCardContent}>
                          <CardAuthorThumb
                            rightPadded
                            url={
                              hasUserAvatar && `url(${item.createdBy.photoURL})`
                            }
                          >
                            {!hasUserAvatar && (
                              <FontAwesomeIcon icon={["far", "user"]} />
                            )}
                          </CardAuthorThumb>
                          <AuthorNameWrapper>
                            <CardAuthorName
                              color={
                                hasCardContent && item.themeColor.fontColor
                              }
                            >
                              {item.createdBy.displayName}
                            </CardAuthorName>
                            <CardCreatedAt
                              color={
                                hasCardContent && item.themeColor.fontColor
                              }
                            >
                              {moment
                                .duration(
                                  now.diff(
                                    item.createdAt
                                      ? item.createdAt.toDate
                                        ? item.createdAt.toDate()
                                        : item.createdAt
                                      : new Date()
                                  )
                                )
                                .humanize()}{" "}
                              ago
                            </CardCreatedAt>
                          </AuthorNameWrapper>
                        </CardAuthorWrapper>
                        <CardCommentThumb
                          color={hasCardContent && item.themeColor.fontColor}
                          onClick={() => handleCommentsClick(item, i)}
                        >
                          <StyledSpan tiny withIconRight>
                            {`${item.commentCount}`}
                            <FontAwesomeIcon icon={["far", "comment"]} />
                          </StyledSpan>
                        </CardCommentThumb>
                      </CardAuthor>
                    )}
                  {!isItemSelected &&
                    (!isDisplayingLikes && !isDisplayingComments) && (
                      <NonSelectedAuthorWrapper>
                        <CardAuthorWrapper gutterBottom={hasCardContent}>
                          <CardAuthorThumb
                            rightPadded
                            url={
                              hasUserAvatar && `url(${item.createdBy.photoURL})`
                            }
                          >
                            {!hasUserAvatar && (
                              <FontAwesomeIcon icon={["far", "user"]} />
                            )}
                          </CardAuthorThumb>
                          <AuthorNameWrapper>
                            <CardAuthorName
                              color={
                                hasCardContent && item.themeColor.fontColor
                              }
                            >
                              {item.createdBy.displayName}
                            </CardAuthorName>
                            <CardCreatedAt
                              color={
                                hasCardContent && item.themeColor.fontColor
                              }
                            >
                              {moment
                                .duration(
                                  now.diff(
                                    item.createdAt
                                      ? item.createdAt.toDate
                                        ? item.createdAt.toDate()
                                        : item.createdAt
                                      : new Date()
                                  )
                                )
                                .humanize()}{" "}
                              ago
                            </CardCreatedAt>
                          </AuthorNameWrapper>
                        </CardAuthorWrapper>
                        <CardCommentThumb
                          color={hasCardContent && item.themeColor.fontColor}
                          onClick={() => handleCommentsClick(item, i)}
                        >
                          <StyledSpan withIconRight tiny>
                            {`${item.commentCount}`}
                            <FontAwesomeIcon icon={["far", "comment"]} />
                          </StyledSpan>
                        </CardCommentThumb>
                      </NonSelectedAuthorWrapper>
                    )}
                  {hasCardContent &&
                    (!isDisplayingLikes && !isDisplayingComments) && (
                      <CardDescriptionWrapper
                        isItemSelected={isItemSelected}
                        onClick={() =>
                          hasCardContent && handleItemClick(item, i)
                        }
                      >
                        <CardText
                          text={item.content}
                          color={item.themeColor.fontColor}
                        />
                      </CardDescriptionWrapper>
                    )}
                </CardContentPadder>
              </CardContentWrapper>
              <CardImagePickerWrapper>
                <CardImagePicker
                  onClick={() =>
                    setNextImage(nextImage - 1 <= 0 ? 0 : nextImage - 1)
                  }
                />
                <CardImagePicker onClick={() => setHighlightedImage(item)} />
                <CardImagePicker
                  onClick={() =>
                    setNextImage(
                      nextImage + 1 === item.overlays.length
                        ? nextImage
                        : nextImage + 1
                    )
                  }
                />
              </CardImagePickerWrapper>
              {renderCardOverlay(isCurrentCard, item)}
              <StaticCardLoaderWrapper>
                <span>
                  <FontAwesomeIcon icon="dharmachakra" spin />
                </span>
              </StaticCardLoaderWrapper>
            </Card>
          </CardWrapper>
        );
      })}
    </React.Fragment>
  );
}

export default CardDeck;
