import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function tyrillErrorToast(message, autoClose = 3500, toastId) {
    if(toastId) {
        return toast.error(message, {
            toastId,
            autoClose
        });
    } 
    return toast.error(message, {
        autoClose
    });
}

export function tyrillSuccessToast(message, autoClose = 3500, toastId) {
    if(toastId) {
        return toast.success(message, {
            toastId,
            autoClose
        });
    }
    return toast.success(message, {
        autoClose
    });
}