import React from "react";
import styled from "styled-components";
import Text from "../styleguides/Text";
import {
  MarginLarge,
  MarginMediumTb
} from "../styleguides/ContentPadders";
import ScrollableView from "../styleguides/ScrollableView";
import sampleTyrillCardImage from "../assets/images/tyrill/tyrill-sample-card.png";
import sampleTyrillCommentsImage from "../assets/images/tyrill/tyrill-sample-card-comment.png";
import sampleTyrillDeckImage from "../assets/images/tyrill/tyrill-sample-carddeck.png";
import { StyledLink } from "../styleguides/StyledLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../styleguides/Flex";
import { backgroundColorChanger } from "../utils/animations";
import CircularFab from "../styleguides/CircularFab";
import { TitleContext } from "../context/TitleContext";

const Section = styled.section`
  padding: ${props => props.theme.spacing.smallx};
`;

const TertiarySection = styled(Section)`
  background: ${props => props.theme.colors.section.tertiary};
`;

const StickySectionHeader = styled(Section)`
  background-color: ${props => props.theme.colors.section.primary};
  position: sticky;
  top: 45px;
  z-index: 2;
`;

const AnimatedSection = styled(Section)`
  animation: ${backgroundColorChanger} 75s linear infinite;
`;

const BackStickySection = styled(Section)`
  position: sticky;
  top: 0;
  z-index: 2;
  animation: ${backgroundColorChanger} 75s linear infinite;
`;

const ContentWithImage = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: ${props => props.theme.screenSizes.mediumx};

  ${props => `
  @media screen and (max-width: ${props.theme.screenSizes.medium}) {
   flex-flow: column;
   justify-content: center;
  }
`};
`;

const StyledDescriptiveImage = styled.img`
  height: 400px;

  ${props => `
  @media screen and (max-width: ${props.theme.screenSizes.medium}) {
   padding: ${props.theme.spacing.large} 0;
  }
`};
`;

const ImageCaption = styled.div`
  ${props => `
  @media screen and (min-width: ${props.theme.screenSizes.medium}) {
   display: none;
  }
`};
`;

export default function About() {

  const { setTabTitle } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTabTitle("Tyrill - About Tyrill")
  }, []);

  return (
      <ScrollableView zIndex={8} background={"#ffffff"}>
        <BackStickySection>
          <StyledLink to="/">
            <Text element="span" size="bigger" bold textColor="white">
              <FontAwesomeIcon icon="arrow-left" /> Back to Tyrill
            </Text>
          </StyledLink>
        </BackStickySection>
        <AnimatedSection>
          <Text
            variant="title"
            element="h1"
            textColor="white"
            size="biggest"
            fontType="alternative"
          >
            About Tyrill
          </Text>
          <MarginLarge>
            <Text
              variant="title"
              element="h2"
              gutterBottom
              fontType="alternative"
              textColor="white"
            >
              Our mission
            </Text>
            <Text variant="subtitle" element="p" italic textColor="white">
              Enable people to create new connections by sharing their moments
              with other people nearby.
            </Text>
          </MarginLarge>
        </AnimatedSection>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            What is Tyrill?
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text variant="subtitle" element="p">
              Tyrill is a social network that allows users to browse and share
              content in the form of digital cards.
            </Text>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            Who can use Tyrill?
          </Text>
        </StickySectionHeader>
        <Section>
          <MarginLarge>
            <Text variant="subtitle" element="p">
              Tyrill requires an existing{" "}
              <Text
                element="a"
                target={"_blank"}
                href="https://facebook.com"
                title="Navigate to Facebook"
              >
                Facebook account
              </Text>{" "}
              (external link).
            </Text>
          </MarginLarge>
        </Section>
        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            How does Tyrill work?
          </Text>
        </StickySectionHeader>
        <Section>
          <Flex align="center" justify="center">
            <MarginLarge>
              <ContentWithImage>
                <div>
                  <Text
                    variant="title"
                    element="h3"
                    gutterBottom
                    fontType="alternative"
                  >
                    Card deck
                  </Text>
                  <Text variant="subtitle" element="p">
                    To browse content in Tyrill, users swipe through a deck of
                    user uploaded cards. Swiping is simply a way to browse, it
                    does not matter which way you swipe.
                  </Text>
                </div>
                <StyledDescriptiveImage
                  src={sampleTyrillDeckImage}
                  alt="Sample picture of a Tyrill Card"
                />
                <ImageCaption>
                  <Text element="p" italic>
                    An image of a card deck in Tyrill.
                  </Text>
                </ImageCaption>
              </ContentWithImage>
            </MarginLarge>
          </Flex>
          <Flex align="center" justify="center">
            <MarginLarge>
              <ContentWithImage>
                <div>
                  <Text
                    variant="title"
                    element="h3"
                    gutterBottom
                    fontType="alternative"
                  >
                    Card
                  </Text>
                  <Text variant="subtitle" element="p">
                    Users may create cards including pictures or videos,
                    optionally with a text to describe their card. By default, a
                    card in Tyrill is available to every logged in user.
                  </Text>
                </div>

                <StyledDescriptiveImage
                  src={sampleTyrillCardImage}
                  alt="Sample picture of a Tyrill Card"
                />
                <ImageCaption>
                  <Text element="p" italic>
                    An image of a card in Tyrill.
                  </Text>
                </ImageCaption>
              </ContentWithImage>
            </MarginLarge>
          </Flex>
        </Section>
        <MarginMediumTb>
          <TertiarySection>
            <MarginLarge>
              <Text
                variant="title"
                element="h3"
                gutterBottom
                fontType="alternative"
                textColor="white"
                withIconLeft
              >
                <FontAwesomeIcon icon="search" /> Discovering new cards
              </Text>
              <Text variant="subtitle" element="p" textColor="white">
                Users may discover new cards by checking out trending card
                categories, or use their current location to discover cards
                recently published nearby.
              </Text>
            </MarginLarge>
          </TertiarySection>
        </MarginMediumTb>

        <StickySectionHeader>
          <Text variant="subtitle" element="h3" fontType="alternative">
            User engagement
          </Text>
        </StickySectionHeader>
        <Flex align="center" justify="center">
          <MarginLarge>
            <ContentWithImage>
              <div>
                <Text
                  variant="title"
                  element="h3"
                  gutterBottom
                  fontType="alternative"
                >
                  Commenting
                </Text>
                <Text variant="subtitle" element="p">
                  Users may add comments to other users uploaded cards.
                </Text>
              </div>

              <StyledDescriptiveImage
                src={sampleTyrillCommentsImage}
                alt="Sample picture of a Tyrill Card"
              />
              <ImageCaption>
                <Text element="p" italic>
                  An image of a conversation on a card in Tyrill.
                </Text>
              </ImageCaption>
            </ContentWithImage>
          </MarginLarge>
        </Flex>
        <Flex align="center" justify="center">
          <MarginLarge>
            <ContentWithImage>
              <div>
                <Text
                  variant="title"
                  element="h3"
                  gutterBottom
                  fontType="alternative"
                >
                  Where is the "Like"-button?
                </Text>
                <Text variant="subtitle" element="p">
                  It is possible to appreciate a card by hitting a heart icon on
                  the left of each card.
                </Text>
                <ul>
                  <li>
                    <Text variant="subtitle" element="p">
                      Appreciating a card puts the card into your "heart" card
                      deck, so that you may view it later.
                    </Text>
                  </li>
                  <li>
                    <Text variant="subtitle" element="p">
                      Receiving a heart increments a "heart" counter on the card
                      owners profile page.
                    </Text>
                  </li>
                </ul>
                <Text variant="subtitle" element="p">
                  Only the user who uploaded the card may view how many hearts
                  the card has received. Every user may view how many "hearts" a
                  user profile has received.
                </Text>
              </div>
              <MarginLarge>
                <CircularFab icon="heart" pulse={true} textColor="secondary" />
              </MarginLarge>
              <ImageCaption>
                <Text element="p" italic>
                  Users have their own heart counter.
                </Text>
              </ImageCaption>
            </ContentWithImage>
          </MarginLarge>
        </Flex>
      </ScrollableView>
  );
}
