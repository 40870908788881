import React from "react";
import Text from "../styleguides/Text";

import Notification from "./Notification";
import StyledButton from "../styleguides/StyledButton";
import Flex from "../styleguides/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MarginMediumTb,
} from "../styleguides/ContentPadders";
import ScrollableView from "../styleguides/ScrollableView";
import { UserContext } from "../context/UserContext";
import LoadingIndicator from "./LoadingIndicator";
import useFirestoreQuery from "../hooks/useFirestoreQuery";
import {
  setNotificationSeen,
  markAllAsSeen,
  removeNotification
} from "../utils/notificationHelper";
import styled from "styled-components";
import { TitleContext, DEFAULT_APP_COLOR } from "../context/TitleContext";

const PaddedContent = styled.div`
  padding: ${props => props.theme.spacing.largex}
    ${props => props.theme.spacing.small};
`;

const AbsoluteView = styled.div`
  position: absolute;
  top: 60px;
  height: calc(100% - 60px);
  width: 100%;
  border-top: 5px solid #eee;
`;

const NotificationsWrapper = styled.div`
`;

export default function Notifications() {
  const { user } = React.useContext(UserContext);
  const [notifications, setNotifications] = React.useState([]);
  const [deletedNotification, setDeletedNotification] = React.useState(null);

  const { busy, data, fetchMoreData, hasMoreData } = useFirestoreQuery({
    collection: `users/${user.uid}/notifications`,
    limit: 15,
    initialData: [],
    reverseOnInitial: false,
    reverseOnMore: false,
    orderByField: "updatedAt",
    order: "desc",
    fetchOnMount: true
  });

  const { setTitle, setTabTitle, setPath, lastPath, setTitleColor } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTabTitle("Tyrill - Notifications");
    setTitle("Back");
    setTitleColor(DEFAULT_APP_COLOR);
    setPath(lastPath);
  }, []);

  React.useEffect(() => {
    if (data) {
      if (notifications && notifications.length > 0) {
        const uniqueNotifications = data.filter(
          cmt => !notifications.some(oldCmt => oldCmt.id === cmt.id)
        );
        if (uniqueNotifications && uniqueNotifications.length > 0) {
          setNotifications([...notifications, ...uniqueNotifications]);
        }
      } else {
        setNotifications([...data]);
      }
    }
  }, [data]);


  React.useEffect(() => {
    if(deletedNotification) {
      setNotifications(notifications.filter(not => not.id !== deletedNotification));
      setDeletedNotification(null);
    }
  },
  [deletedNotification]);

  const hasNotifications = notifications && notifications.length > 0;
  const hasUnseenNotifications = notifications.some(
    n => !Object.keys(n).includes("seen")
  );

  function handleSetNotificationAsSeen(notificationId) {
    setNotificationSeen(notificationId, user.uid, () => {
      setNotifications(
        notifications.map(n => {
          if (n.id === notificationId) {
            n.seen = true;
            return n;
          }
          return n;
        })
      );
    });
  }

  function handleRemoveNotification(notificationId) {
    removeNotification(notificationId, user.uid, () => {
      setDeletedNotification(notificationId);
    })
  }

  return (
    <AbsoluteView>
      <ScrollableView>
        <MarginMediumTb>
          {hasNotifications && (
            <NotificationsWrapper>
              {notifications.map((notification, idx) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onNotificationClick={() =>
                    !notification.seen &&
                    handleSetNotificationAsSeen(notification.id)
                  }
                  onNotificationRemove={() => handleRemoveNotification(notification.id)}
                />
              ))}
            </NotificationsWrapper>
          )}
          {busy && (
            <PaddedContent>
              <Flex centerContent>
                <LoadingIndicator
                  message={"Loading notifications.."}
                  textColor="primary.light"
                />
              </Flex>
            </PaddedContent>
          )}

          {hasNotifications && hasMoreData && (
            <StyledButton
              backgroundColor="#ffffff"
              onClick={() => fetchMoreData()}
            >
              <Text bold element="span" textColor="secondary">
                Load older notifications
              </Text>
            </StyledButton>
          )}

          {!busy && !hasNotifications && (
            <PaddedContent>
              <Flex
                align="center"
                justify="center"
                direction="column"
                fullHeight
              >
                <Text
                  variant="text"
                  element="h2"
                  textColor="secondary"
                  bold
                  withIconRight
                >
                  All notifications <FontAwesomeIcon icon="bell" />
                </Text>
                <Text size="smaller" textColor="primary.light">
                  You don't have any notifications, yet.{" "}
                  <span role="img" aria-label="Cat Crying">
                    😿
                  </span>
                </Text>
              </Flex>
            </PaddedContent>
          )}

          {hasNotifications && hasUnseenNotifications && (
            <StyledButton
              backgroundColor="#ffffff"
              onClick={() =>
                markAllAsSeen(notifications, user.uid, () => {
                  setNotifications(
                    notifications.map(n => {
                      n.seen = true;
                      return n;
                    })
                  );
                })
              }
            >
              <Text bold element="span" textColor="secondary">
                Mark all as read
              </Text>
            </StyledButton>
          )}
        </MarginMediumTb>
      </ScrollableView>
    </AbsoluteView>
  );
}
