import styled, { css } from "styled-components";
import noTouchHighlight from "./noTouchHighlight";

const StyledButton = styled.button`
    border: 0;
    padding: 0.7rem 1rem;
    color: ${props => props.color || props.theme.colors.text.contrast};
    font-weight: bold;
    background-color: ${props =>
      props.backgroundColor || props.theme.colors.button.secondary};
    width: 100%;
    font-size: ${props => props.theme.fontSizes.button};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${props => props.theme.fonts.primary};
    transition: all 0.15s cubic-bezier(0.4, 0.35, 0.63, 0.47);
    
    svg {
        margin: 0 ${props => props.theme.spacing.small};
    }

    &:focus {
        outline: 0;
    }

    ${props =>
      props.bottomBorderRadius &&
      css`
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      `}

    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}
    ${props =>
      props.fullWidth &&
      css`
        width: 100%;
      `}
    ${props =>
      props.gutterTop &&
      css`
        margin-top: ${props => props.theme.spacing.small};
      `}
    ${props =>
      props.disabledState &&
      css`
        color: ${props => props.theme.colors.text.primary.lightest};
      `}

    ${props =>
      props.faded &&
      css`
        color: ${props => props.theme.colors.text.contrast};
        background-color: #ffffff45;
      `} 
    
    ${props =>
      props.rounded &&
      css`
        border-radius: 20px;
      `}

    ${props =>
      props.secondary &&
      css`
        color: ${props => props.theme.colors.text.contrast};
        background-color: ${props => props.theme.colors.button.secondary};
      `}
    ${props =>
      props.tertiary &&
      css`
        color: ${props => props.theme.colors.text.contrast};
        background-color: ${props => props.theme.colors.button.tertiary};
      `}
    ${noTouchHighlight}
`;

export const StyledIconButton = styled.button`
  border: 0;
  padding: ${props => props.theme.spacing.tinyx} 0.7rem;
  color: ${props => props.color || props.theme.colors.text.secondary};
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
  text-decoration: underline;
  font-family: ${props => props.theme.fonts.primary};
  &:focus {
    outline: 0;
  }
  ${noTouchHighlight}
`;

export const ButtonGroup = styled.div`
  * {
    margin-bottom: ${props => props.theme.spacing.small};
  }

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

export default StyledButton;
