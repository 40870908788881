import React, { useContext } from "react";
import { CreateCardContext } from "../context/CreateCardContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledButton from "../styleguides/StyledButton";
import styled from "styled-components";
import { CardBrowserContext } from "../context/CardBrowserContext";
import { UserContext } from "../context/UserContext";
import StyledParagraph from "../styleguides/StyledParagraph";
import { FieldValue } from "../utils/firebase";
import LoadingIndicator from "./LoadingIndicator";
import Text from "../styleguides/Text";
import {
  PaddingSmall,
  MarginSmallTb,
  MarginMediumTb,
  PaddingMedium
} from "../styleguides/ContentPadders";
import Flex from "../styleguides/Flex";
import { LocationContext } from "../context/LocationContext";
import { animated, useTransition } from "react-spring";

const AddToDiscover = styled(PaddingMedium)`
  position: relative;
`;

const CheckButtonWrapper = styled(MarginMediumTb)`
  width: 100%;
  padding: 0 ${props => props.theme.spacing.small};
`;

const circleChecked = ["far", "check-circle"];
const circleUncheked = ["far", "circle"];

export default function CreateCardPublishOptions(props) {
  const {
    selectedItems,
    tag,
    content,
    themeColor,
    setHasPublishedWithoutContent,
    setIsDisplayingUploadContent
  } = useContext(CreateCardContext);

  const { addPost, isAdding } = useContext(CardBrowserContext);

  const { user } = useContext(UserContext);
  const {
    isGranted,
    cityLocation,
    triggerLocationPrompt
  } = useContext(LocationContext);
  const [isDiscoveryChecked, setIsDiscoveryChecked] = React.useState(false);
  const hasUploadedOwnContent =
    selectedItems.length > 0 && selectedItems.some(x => x.source === "UPLOAD");

  const transitions = useTransition(isDiscoveryChecked, null, {
    from: { opacity: 0, transform: "translate3d(0,-40px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px, 0)" },
    leave: { opacity: 0, transform: "translate3d(0,-40px,0)" }
  });

  const addNewPost = () => {
    if (selectedItems.length) {
      addPost(
        {
          overlays: selectedItems,
          tag,
          content,
          themeColor,
          createdBy: {
            id: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL
          },
          createdAt: FieldValue.serverTimestamp()
        },
        () => {
          props.onPublishSuccess();
        }
      );
    } else {
      setHasPublishedWithoutContent(true);
      setIsDisplayingUploadContent(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div>
      {hasUploadedOwnContent && (
        <PaddingSmall>
          <AddToDiscover>
            <Flex fullWidth center>
              <CheckButtonWrapper>
                <StyledButton
                  backgroundColor={"transparent"}
                  onClick={() => {
                    setIsDiscoveryChecked(!isDiscoveryChecked);
                    if (!isGranted) {
                      triggerLocationPrompt();
                    }
                  }}
                >
                  {transitions.map(({ item, key, props }) => (
                    <animated.div
                      style={{ ...props, position: "absolute" }}
                      key={key}
                    >
                      <Text textColor="primary.light" withIconRight size="bigger">
                        <FontAwesomeIcon
                          icon={item ? circleChecked : circleUncheked}
                        />
                        {isDiscoveryChecked
                          ? `Also post in ${
                              cityLocation
                                ? `Discover - ${cityLocation}`
                                : "Discovery"
                            }.`
                          : `Also post in ${
                              cityLocation
                                ? `Discover - ${cityLocation}`
                                : "Discovery"
                            }. `}
                      </Text>
                    </animated.div>
                  ))}
                </StyledButton>
              </CheckButtonWrapper>
            </Flex>
            {(!isGranted && isDiscoveryChecked) && (
              <MarginSmallTb>
                <Text  textColor="error" withIconLeft><FontAwesomeIcon icon="exclamation-triangle" /> Enable location services to feature a card in Discover.</Text>
              </MarginSmallTb>
            )}
          </AddToDiscover>
        </PaddingSmall>
      )}

      <PaddingSmall>
        <StyledButton
          backgroundColor={`${themeColor.background}${
            !selectedItems.length ? "35" : ""
          }`}
          color={`${themeColor.fontColor}${!selectedItems.length ? "95" : ""}`}
          onClick={() => !isAdding && addNewPost()}
        >
          {isAdding ? (
            <LoadingIndicator />
          ) : (
            <StyledParagraph white>
              Publish <FontAwesomeIcon icon={["far", "check-circle"]} />
            </StyledParagraph>
          )}
        </StyledButton>
      </PaddingSmall>
    </div>
  );
}
