import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import giphyLogo from "../assets/images/giphy/giphy_white_large.png";
import {
  CreateCardContext,
  MAX_SELECTABLE_ITEMS
} from "../context/CreateCardContext";
import noTouchHighlight from "../styleguides/noTouchHighlight";
import uuidv4 from 'uuid/v4';

const OptionIcon = styled.span`
  font-size: ${props => props.theme.fontSizes.large};
  height: 35px;
 
  ${props => ` 
  @media screen and (max-height: ${props.theme.screenSizes.smallx}) {
    height: 25px;
  }
  `}
  `;

const GiphyIcon = styled.img`
  height: 35px;
 
  ${props => ` 
  @media screen and (max-height: ${props.theme.screenSizes.smallx}) {
    height: 25px;
  }
  @media screen and (max-width: ${props.theme.screenSizes.tiny}) {
    height: 20px;
  }
  `}
  
`;

const OptionTiles = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: ${props => props.theme.spacing.small};
  padding: 0 0.25rem;

  ${props =>
    props.singleRows &&
    css`
      display: block;
      width: 100%;
      margin: 0;
    `}
`;

const OptionTitle = styled.h3`
  text-align: center;
  margin: ${props => props.theme.spacing.small} 0;
  font-size: ${props => props.theme.titleSizes.tiny};

  ${props => ` 
  @media screen and (min-width: ${props.theme.screenSizes.large}) {
    font-size: ${props.theme.titleSizes.small};
  }
  @media screen and (max-width: ${props.theme.screenSizes.small}) {
    font-size: ${props.theme.fontSizes.tiny};
  }
  @media screen and (max-width: ${props.theme.screenSizes.tinyx}) {
    display: none;
  }
`}
`;

const commonOptionStyles = `
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
flex-flow: column;
cursor: pointer;
transition: all 0.1s ease-in-out;
box-shadow: 1px 3px 4px 0 rgba(0,0,0,0.2);
font-weight: bold;
z-index: 1;
min-width: 100px;
padding: 1.5rem;

@media screen and (max-width: 300px) {
  width: 100%;
  margin: 0.3rem 0;
  min-width: 50px;
}


@media screen and (max-height: 400px) {
  height: 75px;
  margin: 0.3rem 0;
}
`;

const UploadContentLabel = styled.label`
  ${commonOptionStyles}
  background-color: ${props => props.theme.colors.button.primary};
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.text.primary.main};

  ${props =>
    props.isSelected &&
    css`
      background-color: ${props => props.theme.colors.general.secondary};
      color: ${props => props.theme.colors.text.contrast};
    `}

  &:focus {
    background: #000;
  }
`;

const OptionTile = styled.button`
  ${commonOptionStyles}
  background-color: ${props => props.theme.colors.button.primary};
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.text.primary.main};
  border: 0;
  font-weight: normal;
  
  &:focus {
    outline: 0;
  }

  ${props =>
    props.creative &&
    css`
      color: ${props => props.theme.colors.text.contrast};
      background-image: linear-gradient(220deg, #d322ff 0%, #72bbfa 60%);
      background-repeat: no-repeat;
      background-size: cover;
    `}
  
${props =>
  props.singleRows &&
  css`
    width: 100%;
    margin: ${props => props.theme.spacing.tinyx} 0;
  `}
  ${noTouchHighlight}
`;
const StyledInvisibleInput = styled.input`
  display: none;
`;

const NONE_VIEW = "NONE";
const GIF_VIEW = "GIF";
const CAMERA_VIEW = "CAMERA";
const SOLID_VIEW = "SOLID";

export default function CreateCardPictureOptions({ singleRows, onUploadedItem }) {
  
  const { currentView, setView, addItem, replaceItem, selectedItems } = useContext(
    CreateCardContext
  );

  const isComplete = selectedItems.length === MAX_SELECTABLE_ITEMS;
 
  const uploadImage = e => {

    if (e.target.files && e.target.files[0]) {
      const filename = uuidv4();
      const file = e.target.files[0];
      
      const item = {
        id: Math.floor(Math.random() * 13337) + 5,
        source: "UPLOAD",
        type: file.type,
        file,
        sources: [
          {
            name: filename, type: file.type === "video/quicktime" ? "video/mp4" : file.type, url: URL.createObjectURL(file)
          }
        ]
      };

      if(!isComplete) {
        addItem(item);
      } else {
        replaceItem(item);
      }
      onUploadedItem();
    }

    e.target.value = "";
  };

  return (
    <OptionTiles singleRows={singleRows}>
      <UploadContentLabel
        htmlFor="camera-upload"
        onClick={() =>
          setView(currentView === CAMERA_VIEW ? NONE_VIEW : CAMERA_VIEW)
        }
      >
        <OptionIcon>
          <FontAwesomeIcon icon="camera-retro" />
        </OptionIcon>{" "}
        <OptionTitle>Image or Video</OptionTitle>
      </UploadContentLabel>
      <StyledInvisibleInput
        type="file"
        id="camera-upload"
        onChange={e => uploadImage(e)}
        accept="image/*, video/*"
        
      />
      <OptionTile
        singleRows={singleRows}
        onClick={() => { 
          setView(currentView === GIF_VIEW ? NONE_VIEW : GIF_VIEW);
          onUploadedItem();
         }}
      >
        <GiphyIcon src={giphyLogo} />
        <OptionTitle gutterTop>Browse GIFs</OptionTitle>
      </OptionTile>
      <OptionTile
        singleRows={singleRows}
        creative="true"
        onClick={() => {
          setView(currentView === SOLID_VIEW ? NONE_VIEW : SOLID_VIEW);
          onUploadedItem();
        }
        }
      >
        <OptionIcon>
          <FontAwesomeIcon icon="palette" />
        </OptionIcon>
        <OptionTitle>Palettes</OptionTitle>
      </OptionTile>
    </OptionTiles>
  );
}
