export default [
  {
    content: "LØP OG KJØP! #DrinkingLadder",
    createdAt: { seconds: 1565292404, nanoseconds: 965000000 },
    createdBy: {
      displayName: "Steinar Kraugerud",
      id: "6aFSxm6YdkO8Flk1D34uiu6X2pf1",
      photoURL: "https://graph.facebook.com/10216724946410237/picture"
    },
    overlays: [
      {
        id: 6955,
        source: "UPLOAD",
        sources: [
          {
            path:
              "/6aFSxm6YdkO8Flk1D34uiu6X2pf1/hz00JNgH2dDVGG37QTNc/FunnyMan.png",
            type: "image/png",
            url:
              "https://smp.vgc.no/v2/images/10df18fc-53ed-4de4-9276-084a296c7ce3?fit=crop&h=582&w=800&s=1f9101cf4e33aa371121e8ad8c4a40de2f13a33a",
            resolvedUrl:
              "https://smp.vgc.no/v2/images/10df18fc-53ed-4de4-9276-084a296c7ce3?fit=crop&h=582&w=800&s=1f9101cf4e33aa371121e8ad8c4a40de2f13a33a"
          },
          {
            path:
              "/6aFSxm6YdkO8Flk1D34uiu6X2pf1/hz00JNgH2dDVGG37QTNc/FunnyMan.png",
            type: "image/png",
            url:
              "https://smp.vgc.no/v2/images/59a3b03e-c707-44fd-9479-4a49b1c35606?fit=crop&h=709&w=1000&s=27f3337886588d66717257499a87421c53e918e1",
            resolvedUrl:
              "https://smp.vgc.no/v2/images/59a3b03e-c707-44fd-9479-4a49b1c35606?fit=crop&h=709&w=1000&s=27f3337886588d66717257499a87421c53e918e1"
          }
        ],
        type: "image/png"
      }
    ],
    tag: "THURSDAY",
    themeColor: { background: "#35384a", fontColor: "#ffffff" },
    commentCount: 2,
    likeCount: 4,
    id: "hz00JNgH2dDVGG37QTNc"
  },
  {
    content: "Blåser helt sjukt i dag. #Tornado",
    createdAt: { seconds: 1565292528, nanoseconds: 649000000 },
    createdBy: {
      displayName: "Steinar Kraugerud",
      id: "6aFSxm6YdkO8Flk1D34uiu6X2pf1",
      photoURL: "https://graph.facebook.com/10216724946410237/picture"
    },
    overlays: [
      {
        id: 10935,
        source: "UPLOAD",
        sources: [
          {
            path: "/6aFSxm6YdkO8Flk1D34uiu6X2pf1/RMoJizrD39c3KVH8nKrl/wind.png",
            type: "image/png",
            url:
              "https://smp.vgc.no/v2/images/59a3b03e-c707-44fd-9479-4a49b1c35606?fit=crop&h=709&w=1000&s=27f3337886588d66717257499a87421c53e918e1",
            resolvedUrl:
              "https://smp.vgc.no/v2/images/59a3b03e-c707-44fd-9479-4a49b1c35606?fit=crop&h=709&w=1000&s=27f3337886588d66717257499a87421c53e918e1"
          }
        ],
        type: "image/png"
      }
    ],
    tag: "THURSDAY",
    themeColor: { background: "#35384a", fontColor: "#ffffff" },
    commentCount: 0,
    likeCount: 4,
    id: "RMoJizrD39c3KVH8nKrl"
  },
  {
    content: "#AngryCat",
    createdAt: { seconds: 1565292569, nanoseconds: 664000000 },
    createdBy: {
      displayName: "Steinar Kraugerud",
      id: "6aFSxm6YdkO8Flk1D34uiu6X2pf1",
      photoURL: "https://graph.facebook.com/10216724946410237/picture"
    },
    overlays: [
      {
        id: 10491,
        source: "UPLOAD",
        sources: [
          {
            path: "/6aFSxm6YdkO8Flk1D34uiu6X2pf1/ZEsGqPlr3wwaLf8hGIMX/cat.png",
            type: "image/png",
            url:
              "https://smp.vgc.no/v2/images/622360bb-e4a3-4ba7-82f3-efa387e9540a?fit=crop&h=630&w=800&s=9e8fa44746873c8b8c9fe8ea72f9249fc248d4d2",
            resolvedUrl:
              "https://smp.vgc.no/v2/images/622360bb-e4a3-4ba7-82f3-efa387e9540a?fit=crop&h=630&w=800&s=9e8fa44746873c8b8c9fe8ea72f9249fc248d4d2"
          }
        ],
        type: "image/png"
      }
    ],
    tag: "THURSDAY",
    themeColor: { background: "#35384a", fontColor: "#ffffff" },
    commentCount: 0,
    likeCount: 4,
    id: "ZEsGqPlr3wwaLf8hGIMX"
  },
  {
    content: "",
    createdAt: { seconds: 1565454149, nanoseconds: 543000000 },
    createdBy: {
      displayName: "Espen Schatten",
      id: "R5S7pkh36VUusQwheWUIRYHFoAv2",
      photoURL: "https://graph.facebook.com/10156243832772327/picture"
    },
    overlays: [
      {
        id: 13036,
        source: "UPLOAD",
        sources: [
          {
            path:
              "/R5S7pkh36VUusQwheWUIRYHFoAv2/AKHritd4FqhO1r4B33bu/58714690650__9BBC6172-CF1D-4338-904A-B18D77161882.MOV",
            type: "video/mp4",
            url: "https://i.giphy.com/media/l0HlHlV6OXkzE1EQM/giphy.webp",
            resolvedUrl:
              "https://i.giphy.com/media/l0HlHlV6OXkzE1EQM/giphy.webp"
          }
        ],
        type: "video/quicktime"
      }
    ],
    tag: "PORTUGAL",
    themeColor: { background: "#2196f3", fontColor: "#ffffff" },
    commentCount: 1,
    likeCount: 4,
    id: "AKHritd4FqhO1r4B33bu"
  },
  {
    content: "Master yoda",
    createdAt: { seconds: 1565643767, nanoseconds: 295000000 },
    createdBy: {
      displayName: "Mikkel Gudmundsen",
      id: "d2n5HT7r52Sv2Of94HySjScshqM2",
      photoURL: "https://graph.facebook.com/2737949416220474/picture"
    },
    overlays: [
      {
        id: 6281,
        source: "UPLOAD",
        sources: [
          {
            path:
              "/d2n5HT7r52Sv2Of94HySjScshqM2/8tQJTLCu2CEuViuKyLCQ/IMG_3036.jpg",
            type: "image/jpeg",
            url:
              "https://smp.vgc.no/v2/images/622360bb-e4a3-4ba7-82f3-efa387e9540a?fit=crop&h=630&w=800&s=9e8fa44746873c8b8c9fe8ea72f9249fc248d4d2",
            resolvedUrl:
              "https://smp.vgc.no/v2/images/622360bb-e4a3-4ba7-82f3-efa387e9540a?fit=crop&h=630&w=800&s=9e8fa44746873c8b8c9fe8ea72f9249fc248d4d2"
          }
        ],
        type: "image/jpeg"
      },
      {
        id: 6281,
        source: "UPLOAD",
        sources: [
          {
            path:
              "/6aFSxm6YdkO8Flk1D34uiu6X2pf1/hz00JNgH2dDVGG37QTNc/FunnyMan.png",
            type: "image/png",
            url:
              "https://smp.vgc.no/v2/images/59a3b03e-c707-44fd-9479-4a49b1c35606?fit=crop&h=709&w=1000&s=27f3337886588d66717257499a87421c53e918e1",
            resolvedUrl:
              "https://smp.vgc.no/v2/images/59a3b03e-c707-44fd-9479-4a49b1c35606?fit=crop&h=709&w=1000&s=27f3337886588d66717257499a87421c53e918e1"
          }
        ],
        type: "image/jpeg"
      },
      {
        id: 6281,
        source: "UPLOAD",
        sources: [
          {
            path:
              "/6aFSxm6YdkO8Flk1D34uiu6X2pf1/hz00JNgH2dDVGG37QTNc/FunnyMan.png",
            type: "image/png",
            url:
              "https://smp.vgc.no/v2/images/10df18fc-53ed-4de4-9276-084a296c7ce3?fit=crop&h=582&w=800&s=1f9101cf4e33aa371121e8ad8c4a40de2f13a33a",
            resolvedUrl:
              "https://smp.vgc.no/v2/images/10df18fc-53ed-4de4-9276-084a296c7ce3?fit=crop&h=582&w=800&s=1f9101cf4e33aa371121e8ad8c4a40de2f13a33a"
          }
        ],
        type: "image/jpeg"
      }
    ],
    tag: "FLYING",
    themeColor: { background: "#35384a", fontColor: "#ffffff" },
    commentCount: 0,
    likeCount: 2,
    id: "8tQJTLCu2CEuViuKyLCQ"
  },
  {
    content: "The boy is growing stronger! #{#wattson} #{#hunter} ",
    createdAt: { seconds: 1568477195, nanoseconds: 200000000 },
    createdBy: {
      displayName: "Espen Schatten",
      id: "R5S7pkh36VUusQwheWUIRYHFoAv2",
      photoURL: "https://graph.facebook.com/10156243832772327/picture"
    },
    overlays: [
      {
        id: 110,
        source: "UPLOAD",
        sources: [
          {
            path:
              "/R5S7pkh36VUusQwheWUIRYHFoAv2/eLiDOEn4CWJtdlAezFmG/180292af-cfbc-4bb2-b5ec-b1c59f085dbb",
            type: "image/jpeg",
            url:
              "https://vignette.wikia.nocookie.net/moon-guard/images/c/c0/Azsharaforest.jpg/revision/latest?cb=20131006175009",
            resolvedUrl:
              "https://vignette.wikia.nocookie.net/moon-guard/images/c/c0/Azsharaforest.jpg/revision/latest?cb=20131006175009"
          }
        ],
        type: "image/jpeg"
      }
    ],
    tag: "WATTSON",
    themeColor: { background: "#35384a", fontColor: "#ffffff" },
    commentCount: 0,
    id: "eLiDOEn4CWJtdlAezFmG"
  }
];
