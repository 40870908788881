import React, { useEffect, useState } from "react";
import app, { providers } from "../utils/firebase";
import useLocalStorage from "../hooks/useLocalStorage";


const UserContext = React.createContext({
  user: null,
  register: () => {},
  loginWithEmail: () => {},
  loginWithFacebook: () => {},
  logout: () => {},
  hasLoadedUser: false,
  idToken: null,
  error: null
});

const UserProvider = ({ children }) => {
  const [storedUser, setStoredUser, removeStoredUser] = useLocalStorage(
    "tyrill-user",
    null
  );
  const [user, setUser] = useState(storedUser);
  const [hasLoadedUser, setHasLoadedUser] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged(aUser => {
      if (aUser) {
        aUser
          .getIdToken(true)
          .then(function(newToken) {
            setUser(aUser);
            setIdToken(newToken);
            setStoredUser({
              token: newToken,
              displayName: aUser.displayName,
              photoURL: aUser.photoURL,
              uid: aUser.uid
            });
          })
          .catch(function(error) {
            logout();
            setError({
              error,
              message: "Unable to set ID-token!"
            });
          });
      } else {
        logout();
      }
      setHasLoadedUser(true);
    });
    // app.auth().getRedirectResult().then(function(result) { // result.credential.token -> Facebook API access. });
  }, []);

  const loginWithFacebook = () => {
    app.auth().signInWithRedirect(providers.facebook);
  };

  const logout = () => {
    setUser(null);
    removeStoredUser();
    return app.auth().signOut();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        loginWithFacebook,
        hasLoadedUser,
        error,
        idToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
