import React from "react";
import Text from "../styleguides/Text";
import {
  Modal,
  TopSection,
  CloseWrapper,
  ContentWrapper,
  Overlay
} from "../styleguides/ModalStyles";
import Notification from "./Notification";
import { NotificationContext } from "../context/NotificationContext";
import StyledButton from "../styleguides/StyledButton";
import Flex from "../styleguides/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTransition } from "react-spring";

const NotificationActions = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.small} 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  flex-basis: auto;
  flex-direction: column;
`;

const NotificationsWrapper = styled.div`
  display: flex;
  flex-basis: 90%;
  overflow: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

export default function NotificationPopup({ onClose }) {
  const {
    notifications,
    markAllAsSeen,
    hasUnseenNotifications,
    setNotificationSeen,
    handleNotificationRemove
  } = React.useContext(NotificationContext);

  const hasNotifications = notifications && notifications.length > 0;

  const transitions = useTransition("s", null, {
    from: { backgroundColor: "rgba(0,0,0,0.0)" },
    enter: { backgroundColor: "rgba(0,0,0,0.75)" },
    leave: { backgroundColor: "rgba(0,0,0,0.0)" },
  });

  return (
    <React.Fragment>

{
      transitions.map(({ key, props }) => (
        <Modal
        style={{
          ...props,
        }}
        key={key}
      >
         <TopSection>
        <CloseWrapper onClick={() => onClose()}>
          <Text variant="subtitle" textColor="white">
            Close
          </Text>
        </CloseWrapper>
      </TopSection>
      <ContentWrapper excessiveBordered>
        {!hasNotifications && (
          <Flex align="center" justify="center" direction="column" fullHeight>
            <Text
              variant="text"
              element="h2"
              textColor="secondary"
              bold
              withIconRight
            >
              Recent notifications <FontAwesomeIcon icon="bell" />
            </Text>
            <Text size="smaller" textColor="primary.light">
              You don't have any recent notifications.
            </Text>
          </Flex>
        )}
        <NotificationsWrapper>
          {hasNotifications &&
            notifications.map(notification => (
              <Notification
                key={notification.id}
                notification={notification}
                onNotificationClick={() =>
                  !notification.seen && setNotificationSeen(notification.id)
                }
                onNotificationRemove={() => {
                  handleNotificationRemove(notification.id);
                }}
              />
            ))}
        </NotificationsWrapper>

        <NotificationActions>
          {hasNotifications && hasUnseenNotifications && (
            <StyledButton
              backgroundColor="#ffffff"
              onClick={() => markAllAsSeen()}
            >
              <Text bold element="span" textColor="secondary">
                Mark all as read
              </Text>
            </StyledButton>
          )}
          <StyledButton
            as={Link}
            to={"/notifications"}
            style={{ backgroundColor: "#ffffff" }}
            onClick={() => onClose()}
          >
            <Text bold element="span" textColor="secondary">
              View all notifications
            </Text>
          </StyledButton>
        </NotificationActions>
      </ContentWrapper>
         <Overlay onClick={() => onClose()} />
      </Modal>
      ))
    }
    </React.Fragment>
  );
}
