import React, { useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledButton from "../styleguides/StyledButton";
import { UserContext } from "../context/UserContext";
import { fontColorChanger } from "../utils/animations";
import Text from "../styleguides/Text";
import { CreateCommentContext } from "../context/CreateCommentContext";
import { FieldValue } from "../utils/firebase";

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AnimatedText = styled(Text)`
  animation: ${fontColorChanger} 1.5s linear infinite;
  margin-right: 0.5rem;
`;

const InputWrapper = styled.div`
`;

const Wrapper = styled.div``;

export default function CommentPublish({ card, onPublished }) {
  const {
    content,
    publish,
    isAdding,
    setContent,
    setIsWritingComment
  } = useContext(CreateCommentContext);
 
  const { user } = useContext(UserContext);

  const addNewComment = () => {
    if (content) {
        publish(
        {
          text: content,
          createdBy: {
            id: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL
          },
          createdAt: FieldValue.serverTimestamp(),
          cardOwner: card.createdBy.id
        },
        () => {
          setContent("");
          setIsWritingComment(false);
          onPublished();
        }
      );
    }
  };

  return (
    <Wrapper>
      <InputWrapper>
          <StyledButton
            backgroundColor={`${card.themeColor.background}${content.length > 0 ? "" : "95"}`}
            onClick={() => (content.length > 0 && !isAdding) && addNewComment()}
          >
            {
              isAdding ? (<LoaderWrapper>
                <AnimatedText element="span">
                  <FontAwesomeIcon icon="dharmachakra" spin />
                </AnimatedText>
                <Text element="span" textColor="white">Publishing..</Text>
              </LoaderWrapper>) : (
             <Text element="span" color={content.length > 0 ? card.themeColor.fontColor : "#ffffff15"} withIconRight>Publish <FontAwesomeIcon icon={["far", "check-circle"]} /></Text>
              )
            }
          </StyledButton>
      </InputWrapper>
    </Wrapper>
  );
}
