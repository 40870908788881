import React, { createContext, useState } from 'react';
import useFirestoreDocument from '../hooks/useFirestoreDocument';
const MAX_LIMIT_CONTENT_LENGTH = 600;

const CreateCommentContext = createContext({
  content: "",
  setContent: () => {},
  publish: () => {},
  isAdding: false,
  setIsAdding: s => {},
  remove: id => {},
  toggleWriteComment: () => {}, 
  isWritingComment: false,
  setIsWritingComment: state => {}
});

function CreateCommentProvider({ children, cardId }) {
  const [content, setContent] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isWritingComment, setIsWritingComment] = useState(false);
  
  //eslint-disable-next-line
  const [createNewDoc, setDocData, err, remove] = useFirestoreDocument({
    collection: `cards/${cardId}/comments`
  });

  
  async function publish(post, onSuccess) {
    setIsAdding(true);
    const newCommentRef = createNewDoc();
    await setDocData(newCommentRef, post);
    setIsAdding(false);
    onSuccess();
  }

  function removeComment(id, callback) {
    let complete = false;

    remove(id, () => {
      setIsRemoving(false);
      callback();
    });

    return complete;
  }

  function toggleWriteComment() {
    setIsWritingComment(!isWritingComment);
  }

  return (
    <CreateCommentContext.Provider value={{
      content,
      setContent,
      publish,
      isAdding,
      setIsAdding,
      removeComment,
      isRemoving,
      remove,
      toggleWriteComment,
      isWritingComment,
      setIsWritingComment
    }}>
      {children}
    </CreateCommentContext.Provider>
  )
}

export { 
    CreateCommentProvider,
    CreateCommentContext,
    MAX_LIMIT_CONTENT_LENGTH
}