import React, { useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledButton from "../styleguides/StyledButton";
import { fontColorChanger } from "../utils/animations";
import StyledParagraph from "../styleguides/StyledParagraph";
import { CreateCommentContext } from "../context/CreateCommentContext";

const LoaderWrapper = styled.div`
  span {
    animation: ${fontColorChanger} 1.5s linear infinite;
    font-size: ${props => props.theme.fontSizes.large};
    margin-right: 0.5rem;
  }

  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  padding: ${props => props.theme.spacing.small};
`;

const Wrapper = styled.div`
  width: 100%;
  margin: ${props => props.theme.spacing.medium} 0;
`;

export default function CommentConfirmRemove({
    id,
    onRemove
}) {

  const {
    removeComment,
    isRemoving
  } = useContext(CreateCommentContext);
 
  const remove = () => {
       removeComment(id, () => onRemove());
  };

  return (
    <Wrapper>
      <InputWrapper>
          <StyledButton
            backgroundColor="#ff1177"
            color="#ffffff"
            onClick={() => !isRemoving && remove()}
          >
            {
              isRemoving ? (<LoaderWrapper>
                <span>
                  <FontAwesomeIcon icon="dharmachakra" spin />
                </span>
                <StyledParagraph white small>Removing..</StyledParagraph>
              </LoaderWrapper>) : (
             <StyledParagraph white small>Remove this comment <FontAwesomeIcon icon={["far", "trash-alt"]} /></StyledParagraph>
              )
            }
          </StyledButton>
      </InputWrapper>
    </Wrapper>
  );
}
