import React, { useEffect, useState } from "react";
import { UserContext } from "./UserContext";

const LocationContext = React.createContext({
  location: null,
  cityLocation: null,
  isGranted: false,
  isAvailable: null,
  isDenied: false,
  hasLoadedLocation: false,
  triggerLocationPrompt: () => {}
});

const LocationProvider = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const [location, setLocation] = useState(false);
  const [cityLocation, setCityLocation] = useState(null);
  const [isGranted, setIsGranted] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isDenied, setIsDenied] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [hasLoadedLocation, setHasLoadedLocation] = useState(false);

  function handlePermissionChange(permission) {
    setIsPrompt(false);
    if (permission.state === "granted") {
        navigator.geolocation.getCurrentPosition(
            location => {  setCityLocation("Oslo"); setIsGranted(true); setLocation(location); setIsDenied(false); setHasLoadedLocation(true); }, 
            () => {setIsGranted(false); setIsDenied(true); setHasLoadedLocation(true); });
    } if(permission.state === "prompt") {
        setIsPrompt(true);
        setHasLoadedLocation(true);
    }
    else if (permission.state === "denied") {
        setIsDenied(true);
        setHasLoadedLocation(true);
    } else if (permission.state === "default") {
        setIsGranted(false);
        setHasLoadedLocation(true);
    }
    

    permission.onchange = function() {
        handlePermissionChange(permission);
    };
  }
  const triggerLocationPrompt = () => {
    navigator.geolocation.getCurrentPosition(
      location => { setCityLocation("Oslo"); setIsGranted(true); setLocation(location); setIsDenied(false); setHasLoadedLocation(true); }, 
      () => {setIsGranted(false); setIsDenied(true); setHasLoadedLocation(true); setIsPrompt(false)});
  };
  const requestLocation = React.useCallback(async () => {
    if ("geolocation" in navigator && navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({
          name: "geolocation"
        })
        .then(handlePermissionChange);
    } else if("geolocation" in navigator) {
        if(navigator.geolocation) {
           setIsAvailable(true);
           setHasLoadedLocation(true);
           setIsPrompt(true);
        } else {
            setIsAvailable(false);
            setHasLoadedLocation(true);
        }
    } else {
        setIsAvailable(false);
        setHasLoadedLocation(true);
    }
  
  }, []);

  useEffect(() => {
    if (!!user && !location) {
      requestLocation();
    }
  }, []);

  return (
    <LocationContext.Provider
      value={{
        location,
        isGranted,
        isAvailable,
        isDenied,
        isPrompt,
        triggerLocationPrompt,
        hasLoadedLocation,
        cityLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
