import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCameraRetro,
  faCamera,
  faPalette,
  faChevronLeft,
  faChevronRight,
  faMapMarker,
  faMapMarkerAlt,
  faArrowRight,
  faTimes,
  faDharmachakra,
  faSearch,
  faCog,
  faBars,
  faMitten,
  faGlobeEurope,
  faPlus,
  faGlobeAfrica,
  faPaintBrush,
  faUserSecret,
  faUnlockAlt,
  faExclamationTriangle,
  faEllipsisH,
  faVolumeMute,
  faVolumeUp,
  faHeart,
  faUserFriends,
  faBell,
  faEllipsisV,
  faFlag,
  faArrowLeft,
  faLock
} from "@fortawesome/free-solid-svg-icons";
import {
  faImages,
  faEdit,
  faCheckCircle,
  faMap,
  faUser,
  faComment,
  faTrashAlt,
  faPaperPlane,
  faCircle
} from "@fortawesome/free-regular-svg-icons";
import {
  faFacebook,
  faChrome,
  faSafari,
  faFirefox
} from "@fortawesome/free-brands-svg-icons";

export default function loadIcons() {
  library.add(
    faCameraRetro,
    faEdit,
    faImages,
    faCheckCircle,
    faFacebook,
    faCamera,
    faPalette,
    faChevronLeft,
    faChevronRight,
    faMapMarker,
    faMap,
    faArrowRight,
    faTimes,
    faDharmachakra,
    faSearch,
    faCog,
    faBars,
    faMitten,
    faGlobeEurope,
    faPlus,
    faGlobeAfrica,
    faUser,
    faComment,
    faPaintBrush,
    faUserSecret,
    faUnlockAlt,
    faExclamationTriangle,
    faTrashAlt,
    faEllipsisH,
    faFlag,
    faPaperPlane,
    faCircle,
    faVolumeMute,
    faVolumeUp,
    faHeart,
    faUserFriends,
    faBell,
    faEllipsisV,
    faArrowLeft,
    faLock,
    faChrome,
    faSafari,
    faFirefox,
    faMapMarkerAlt
  );
}
