import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import StyledButton, { StyledIconButton } from "../styleguides/StyledButton";
import useFirestoreQuery from "../hooks/useFirestoreQuery";
import UserComment from "./UserComment";
import { UserContext } from "../context/UserContext";
import {
  CreateCommentProvider,
  CreateCommentContext
} from "../context/CreateCommentContext";
import Text from "../styleguides/Text";
import { fadeInTop } from "../utils/animations";
import CommentCreate from "./CommentCreate";

const Toolbox = styled.div`
  transition: all 0.25s cubic-bezier(0.4, 0.35, 0.63, 0.47);
  z-index: 50;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: calc(100% + 1px);
  animation: ${fadeInTop} 0.15s cubic-bezier(0.17, 0.67, 0.83, 0.67) 1;
`;

const ToolboxTitle = styled.h3`
  color: ${props => props.color || props.theme.colors.text.contrast};
  margin: 0;
  margin-left: ${props => props.theme.spacing.small};
  font-size: ${props => props.theme.titleSizes.small};

  ${props => `
    @media screen and (max-width: ${props.theme.screenSizes.small}) {
      font-size: ${props.theme.fontSizes.tinyx}
    }
    @media screen and (min-width: ${props.theme.screenSizes.mediumx}) {
      font-size: ${props.theme.fontSizes.mediumx}
    }
    @media screen and (min-width: ${props.theme.screenSizes.largex}) {
      font-size: ${props.theme.fontSizes.mediumx}
    }
  `};
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  min-height: 50px;
  padding: ${props => props.theme.spacing.small};
  transition: background-color 0.25s ease-in-out;

  ${props => `
  @media screen and (min-width: ${props.theme.screenSizes.smallx}) {
    padding: ${props.theme.spacing.medium} ${props.theme.spacing.small};
  }
`};
`;

const ToolboxToggleText = styled.span`
  font-size: ${props => props.theme.titleSizes.small};
  ${props => `
@media screen and (max-width: ${props.theme.screenSizes.small}) {
  font-size: ${props.theme.fontSizes.tinyx}
}
@media screen and (min-width: ${props.theme.screenSizes.mediumx}) {
  font-size: ${props.theme.fontSizes.mediumx}
}
@media screen and (min-width: ${props.theme.screenSizes.largex}) {
  font-size: ${props.theme.fontSizes.mediumx}
}
`};
`;

const NoCommentsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${props => props.theme.spacing.large};
  width: 100%;
`;

const Content = styled.div`
  overflow: auto;
  scroll-behavior: smooth;
`;

const scrollToRef = ref => {
  ref.current.scrollTo({
    top: ref.current.scrollHeight,
    behavior: "smooth"
  });
};

export default function CommentsWithProvider({ card, onClose }) {
  return (
    <CreateCommentProvider cardId={card.id}>
      <Comments card={card} onClose={onClose} />
    </CreateCommentProvider>
  );
}

function Comments({ card, onClose }) {
  const [unmounted, setUnmounted] = useState(false);
  const { isWritingComment } = useContext(CreateCommentContext);
  const commentsContainerRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState(false);

  //eslint-disable-next-line
  const { busy, data, fetchMoreData, hasMoreData } = useFirestoreQuery({
    collection: `cards/${card.id}/comments`,
    limit: 25,
    initialData: [],
    orderByField: "createdAt",
    order: "desc",
    fetchOnMount: true,
    reverseOnInitial: true,
    reverseOnMore: true,
  });

  const { subscribe } = useFirestoreQuery({
    collection: `cards/${card.id}/comments`,
    limit: 1,
    subscribe: true,
    initialData: [],
    onNewData: c => setNewComment(c)
  });

  useEffect(() => {
    if (newComment) {
      if (comments && comments.length > 0) {
        const uniqueNewComments = newComment.filter(
          cmt => !comments.some(oldCmt => oldCmt.id === cmt.id)
        );
        if (uniqueNewComments && uniqueNewComments.length > 0) {
          setComments([...comments, ...uniqueNewComments]);
        }
      } else {
        setComments([...newComment]);
      }
    }
  }, [newComment]);

  useEffect(() => {
    if (data) {
      if (comments && comments.length > 0) {
        const uniqueNewComments = data.filter(
          cmt => !comments.some(oldCmt => oldCmt.id === cmt.id)
        );
        if (uniqueNewComments && uniqueNewComments.length > 0) {
          setComments([...uniqueNewComments, ...comments]);
        }
      } else {
        setComments([...data]);
      }
    }
  }, [data]);

  useEffect(() => {
    const unsub = subscribe();
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    setUnmounted(false);
    if (!unmounted && shouldScroll) {
      if (!isWritingComment) {
        scrollToRef(commentsContainerRef);
      }
      setShouldScroll(false);
    }

    return () => {
      setUnmounted(true);
    };
  }, [shouldScroll]);

  const { user } = useContext(UserContext);

  return (
    <Toolbox>
      <TopContent
        color={card.themeColor.fontColor}
        backgroundColor={card.themeColor.background}
      >
        <ToolboxTitle color={card.themeColor.fontColor}>Comments</ToolboxTitle>
        <StyledIconButton
          background={card.themeColor.background}
          color={card.themeColor.fontColor}
          onClick={() => onClose()}
        >
          <ToolboxToggleText>Close</ToolboxToggleText>
        </StyledIconButton>
      </TopContent>
      <Content ref={commentsContainerRef}>
        {comments && comments.length > 0 && (
          <React.Fragment>
            {hasMoreData && (
              <StyledButton
                backgroundColor={"transparent"}
                onClick={() => fetchMoreData()}
                title="Load older comments"
              >
                Load older comments
              </StyledButton>
            )}
            {comments.map((comment, idx) => (
              <UserComment
                key={comment.id}
                comment={comment}
                background={card.themeColor.background}
                fontColor={card.themeColor.fontColor}
                isLast={idx === data.length - 1}
                isCardOwner={user.uid === card.createdBy.id}
                isOwnComment={
                  comment.createdBy && comment.createdBy.id === user.uid
                }
                onRemove={() => {
                  setComments(comments.filter(cm => cm.id !== comment.id));
                }}
              />
            ))}
          </React.Fragment>
        )}
        {!busy && (!comments || !comments.length > 0) && (
          <NoCommentsMessage>
            <Text variant="text" element="h2" color={card.themeColor.fontColor}>
              No comments yet.
            </Text>
            <Text size="smaller" color={card.themeColor.fontColor + "95"}>
              Add the first comment?{" "}
              <span role="img" aria-label="cheer-icon">
                🙌
              </span>
            </Text>
          </NoCommentsMessage>
        )}
      </Content>
      <CommentCreate card={card} onPublished={() => setShouldScroll(true)} />
    </Toolbox>
  );
}
