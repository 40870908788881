export default [
    {
        id: 1,
        tag: "chill",
    },
    {
        id: 2,
        tag: "work" 
    },
    {
        id: 3,
        tag: "sniff"
    },
    {
        id: 4,
        tag: "relate"
    },
    {
        id: 5,
        tag: "bro"
    },
    {
        id: 6,
        tag: "nsfw"
    },
    {
        id: 7,
        tag: "travel"
    },
    {
        id: 8,
        tag: "blog"
    },
    {
        id: 9,
        tag: "sponsored"
    },
    {
        id: 10,
        tag: "ad"
    },
    {
        id: 11,
        tag: "yeahright"
    },
    {
        id: 12,
        tag: "sommer"
    },
    {
        id: 13,
        tag: "shiiiiit"
    },
    {
        id: 14,
        tag: "yolo"
    }
]