import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardBrowserContext } from "../context/CardBrowserContext";
import {
  NotificationContext,
} from "../context/NotificationContext";
import Text from "../styleguides/Text";
import noTouchHighlight from "../styleguides/noTouchHighlight";
import { colorPulsate } from "../utils/animations";
import NotificationPopup from "./NotificationPopup";
import { Link } from 'react-router-dom';
import { UserContext } from "../context/UserContext";

const Top = styled.div` 
    position: absolute;
    bottom: auto;
    right: ${props => (props.pullRight ? "10px" : "45px")};
    z-index: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;
    top: 4px;

    @media screen and (min-width: ${props => props.theme.screenSizes.smallx}) {
        top: auto;
        bottom: 0.5rem;
        right 0;
        margin-top: 0;
        flex-direction: column;
        width: auto;
    }
`;

const NavTool = styled.button`
  margin: 0 ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.small};
  color: ${props => props.theme.colors.text.secondary};
  min-height: 30px;
  min-width: 30px;
  background: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  @media screen and (min-width: ${props => props.theme.screenSizes.smallx}) {
    margin: ${props => props.theme.spacing.small};
  }

  ${props =>
    props.animate &&
    css`
      padding: ${props => props.theme.spacing.small}
        ${props => props.theme.spacing.smallx};
      animation: ${colorPulsate} 1.1s cubic-bezier(0.17, 0.67, 0.83, 0.67) 1;
    `}

  ${noTouchHighlight}
`;

export default function NavToolbar() {
  const { user } = useContext(UserContext);
  const { setIsAddingCard } = useContext(CardBrowserContext);
  const { hasUnseenNotifications } = useContext(NotificationContext);
  const [isNotificationOpen, setNotificationsOpen] = React.useState(false);

  if(!user) {
    return null;
  }


  return (
    <React.Fragment>
      <Top>
        <NavTool
          onClick={() => setNotificationsOpen(!isNotificationOpen)}
          title="Notifications"
        >
          <Text element="span" size="bigger" withDropShadow>
            {hasUnseenNotifications ? (
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="bell" />
                <Text element="span" className="fa-layers-counter grow-8" />
              </span>
            ) : (
              <FontAwesomeIcon icon="bell" />
            )}
          </Text>
        </NavTool>
        <NavTool
          as={Link}
          to="/discover"
          title="Discover new cards nearby"
        >
          <Text element="span" size="bigger" withDropShadow>
            <FontAwesomeIcon icon="globe-europe" />
          </Text>
        </NavTool>
        <NavTool
          onClick={() => setIsAddingCard(true)}
          title="Create a new card"
        >
          <Text element="span" size="bigger" withDropShadow>
            <FontAwesomeIcon icon="plus" />
          </Text>
        </NavTool>
      </Top>
      {isNotificationOpen && (
          <NotificationPopup onClose={() => setNotificationsOpen(false)} />
      )}
    </React.Fragment>
  );
}
