import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledButton from "../styleguides/StyledButton";
import StyledParagraph from "../styleguides/StyledParagraph";

const Option = styled.div`
  margin: ${props => props.theme.spacing.small}
    ${props => props.theme.spacing.tiny};
`;

const Wrapper = styled.div`
  width: 100%;
  margin: ${props => props.theme.spacing.medium} 0;
`;

const ReportDescription = styled.div`
  background-color: rgba(0,0,0,0.75);
  padding: ${props => props.theme.spacing.small};
  text-align: center;
`;

const circleChecked = ["far", "check-circle"];
const circleUncheked =  ["far", "circle"];

export default function CommentReport({ id, onReportSuccess }) {

  const [isReporting, setIsReporting] = useState(false);
  const [isNotifyingTyrill, setIsNotifyingTyrill] = useState(false);
  const [isNotifyingCardOwner, setIsNotifyingCardOwner] = useState(true);

  const remove = () => {
    alert("This comment has successfully been reported. Thanks!");
    setIsReporting(false);
    setIsNotifyingTyrill(false);
    setIsNotifyingCardOwner(false);
    onReportSuccess();
  };

  return (
    <Wrapper>
      {!isReporting && (
        <React.Fragment>
          <Option>
            <ReportDescription>
              <StyledParagraph white small>
                Tell us why you would like to report this comment.
              </StyledParagraph>
            </ReportDescription>
          </Option>
          <Option>
            <StyledButton
              backgroundColor="#ff1177"
              color="#ffffff"
              onClick={() => setIsReporting("SPAM")}
            >
              <StyledParagraph white small>
                This is spam.
              </StyledParagraph>
            </StyledButton>
          </Option>
          <Option>
            <StyledButton
              backgroundColor="#ff1177"
              color="#ffffff"
              onClick={() => setIsReporting("OFFENSIVE")}
            >
              <StyledParagraph white small>
                This comment offends me or others.
              </StyledParagraph>
            </StyledButton>
          </Option>
          <Option>
            <StyledButton
              backgroundColor="transparent"
              color="#ffffff"
              onClick={() => { onReportSuccess() }}
            >
              <StyledParagraph white small>
                Cancel
              </StyledParagraph>
            </StyledButton>
          </Option>
        </React.Fragment>
      )}

      {isReporting && (
        <React.Fragment>
          <Option>
            <ReportDescription>
              <StyledParagraph white small>
                What actions should be taken?
              </StyledParagraph>
            </ReportDescription>
          </Option>
          <Option>
            <StyledButton
              backgroundColor={isNotifyingTyrill ? "#ff1177" : "#ff117775"}
              color={isNotifyingTyrill ? "#ffffff" : "#ffffff75"}
              onClick={() => setIsNotifyingTyrill(!isNotifyingTyrill)}
            >
              <StyledParagraph white small withIconRight>
                Notify the Tyrill Team.
                <FontAwesomeIcon icon={isNotifyingTyrill ? circleChecked : circleUncheked} />
              </StyledParagraph>
            </StyledButton>
          </Option>
          <Option>
            <StyledButton
              backgroundColor={isNotifyingCardOwner ? "#ff1177" : "#ff117775"}
              color={isNotifyingCardOwner ? "#ffffff" : "#ffffff75"}
              onClick={() => setIsNotifyingCardOwner(!isNotifyingCardOwner)}
            >
              <StyledParagraph white small withIconRight>
                Notify the Card Owner. 
                <FontAwesomeIcon icon={isNotifyingCardOwner ? circleChecked : circleUncheked} />
              </StyledParagraph>
            </StyledButton>
          </Option>
          <Option>
            <StyledButton
              backgroundColor={(isNotifyingCardOwner || isNotifyingTyrill) ? "#666666" : "#66666615"}
              color={(isNotifyingCardOwner || isNotifyingTyrill) ? "#ffffff" : "#cccccc15"}
              onClick={() => (isNotifyingCardOwner || isNotifyingTyrill) && remove()}
            >
              <StyledParagraph small withIconRight>
                Send report <FontAwesomeIcon icon={["far", "paper-plane"]} />
              </StyledParagraph>
            </StyledButton>
          </Option>
          <Option>
            <StyledButton
              backgroundColor="transparent"
              color="#ffffff"
              onClick={() => setIsReporting(false)}
            >
              <StyledParagraph white small>
                Back
              </StyledParagraph>
            </StyledButton>
          </Option>
        </React.Fragment>
      )}
    </Wrapper>
  );
}
