import React, { useContext } from "react";
import { CreateCardContext } from "../context/CreateCardContext";
import styled from "styled-components";
import themeColors from "../assets/themeColors";
import { getFontColorForThemeColor } from '../assets/themeColorFontColors';
import { CirclePicker } from "react-color";
import StyledSpan from "../styleguides/StyledSpan";
import noTouchHighlight from '../styleguides/noTouchHighlight';

const ThemeWrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ColorPickerDescription = styled.p`
  color: #aaa;
  padding: 0;
`;

const CloseWrapper = styled.div`
  color: ${props => props.theme.colors.text.primary.light};
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
  ${noTouchHighlight};
`;

export default function CreateCardThemeColor({ isClosable, onClose}) {
  const { setThemeColor, themeColor } = useContext(CreateCardContext);

  return (
    <ThemeWrapper>
      {isClosable && (
         <CloseWrapper onClick={() => onClose()}><StyledSpan>Close</StyledSpan></CloseWrapper>
      )}
      <ColorPickerDescription>
        Choose which color will represent as your cards theme color.
      </ColorPickerDescription>
      <CirclePicker
        colors={themeColors}
        color={themeColor.background}
        onChangeComplete={clr => setThemeColor({background: clr.hex, fontColor: getFontColorForThemeColor(clr.hex)})}
      />
    </ThemeWrapper>
  );
}
